/* eslint-disable class-methods-use-this */
import { ApplicationName } from './ApiAuthorizationConstants';
import { fetchClaims, fetchUserProfile } from './AuthServices';
import { getFirstNameAndLastNameFromEmail, getSignInUrl } from '../../commons/utils';
import currentOrganization from '../../commons/CurrentOrganization';
import WebStorageStateStore from './WebStorageStateStore';

export const Settings = {
  userStore: new WebStorageStateStore({
    prefix: `${ApplicationName}-`,
  }),
};

export class AuthorizeService {
  constructor() {
    this.signInSilentIframe = null;
  }

  async signInSilent(returnUrl) {
    const url = encodeURIComponent(returnUrl);

    return new Promise((resolve) => {
      let signInSilentTimeout = null;
      let signInIframe = this.signInSilentIframe;

      function clearSignInSilent() {
        if (signInIframe) {
          document.body.removeChild(signInIframe);
          clearTimeout(signInSilentTimeout);
          signInIframe = null;
        }
      }

      function handleOnMessageEvent(e) {
        if (e.origin !== window.location.origin) {
          return;
        }
        if (e.data && e.data.source === 'bff-silent-login') {
          // we now have a user logged in silently, so reload this window
          clearSignInSilent();
          resolve(e.data.isLoggedIn);
          if (returnUrl) {
            location.href = url;
          }
        }
      }

      if (!signInIframe) {
        signInIframe = document.createElement('iframe');
        signInIframe.id = 'bff-silent-login';
        signInIframe.className = 'silent-signin';
        signInIframe.style.display = 'none';
        signInIframe.src = getSignInUrl(url, true);
        document.body.appendChild(signInIframe);

        signInSilentTimeout = setTimeout(() => {
          clearSignInSilent();
          window.removeEventListener('message', handleOnMessageEvent);
          resolve(false);
        }, [10000]);

        window.addEventListener('message', handleOnMessageEvent);
      }
    });
  }

  async signIn(returnUrl = `${location.pathname}${location.search}`) {
    await this.clearData();
    const url = returnUrl ? encodeURIComponent(returnUrl) : '/';
    const loginUrl = getSignInUrl(url, false);
    window.location = loginUrl;
    // const isLoggedIn = await this.signInSilent(returnUrl);
    // const silentLoginAttemps = readValueFromSessionStorageAsNumber('silentLoginAttemps');
    // if (isLoggedIn && silentLoginAttemps < 5) {
    //   await sleep(silentLoginAttemps);
    //   sessionStorage.setItem('silentLoginAttemps', silentLoginAttemps + 1);
    //   if (returnUrl) {
    //     window.location = returnUrl;
    //   } else {
    //     window.location.reload();
    //   }
    // } else {
    //   await this.clearData();
    //   const url = returnUrl ? encodeURIComponent(returnUrl) : '/';
    //   const loginUrl = getSignInUrl(url, false);
    //   window.location = loginUrl;
    // }

    // return Promise.resolve(true);
  }

  async signOut(returnUrl = '/') {
    const claims = await this.getClaims();
    if (!claims) {
      window.location = `/identity/Account/Logout?returnUrl=${returnUrl}`;
      return;
    }

    const logoutUrl = claims?.find((claim) => claim.type === 'bff:logout_url');
    await this.clearData();
    window.location = `${logoutUrl?.value}&returnUrl=${returnUrl}`;
  }

  getDataInLocalStorageStartWith(texts = []) {
    const values = [];
    const keys = [];
    if (!texts?.length) return { values, keys };
    for (let i = 0; i < window.localStorage.length; i += 1) {
      const key = window.localStorage.key(i);
      // console.log('### 103 key:', i, key);
      if (texts.some((text) => key.startsWith(text))) {
        const value = window.localStorage.getItem(key);
        if (value) {
          keys.push(key);
          values.push(value);
        }
      }
    }
    return { values, keys };
  }

  setDataToLocalStorage(values, keys) {
    if (!values?.length || !keys?.length) return;
    const length = Math.min(values.length, keys.length);
    for (let i = 0; i < length; i += 1) {
      const value = values[i];
      const key = keys[i];
      window.localStorage.setItem(key, value);
    }
  }

  async clearData() {
    await Settings.userStore.remove('claims');
    await Settings.userStore.remove('userProfile');

    const { values, keys } = this.getDataInLocalStorageStartWith([
      'spaceOverviewFilterCriterias',
      'spaceOverviewSortBy',
    ]);
    localStorage.clear();
    this.setDataToLocalStorage(values, keys);

    sessionStorage.clear();
    currentOrganization.clearData();
  }

  async isAuthenticated(forceRequest) {
    const claims = await this.getClaims(forceRequest);
    if (!claims) {
      return false;
    }
    const nameDict =
      claims?.find((claim) => claim.type === 'name') ||
      claims?.find((claim) => claim.type === 'sub');
    const userName = nameDict?.value;

    return !!userName;
  }

  async getClaims(forceRequest) {
    if (!forceRequest) {
      const storedClaimsJsonString = await Settings.userStore.get('claims');
      const storedClaims = storedClaimsJsonString ? JSON.parse(storedClaimsJsonString) : null;
      // console.log("storedClaims", storedClaims);
      return Promise.resolve(storedClaims);
    }

    try {
      const claims = await fetchClaims();
      Settings.userStore.set('claims', JSON.stringify(claims));
      return claims;
    } catch (error) {
      if (error.message === 'UserNotFoundException' || error.httpStatus === 401) {
        Settings.userStore.set('claims', '');
      }
      return null;
    }
  }

  async getUserInfo(forceRequest = null) {
    if (!forceRequest) {
      const storedUserProfileJsonString = await Settings.userStore.get('userProfile');
      let storedUserProfile;
      try {
        storedUserProfile = JSON.parse(storedUserProfileJsonString);
      } catch (error) {
        console.error(error);
        storedUserProfile = null;
      }
      return Promise.resolve(storedUserProfile);
    }

    try {
      const userProfile = await fetchUserProfile();
      if (userProfile) {
        let firstName = userProfile?.firstName || '';
        let lastName = userProfile?.lastName || '';
        const contactEmail = userProfile?.contactEmail || '';
        const phoneNumber = userProfile?.phoneNumber || '';
        let fullName = `${firstName} ${lastName}`;
        if (fullName.trim().length === 0) {
          const parsedFromEmail = getFirstNameAndLastNameFromEmail(contactEmail);
          firstName = parsedFromEmail.firstName;
          lastName = parsedFromEmail.lastName;
          fullName = parsedFromEmail.fullName;
        }

        userProfile.id = Number(userProfile?.userInfoId);
        userProfile.userInfoId = Number(userProfile?.userInfoId);
        userProfile.firstName = firstName;
        userProfile.lastName = lastName;
        userProfile.fullName = fullName;
        userProfile.contactEmail = contactEmail;
        userProfile.phoneNumber = phoneNumber;
        userProfile.seenCompanyIAIntro = userProfile?.seenCompanyIAIntro === 'true';

        Settings.userStore.set('userProfile', JSON.stringify(userProfile));
        return userProfile;
      }
      return null;
    } catch (error) {
      console.log('error: ', error, error.message);
      if (error.message === 'UserNotFoundException' || error.httpStatus === 401) {
        this.signOut();
        return undefined;
      }
      return null;
    }
  }

  async setProperty(propertyName, propertyValue) {
    const storedUserProfileJsonString = await Settings.userStore.get('userProfile');
    const storedUserProfile = storedUserProfileJsonString
      ? JSON.parse(storedUserProfileJsonString)
      : null;
    storedUserProfile[propertyName] = propertyValue;
    Settings.userStore.set('userProfile', JSON.stringify(this.userProfile));
  }

  async getProperty(propertyName) {
    const storedUserProfileJsonString = await Settings.userStore.get('userProfile');
    const storedUserProfile = storedUserProfileJsonString
      ? JSON.parse(storedUserProfileJsonString)
      : null;
    return Promise.resolve(storedUserProfile[propertyName]);
  }

  async setSeenCompanyIAIntro(hasSeen) {
    await this.setProperty('seenCompanyIAIntro', hasSeen);
  }
}

const authService = new AuthorizeService();

export default authService;

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
// import _cloneDeep from 'lodash/cloneDeep';
import SpaceHeaderPreview from './components/header/SpaceHeaderPreview';
import './ThemePreview.scss';
import './cmsStyle.scss';
import Banner from '../components/Banner';
import spaceUser from '../spaces/spaceUser';
import IntegrationContextProvider from '../integrations/IntegrationContextProvider';
import EmptyThemeSettings from './components/EmptyThemeSettings';
import RowContent from './RowContent';
import { getResourceIds, shouldDoAction } from './cmsHelpers';
import { CacheKeys } from '../app/queryCache';
import { getResourcesForViewer } from '../resources/ResourceServices';
import Loading from '../components/Loading';
import useMobileQuery from '../commons/useMobileQuery';
import SpaceShareLinks from '../spaces/SpaceShareLinks';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';

const ThemePreview = (props) => {
  const {
    hideHeader,
    spaceId,
    blocks,
    pagesBlock,
    currentPage,
    hideRightContent,
    hideSpaceName,
    isSpaceClosed,
    hideShareLink,
    hideDivider,
    hideTemplateTag,
    // isMobile,
    isMobileMode,
    onAddButtonOnFloatingMenuClick,
  } = props;

  // console.log('### blocks', blocks);
  // console.log('### space', space);

  const { isMobile } = useMobileQuery();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledCMSPages] = checkEnabledFeatures([FeatureFlagsType.CMS_PAGES]);

  const isEmpty = props.isEdit && !blocks?.length;
  const currentPageId = currentPage?.id;

  const resourceIdsRef = React.useRef();
  const containerRef = React.useRef(null);

  const getResourcesQuery = useQuery({
    queryKey: [CacheKeys.getResourcesForViewer, spaceId, props.isPortal, currentPage?.slug],
    queryFn: async () => {
      const resourceIds = resourceIdsRef.current;
      if (!resourceIds?.length) return {};
      const resp = await getResourcesForViewer(resourceIds, props.isPortal);

      if (!resp) return {};
      const resourcesMap = resp.reduce((dict, resource) => {
        dict[resource.resourceId] = resource;
        return dict;
      }, {});
      return resourcesMap;
    },

    retry: 1,
    retryDelay: () => 3000,
    enabled: false,
  });

  const refetch = getResourcesQuery.refetch;
  const resources = getResourcesQuery.data;

  React.useEffect(() => {
    const newResourceIds = getResourceIds(blocks);
    const resourceIds = resourceIdsRef.current;
    const shouldRefetch = shouldDoAction(resourceIds, newResourceIds);

    resourceIdsRef.current = newResourceIds;
    if (shouldRefetch || resourceIds === undefined) {
      refetch();
    }
  }, [refetch, blocks]);

  // console.log('### 711 resources: ', resources);
  const isInitialLoading =
    resources === undefined || (getResourcesQuery.isFetching && getResourcesQuery.isLoading);
  // console.log('### 711 isInitialLoading: ', resources, isInitialLoading, getResourcesQuery);

  function renderBlocks() {
    if (isEmpty) return <EmptyThemeSettings />;
    return blocks.map((row, index) => (
      <RowContent
        key={row.id + index}
        row={row}
        rowIndex={index}
        spaceId={spaceId}
        onSelectBlock={props.onSelectBlock}
        selectedBlock={props.selectedBlock}
        canPinResource={props.canPinResource}
        isPortal={props.isPortal}
        isEdit={props.isEdit}
        onSelectColumn={props.onSelectColumn}
        workingData={props.workingData}
        isMobile={isMobile || isMobileMode}
        isPreviewMode={props.isPreviewMode}
        onAddButtonOnFloatingMenuClick={onAddButtonOnFloatingMenuClick}
        resources={resources}
      />
    ));
  }

  function renderBody() {
    return (
      <>
        {!hideHeader && (
          <div className={`cms-theme-preview-header`}>
            <IntegrationContextProvider showLoading={false}>
              <SpaceHeaderPreview
                hideRightContent={hideRightContent}
                hideSpaceName={hideSpaceName}
                hideShareLink={hideShareLink}
                hideDivider={hideDivider || isMobile}
                isMobile={isMobile}
                isPortal={props.isPortal}
                hideTemplateTag={hideTemplateTag}
                handleClickEditButton={props.handleClickEditButton}
              />
            </IntegrationContextProvider>
          </div>
        )}
        {isSpaceClosed && spaceUser.isHost() && (
          <Banner
            className="banner-warning"
            message={
              <>
                <b>This space is closed. </b>
                Users will not be able to join this space until you reopen it.
              </>
            }
          />
        )}
        <div className="cms-theme-preview-navigation">
          {pagesBlock && isEnabledCMSPages && (
            <RowContent
              rowIndex={-1}
              row={pagesBlock}
              spaceId={spaceId}
              fixed={props.isPreviewMode || !props.isEdit}
              onSelectBlock={props.onSelectBlock}
              selectedBlock={props.selectedBlock}
              canPinResource={props.canPinResource}
              isPortal={props.isPortal}
              isEdit={props.isEdit}
              onSelectColumn={props.onSelectColumn}
              workingData={props.workingData}
              isMobile={isMobile || isMobileMode}
              isPreviewMode={props.isPreviewMode}
              onAddButtonOnFloatingMenuClick={onAddButtonOnFloatingMenuClick}
              resources={resources}
            />
          )}
        </div>
        {isInitialLoading ? (
          <Loading />
        ) : (
          <div
            key={currentPageId}
            className={`cms-theme-preview-body  ${isEmpty ? 'empty' : ''} ${pagesBlock ? 'has-pages-block' : ''}`}
          >
            {renderBlocks()}
          </div>
        )}
        <SpaceShareLinks />
      </>
    );
  }

  // since isMobile is not updated by the fix on spc-7013, we need to use directly from useMobileQuery
  // const scrollCssClass = isDesktop || isXDesktop ? 'custom-scrollbar' : '';

  if (isMobileMode && !isMobile) {
    return (
      <div className={`cms-theme-preview-container-wrapper`}>
        <div
          className="cms-theme-preview-container mobile-mode-container"
          id="cms-theme-preview-container"
          ref={containerRef}
        >
          {renderBody()}
        </div>
      </div>
    );
  }

  return (
    <div className={`cms-theme-preview-container `} id="cms-theme-preview-container">
      {renderBody()}
    </div>
  );
};

ThemePreview.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  hideHeader: PropTypes.bool,
  onSelectBlock: PropTypes.func,
  selectedBlock: PropTypes.instanceOf(Object),
  spaceId: PropTypes.string,
  canPinResource: PropTypes.bool,
  isPortal: PropTypes.bool,
  hideRightContent: PropTypes.bool,
  hideSpaceName: PropTypes.bool,
  isSpaceClosed: PropTypes.bool,
  hideShareLink: PropTypes.bool,
  hideDivider: PropTypes.bool,
  isEdit: PropTypes.bool,
  hideTemplateTag: PropTypes.bool,
  // isMobile: PropTypes.bool,
  onSelectColumn: PropTypes.func,
  workingData: PropTypes.instanceOf(Object),
  handleClickEditButton: PropTypes.func,
  isPreviewMode: PropTypes.bool,
  isMobileMode: PropTypes.bool,
  pagesBlock: PropTypes.instanceOf(Object),
  currentPage: PropTypes.instanceOf(Object),
  onAddButtonOnFloatingMenuClick: PropTypes.func,
};

export default ThemePreview;

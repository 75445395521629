import React from 'react';
import { useTheme } from '@mui/material';
import Widget from '../components/Widget';
import queryCache from '../app/queryCache';
import Api from '../commons/api';

const Dashboard = () => {
  const theme = useTheme();
  return (
    <Widget
      id="ilog-dashboard"
      packageId="@sp-ce/spp-widget-iloqdashboard"
      theme={theme}
      queryCache={queryCache}
      api={Api}
    />
  );
};

export default Dashboard;

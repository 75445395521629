import { useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import currentOrganization from '../commons/CurrentOrganization';
import queryCache, { CacheKeys } from '../app/queryCache';
import { getOrganizationsUsers } from '../settings/SettingsServices';
import { useCallback } from 'react';

const useOrganizationsUsersQuery = (enabled = true, isPortal = true) => {
  // const { isPortal } = useAppContext();
  const organizationId = currentOrganization.getOrganizationId();
  const getOrgUsersQuery = useQuery({
    queryKey: [CacheKeys.getOrganizationsUsers],
    queryFn: async () => {
      const resp = await getOrganizationsUsers(organizationId, isPortal);
      return resp;
    },
    retry: 1,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const getUserInfoById = useCallback(
    (userId) => {
      return getOrgUsersQuery.data?.find((user) => user.userInfoId === userId);
    },
    [getOrgUsersQuery.data]
  );

  const updatedUserInQuery = useCallback((id, user) => {
    console.log('updatedUserInQuery', id, user);
    queryCache.setQueryData([CacheKeys.getOrganizationsUsers], (oldData) => {
      if (!oldData) {
        return null;
      }
      const newData = cloneDeep(oldData);
      if (newData) {
        const foundIndexUser = newData.findIndex((u) => u.userInfoId === id);
        if (foundIndexUser !== -1) {
          newData[foundIndexUser] = { ...newData[foundIndexUser], ...user };
        } else {
          newData.push(user);
        }
      }
      return newData;
    });
  }, []);

  return {
    data: getOrgUsersQuery.data,
    isFetching: getOrgUsersQuery.isFetching,
    isLoading: getOrgUsersQuery.isLoading,
    refetch: getOrgUsersQuery.refetch,
    getUserInfoById,
    updatedUserInQuery,
  };
};

export default useOrganizationsUsersQuery;

import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import './SettingsContainer.scss';
import SettingsMenu from './SettingsMenu';
import SettingsProvider, { useSettingsContext } from './SettingsProvider';
import { AppContext } from '../components/AppProvider';
import currentOrganization from '../commons/CurrentOrganization';
import HomeButton from '../components/HomeButton';
import { FeatureFlagsType } from '../app/appConstants';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import IntegrationContextProvider from '../integrations/IntegrationContextProvider';
import SettingsPage from './SettingsPage';

const LearningManagement = React.lazy(() => import('../course/settings/LearningManagement'));

const UserProfileDetails = React.lazy(() => import('./profile/UserProfileDetails'));
const NotificationPage = React.lazy(() => import('./notifications/NotificationPage'));
const UserManagement = React.lazy(() => import('./userManagement/UserManagement'));
const TagsManagement = React.lazy(() => import('../settings/tags/TagsManagement'));
const DesignSettingsContainer = React.lazy(() => import('./designSetting/DesignSettingContainer'));
const GlobalResourcesSetting = React.lazy(
  () => import('../globalResources/GlobalResourcesSetting')
);

function SettingsContainer() {
  const { open, handleDrawerToggle } = useSettingsContext();
  const { slug } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const { userInfo } = useContext(AppContext);
  const organizationId = currentOrganization.getOrganizationId();
  const organizationName = currentOrganization.getOrganizationName();
  const isAdmin = currentOrganization.isAdmin();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [enabledTags, isEnabledCourses] = checkEnabledFeatures([
    FeatureFlagsType.TAGS,
    FeatureFlagsType.COURSES,
  ]);

  const userDetails = React.useMemo(() => {
    return {
      ...userInfo,
      organizationId,
      organizationName,
    };
  }, [userInfo, organizationId, organizationName]);

  function renderTagsPage() {
    if (isAdmin && enabledTags) {
      return <TagsManagement />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderDesignSetting() {
    if (isAdmin) {
      return <DesignSettingsContainer />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderUserManagementPage() {
    if (isAdmin) {
      return <UserManagement />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderGlobalResourcesPage() {
    if (isAdmin) {
      return (
        <IntegrationContextProvider showLoading>
          <GlobalResourcesSetting />
        </IntegrationContextProvider>
      );
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderLearningManagementPage() {
    if (isAdmin && isEnabledCourses) {
      return <LearningManagement />;
    }
    return (
      <div className="subscription loading-container">
        <p>You have no permission to access this page</p>
      </div>
    );
  }

  function renderSettingsPage() {
    if (pathname === '/profile/settings') {
      return <UserProfileDetails userInfo={userDetails} />;
    }
    if (pathname === '/profile/settings/notifications') {
      return <NotificationPage handleDrawerToggle={handleDrawerToggle} isOpen={open} />;
    }
    if (pathname === '/profile/settings/user-management') {
      return renderUserManagementPage();
    }
    if (pathname === '/profile/settings/design') {
      return renderDesignSetting();
    }
    if (pathname === '/profile/settings/global-resources') {
      return renderGlobalResourcesPage();
    }
    if (pathname === '/profile/settings/tags') {
      return renderTagsPage();
    }
    if (pathname === '/profile/settings/course') {
      return renderLearningManagementPage();
    }
    return <SettingsPage handleDrawerToggle={handleDrawerToggle} isOpen={open} type={slug} />;
  }

  return (
    <>
      <div className="btn-back-home-setting">
        <HomeButton />
      </div>
      <div className="user-container">
        <Box className="user-container-box">
          <div className={`user-container-menu ${open ? 'open' : ''}`}>
            <SettingsMenu userInfo={userDetails} slug={slug} pathname={pathname} />
          </div>

          <div className={`user-container-content ${open ? 'open' : ''}`}>
            {renderSettingsPage()}
          </div>
        </Box>
      </div>
    </>
  );
}

const SettingsContainerWrapper = () => (
  <SettingsProvider>
    <SettingsContainer />
  </SettingsProvider>
);

export default SettingsContainerWrapper;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { CacheKeys, clearCache } from '../app/queryCache';
import authService from '../components/api-authorization/AuthorizeService';
import spaceUser from '../spaces/spaceUser';

const TransferOwnershipHandler = (props) => {
  const { connection, isSpace } = props;
  const [userInfo, setUserInfo] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    async function getUserInfo() {
      const resp = await authService.getUserInfo();
      setUserInfo(resp);

      const respAuth = await authService.isAuthenticated();
      setIsAuthenticated(respAuth);
      return resp;
    }

    getUserInfo();
  }, []);
  useEffect(() => {
    function clearCacheInSpaceOverview() {
      clearCache([CacheKeys.getOrganizationsUsers]);
      clearCache([CacheKeys.fetchSpacesList], false);
      clearCache([CacheKeys.fetchSpaceTemplates], false);
    }

    function clearCacheInSpacePage(spaceId) {
      clearCache([CacheKeys.getSpaceAccessToken, spaceId]);
      clearCache([CacheKeys.fetchSpaceAcessInfo], false);
      setTimeout(() => {
        navigate('/');
      }, 1000);
    }
    function handleDeleteUserData(message) {
      const { spaceIds, fromUserInfoId } = message;
      clearCacheInSpaceOverview();
      if (isSpace && fromUserInfoId === userInfo.id) {
        clearCacheInSpacePage(spaceIds[0]);
      }
    }

    function handleTransferCompleted(message) {
      const { spaceIds, fromUserInfoId } = message;
      clearCacheInSpaceOverview();
      if (isSpace && fromUserInfoId === userInfo.id) {
        clearCacheInSpacePage(spaceIds[0]);
      }
      spaceUser.reset();
      clearCache([CacheKeys.getSpaceAccessToken, spaceIds[0]]);
      clearCache([CacheKeys.fetchSpaceAcessInfo], false);
      clearCache([CacheKeys.getSpaceDetailsQuery, spaceIds[0]]);
    }

    if (connection) {
      connection.on('TransferUserDataCompleted', handleTransferCompleted);
      connection.on('DeleteUserDataCompleted', handleDeleteUserData);
      connection.on('DeleteUserDataFailed', handleDeleteUserData);
    }
    return () => {
      if (connection) {
        connection.off('TransferUserDataCompleted', handleTransferCompleted);
        connection.off('DeleteUserDataCompleted', handleDeleteUserData);
        connection.off('DeleteUserDataFailed', handleDeleteUserData);
      }
    };
  }, [connection, navigate, isAuthenticated, isSpace, userInfo]);

  return null;
};

TransferOwnershipHandler.propTypes = {
  connection: PropTypes.instanceOf(Object),
  isSpace: PropTypes.bool,
};

export default TransferOwnershipHandler;

import { useQuery } from '@tanstack/react-query';
import { getCertificateFile } from '../services';
import { CacheKeys } from '../../app/queryCache';

function useGetCertificateFileQuery(userCredentialId) {
  const userBlocks = useQuery({
    queryKey: [CacheKeys.courseBlocks_GetCertificateFile, userCredentialId],
    queryFn: async () => {
      const resp = await getCertificateFile(userCredentialId);
      return resp;
    },
    enabled: !!userCredentialId,
  });

  return userBlocks;
}

export default useGetCertificateFileQuery;

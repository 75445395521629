/* eslint no-restricted-syntax: 0 */
import { words as _words, split as _split } from 'lodash';

const AVATAR_COLORS = [
  '#6A50D3',
  '#FF9B42',
  '#DF486F',
  '#73348C',
  '#B23683',
  '#F96E57',
  '#4380E2',
  '#2AA076',
  '#00A8B3',
];

// const AVATAR_OPACITY = 0.4;

/**
 * Generates the background color of an initials based avatar.
 *
 * @param {string?} initials - The initials of the avatar.
 * @returns {string}
 */
export function getAvatarColor(initials, customAvatarBackgrounds) {
  const hasCustomAvatarBackgronds = customAvatarBackgrounds && customAvatarBackgrounds.length;
  const colorsBase = hasCustomAvatarBackgronds ? customAvatarBackgrounds : AVATAR_COLORS;

  let colorIndex = 0;

  if (initials) {
    let nameHash = 0;

    for (const s of initials) {
      nameHash += s.codePointAt(0);
    }

    colorIndex = nameHash % colorsBase.length;
  }

  return colorsBase[colorIndex];
}

/**
 * Generates initials for a simple string.
 *
 * @param {string?} s - The string to generate initials for.
 * @returns {string?}
 */
export function getInitials(s) {
  // We don't want to use the domain part of an email address, if it is one
  const initialsBasis = _split(s, '@')[0];
  const words = _words(initialsBasis);
  let initials = '';

  for (const w of words) {
    if (initials.length < 2) {
      initials += w.substr(0, 1).toUpperCase();
    }
  }

  return initials;
}

export function getAvatarLabel(participant) {
  if (!participant) {
    return '';
  }
  let fullName = participant.fullName;
  if (!fullName) {
    fullName = `${participant.firstName} ${participant.lastName}`;
  }
  fullName = fullName.trim();
  return getInitials(fullName || participant.email);
}

const SpecialCharacterPattern = /[-|_`@!^&/\\#,=+()$~%.'",;:*?<>\]\\[{}]/g;

export function hasSpecialCharacter(fullName) {
  return SpecialCharacterPattern.test(fullName);
}

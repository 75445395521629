import React from 'react';
import './SuspendedScreen.scss';
import { DefaultLogoImage } from '../app/appConstants';
import i18n from '../i18n';

const SuspendedScreen = () => {
  return (
    <div className="suspended-screen">
      <img src={DefaultLogoImage} alt="logo" />
      <p>
        {i18n.t(
          'This instance is temporarily suspended due to unpaid invoice and will be reinstated immediately after payment is processed.'
        )}
      </p>
      <p>{i18n.t('Sorry for the inconvenience.')}</p>
    </div>
  );
};

export default SuspendedScreen;

import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import UserAvatar from '../../components/UserAvatar';
import { useSpaceUsersContext } from '../SpaceUsers/SpaceUsersContextProvider';
import { getLastComment } from './CommentService';
import { getLastUpdatedTime } from '../../commons/DateTimeUtils';
import i18n from '../../i18n';
import Tags from '../../components/Tags';
import { SpaceUserRole } from '../../app/appConstants';

const formatLastCommentText = (isMyself, lastComment) => {
  let text = lastComment.text;
  if (!text && (lastComment.materialId > 0 || lastComment.resourceId > 0)) {
    text = i18n.t('Sent an attachment');
  }
  if (text?.length > 70) {
    text = `${text.substring(0, 70)}`;
  }
  return `${isMyself ? 'You: ' : ''}${text}`;
};

const ChannelItem = ({ channel, currentUserInfoId, onSelectChannel, updateChannel }) => {
  const channelId = channel.id;
  const numberOfNewComments = channel.numberOfNewComments || 0;
  const numberOfNewCommentsRef = useRef(numberOfNewComments);
  const lastComment = channel.lastComment;
  const { participants } = useSpaceUsersContext();
  const conversationPartner = useMemo(() => {
    return channel.participants?.find((p) => p.userInfoId !== currentUserInfoId);
  }, [channel.participants, currentUserInfoId]);

  const conversationPartnerId = conversationPartner?.userInfoId;

  function getRoleName(data) {
    const role = data?.lastRole || data?.role;
    return role === SpaceUserRole.Host ? 'Host' : '';
  }

  const { isOnline, tags } = useMemo(() => {
    if (!conversationPartnerId) {
      return { isOnline: false, tags: [] };
    }
    const participant = participants.find((p) => p.userInfoId === conversationPartnerId);
    const roleName = getRoleName(participant);
    return {
      isOnline: participant?.isOnline,
      tags: roleName
        ? [
            {
              backgroundColor: '#DBE1E3',
              color: '#000',
              text: roleName,
            },
          ]
        : [],
    };
  }, [participants, conversationPartnerId]);

  useEffect(() => {
    if (numberOfNewCommentsRef.current !== numberOfNewComments) {
      numberOfNewCommentsRef.current = numberOfNewComments;
      if (numberOfNewComments > 0) {
        getLastComment(channelId).then((comment) => {
          if (!comment) return;
          updateChannel(channelId, { lastComment: comment });
        });
      }
    }
  }, [channelId, numberOfNewComments, updateChannel]);

  if (!conversationPartner) {
    return null;
  }

  function onClick() {
    // reset new messages
    onSelectChannel(channel);
  }

  function renderChannelInfo() {
    const name = conversationPartner.fullName;
    const renderLastComment = () => {
      if (!lastComment) {
        return null;
      }
      const isMyself = lastComment.participantId === currentUserInfoId;
      const formattedText = formatLastCommentText(isMyself, lastComment);
      const lastUpdatedTime = getLastUpdatedTime(lastComment.created);
      return (
        <div className="last-comment">
          <span className="text">{formattedText}</span> <span> • {lastUpdatedTime}</span>
        </div>
      );
    };

    return (
      <div className="content" style={{ flexDirection: 'column' }}>
        <div className="content-name">
          <div className="name">{name}</div>
          {tags.length > 0 && <Tags items={tags} />}
        </div>
        {renderLastComment()}
      </div>
    );
  }

  return (
    <div
      className={`participant-item ${numberOfNewComments > 0 ? 'new-message' : ''}`}
      onClick={onClick}
    >
      <div className="participant-item-header">
        <UserAvatar fullName={conversationPartner.fullName} email={conversationPartner.email} />
        <div className="online-background">
          <div className={`circle ${isOnline ? 'online' : ''}`}></div>
        </div>
      </div>
      <div className="right">
        {renderChannelInfo()}
        {numberOfNewComments > 0 && <span className="blue-dot" />}
      </div>
    </div>
  );
};

ChannelItem.propTypes = {
  currentUserInfoId: PropTypes.number,
  onSelectChannel: PropTypes.func,
  updateChannel: PropTypes.func,
  channel: PropTypes.instanceOf(Object),
};

export default ChannelItem;

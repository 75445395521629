import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getUserInfoById } from '../spaces/SpaceUsers/spaceUsersServices';

function useUserInfoByIdQuery(spaceId, createdByUserInfoId) {
  const userInfoQuery = useQuery({
    queryKey: [CacheKeys.getSpaceUser, createdByUserInfoId],
    queryFn: async () => {
      const resp = await getUserInfoById(spaceId, createdByUserInfoId);
      return resp;
    },
    retry: 0,
    retryDelay: () => 5000,
    enabled: !!createdByUserInfoId,
  });
  const createdByUser = useMemo(() => userInfoQuery.data?.fullName, [userInfoQuery.data]);
  return {
    createdByUser,
    userInfoQuery,
  };
}

export default useUserInfoByIdQuery;

import React from 'react';
import PropTypes from 'prop-types';
import './CmsWidget.scss';
import './ChatWidget.scss';
import '../cmsStyle.scss';
import { useSpaceContext } from '../../commons/SpaceContext';
import ResourcePreviewDialog, {
  getResourcePreviewContext,
} from '../../resources/ResourcePreviewDialog';
import ChatContainer from '../../spaces/Comments/ChatContainer';

function ChatWidget(props) {
  const { signalRConnection } = useSpaceContext();

  const scrollingToEndTrickDivRef = React.useRef(null);
  const scrollToBottom = (milliseconds = 1000) => {
    setTimeout(() => {
      const scrollElement = scrollingToEndTrickDivRef?.current;
      if (scrollElement) {
        const scrollableArea = scrollElement.parentElement;
        scrollableArea.scrollTo({
          top: scrollElement.offsetTop,
          behavior: 'smooth',
        });
      }
    }, milliseconds);
  };

  React.useEffect(() => {
    function handleOnCommentAdded() {
      scrollToBottom();
    }

    if (signalRConnection) {
      signalRConnection.on('CommentAddedEvent', handleOnCommentAdded);
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.off('CommentAddedEvent', handleOnCommentAdded);
      }
    };
  }, [signalRConnection]);

  function handleResourceView(item) {
    ResourcePreviewDialog.show(item, getResourcePreviewContext('space'));
  }

  function renderWidget() {
    return (
      <div className="cms-widget">
        <div className="positionRelative paddingB-ratio32">
          <div className="cms-chat-widget positionAbsolute position0">
            <ChatContainer
              spaceId={props.spaceId}
              isTemplate={props.isTemplate}
              mainChannelId={props.chatPublicChannel?.id}
              widgetName={props.widgetName}
              mainChannel={props.chatPublicChannel}
              isChatWiget
              onResourceView={handleResourceView}
            />
          </div>
        </div>
      </div>
    );
  }

  return renderWidget();
}

ChatWidget.propTypes = {
  spaceId: PropTypes.string,
  chatPublicChannel: PropTypes.instanceOf(Object),
};

export default ChatWidget;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Lock as LockIcon } from '@mui/icons-material';

import ButtonEnhanced from '../components/ButtonEnhanced';
import showSubscriptionPopup from '../commons/Subscription/SubscriptionPopup';
import i18n from '../i18n';
import LightTooltip from '../components/LightTooltip';
import currentOrganization from '../commons/CurrentOrganization';
import { getSubscriptionRequireAndFeatureTypes } from '../commons/Subscription/functions';

function OverviewTabs(props) {
  const { isMobile, handleChangeTab, selectedTab, isDashboardEnabled } = props;
  // const [activeButton, setActiveButton] = useState(selectedTab);

  const isFreemium = currentOrganization.isFreemium();
  const isAdminRole = currentOrganization.isAdmin();

  const tabs = useMemo(() => {
    let tabs = [
      {
        name: 'Spaces',
      },
    ];

    if (isAdminRole && !isMobile) {
      tabs.push({
        name: 'Templates',
        isLocked: isFreemium,
        disabled: true,
      });
    }

    if (isDashboardEnabled) {
      tabs.push({
        name: 'Dashboard',
      });
    }

    return tabs;
  }, [isFreemium, isDashboardEnabled, isAdminRole, isMobile]);
  //listItems(isFreemium, isAdminRole && !isMobile);

  const handleOnClickUpgrade = () => {
    const currentSubscription = currentOrganization.getSubscriptionType();
    const { subscriptionRequire, subscriptionFeatureType } =
      getSubscriptionRequireAndFeatureTypes(currentSubscription);
    showSubscriptionPopup(subscriptionFeatureType, '', currentSubscription, subscriptionRequire);
  };

  const handleOnClick = (item) => {
    const value = item.name;
    if (item.isLocked) {
      handleOnClickUpgrade();
      return;
    }

    // setActiveButton(value);
    handleChangeTab(value);
  };

  return (
    <div className="group-buttons">
      {tabs.map((item, index) => (
        <ButtonEnhanced
          key={index}
          data-id={item.id}
          className={`btn ${selectedTab === item.name ? 'active' : ''}`}
          onClick={() => handleOnClick(item)}
        >
          <>
            {i18n.t(item.name)}
            {item.isLocked && (
              <LightTooltip title="Locked feature" placement="bottom-start">
                <LockIcon className="template-lock" />
              </LightTooltip>
            )}
          </>
        </ButtonEnhanced>
      ))}
    </div>
  );
}

OverviewTabs.propTypes = {
  selectedTab: PropTypes.string,
  isMobile: PropTypes.bool,
  isDashboardEnabled: PropTypes.bool,
  handleChangeTab: PropTypes.func,
};

export default OverviewTabs;

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import LightTooltip from '../LightTooltip';
import i18n from '../../i18n';
import currentOrganization from '../../commons/CurrentOrganization';
import spaceUser from '../../spaces/spaceUser';
import { useSpaceContext } from '../../spaces/SpaceContext';
import { useChatContext } from '../../spaces/Comments/ChatContextProvider';

function VaamButton(props) {
  const { onClicked, shouldVaamButtonDisabled } = props;
  const { setShowVaamRecorder, setVaamTargetChannel, setIsVaamFromChat } = useSpaceContext();
  const { chatPublicChannel } = useChatContext();
  const type = props.type || 'button';

  function handleOnClick() {
    if (shouldVaamButtonDisabled) {
      return;
    }
    setShowVaamRecorder(true);
    setVaamTargetChannel(chatPublicChannel);
    setIsVaamFromChat(false);
    if (onClicked) {
      onClicked();
    }
  }

  if (!currentOrganization.isFreemium() && spaceUser.isHost()) {
    let toolTipMessage = i18n.t('Send a video message');
    if (shouldVaamButtonDisabled) {
      toolTipMessage = i18n.t(
        'Video recording functionality is currently only available in Chrome and Edge browsers'
      );
    }

    if (type === 'button') {
      return (
        <LightTooltip title={toolTipMessage} placement="bottom-start">
          <IconButton
            className={`button-icon vaam-icon${shouldVaamButtonDisabled ? ' disabled' : ''}`}
            onClick={handleOnClick}
          >
            <span className="icon-video-message"></span>
          </IconButton>
        </LightTooltip>
      );
    }

    return (
      <LightTooltip title={toolTipMessage} placement="bottom-start">
        <span
          className={`icon-video-message${shouldVaamButtonDisabled ? ' disabled' : ''}`}
          onClick={handleOnClick}
        ></span>
      </LightTooltip>
    );
  }
  return null;
}

VaamButton.propTypes = {
  type: PropTypes.string,
  onClicked: PropTypes.func,
  shouldVaamButtonDisabled: PropTypes.bool,
};

export default VaamButton;

import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { getDriveInfo as getOneDriveInfo } from './onedriveService';
import { SERVICE_PROVIDER } from '../integrationConstants';
import { getDriveInfo as getGoogleDriveInfo } from '../googleDrive/googleDriveService';
import { getRoiFolderInfo } from '../../components/ROICalculation/service';
import { getCertificatesItemsCount } from '../../course/services';

const useGetDriveInfoQuery = (driveId, itemId, serviceProvider, spaceId, enabled) => {
  const getDriveInfoQuery = useQuery({
    queryKey: [CacheKeys.getDriveFolderInfo, driveId, itemId, spaceId, serviceProvider],
    queryFn: async () => {
      let resp = null;
      if (serviceProvider === SERVICE_PROVIDER.GOOGLE_DRIVE) {
        if (!itemId) {
          return [];
        }
        resp = await getGoogleDriveInfo(itemId);
      } else if (serviceProvider === SERVICE_PROVIDER.ONEDRIVE) {
        if (!driveId || !itemId) {
          return null;
        }
        resp = await getOneDriveInfo(driveId, itemId);
      } else if (serviceProvider === SERVICE_PROVIDER.ROI) {
        resp = await getRoiFolderInfo();
      } else if (serviceProvider === SERVICE_PROVIDER.LMS) {
        resp = await getCertificatesItemsCount();
      }
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: enabled,
  });

  const isFetching = getDriveInfoQuery.isFetching;
  const driveInfo = getDriveInfoQuery.data;

  return { driveInfo, isFetching };
};

export default useGetDriveInfoQuery;

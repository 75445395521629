import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../../i18n';
import { SpaceUserRole } from '../../app/appConstants';
import Tags from '../../components/Tags';
import UserAvatar from '../../components/UserAvatar';
import './ParticipantInfoItem.scss';
import { getTimeActive } from './functions';

function ParticipantItem(props) {
  const { participant, isSpace, isCurrentParticipant, message, timeStamp, isPublicSpace } = props;
  const lastActive = getTimeActive(participant?.lastActive, participant.isOnline, false, '');

  function getRoleName(data) {
    if (isSpace) {
      if (data.isAdmin) {
        return I18n.t('Host');
      }
      return '';
    } else {
      const role = data?.lastRole || data?.role;
      switch (role) {
        case SpaceUserRole.Host:
          return I18n.t('Host');
        default:
          return '';
      }
    }
  }

  const handleOnClickChat = () => {
    if (props.onClickChat) {
      props.onClickChat(participant);
    }
  };

  function getTags() {
    const tags = [];
    const roleName = getRoleName(participant);
    if (roleName) {
      tags.push({
        backgroundColor: '#DBE1E3',
        color: '#000',
        text: roleName,
      });
    }
    return tags;
  }

  function renderItem() {
    const tags = getTags();
    const onlineParticipant = participant.isOnline;
    const fullName = participant.fullName || `${participant.firstName} ${participant.lastName}`;
    return (
      <div className="participant-info" onClick={handleOnClickChat}>
        <div className="avatar-block">
          <div className="avatar">
            <UserAvatar fullName={fullName} email={participant.email} />
            {onlineParticipant !== undefined && (
              <div className="online-background">
                <div className={`circle ${onlineParticipant ? 'online' : ''}`}></div>
              </div>
            )}
          </div>
        </div>
        <div className="content">
          <div className="name-role-block">
            <div className="name text-truncate">
              {fullName}
              {isCurrentParticipant && <span>{`(${I18n.t('me')})`}</span>}
            </div>
            {tags.length > 0 && <Tags items={tags} />}
          </div>
          {message && (
            <div className="message">
              <span>{message}</span>
              <span className="time-stamp">• {timeStamp}</span>
            </div>
          )}
          <div className={`bottom-content ${props.isShowLastActive ? 'has-active' : ''}`}>
            <span className="email">{participant.email}</span>
            {props.isShowLastActive && lastActive && (
              <span>
                {(!participant.isExternal || !isPublicSpace) && <>&bull; </>} {lastActive}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }

  return renderItem();
}

ParticipantItem.propTypes = {
  participant: PropTypes.instanceOf(Object),
  isCurrentParticipant: PropTypes.bool,
  isSpace: PropTypes.bool,
  isShowLastActive: PropTypes.bool,
  message: PropTypes.string,
  timeStamp: PropTypes.string,
  onClickChat: PropTypes.func,
};

ParticipantItem.defaultProps = {};

export default React.memo(ParticipantItem);

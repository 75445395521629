import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../app/queryCache';
import { getPromoList } from './promoService';

const useFetchPromoQuery = (organizationId: string, enabled: boolean) =>
  useQuery({
    queryKey: [CacheKeys.promo_fetchPromoFeatures, organizationId],
    queryFn: async () => {
      if (!organizationId) {
        return null;
      }
      const items = await getPromoList();
      if (items.length > 0) {
        return items[0];
      }
      return null;
    },
    enabled: enabled,
    retry: 3,
    retryDelay: 5000,
  });

export default useFetchPromoQuery;

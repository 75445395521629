import memoizeOne from 'memoize-one';
import { SpaceContext, useSpaceContext } from '../commons/SpaceContext';
import { SpaceUserRole, PrivacySpace } from '../app/appConstants';
import { SubscriptionType } from '../commons/Subscription';

export const getSpaceContext = memoizeOne(
  (
    isLoading,
    space,
    signalRConnection,
    theme,
    sidebar,
    spaceUserSession,
    code,
    isMobile,
    isMobilePortrait,
    getSpaceAccessTokenQuery,
    newNotifications,
    showVaamRecorder,
    activeChannel,
    vaamTargetChannel,
    isSendingVaam,
    isVaamFromChat,
    isSpaceUserLoggedIn,
    notiContext,
    isVisitor,
    currentTabInParticipant,
    isSpaceDeleted,
    selectedTargetChannel,
    isPreview,
    openedP2PChannels,
    pagesBlock,
    currentPage,
    blockId,
    isFileUploadEnabled
  ) => {
    const data = {
      isLoading,
      space,
      signalRConnection,
      theme,
      sidebar,
      spaceUserSession,
      code,
      pagesBlock,
      currentPage,
      isMobile,
      isMobilePortrait,
      isFetchingToken: getSpaceAccessTokenQuery.isFetching,
      newNotifications,
      showVaamRecorder,
      activeChannel,
      vaamTargetChannel,
      isSendingVaam,
      isVaamFromChat,
      isSpaceUserLoggedIn,
      notiContext,
      isVisitor,
      currentTabInParticipant,
      isSpaceDeleted,
      selectedTargetChannel,
      isPreview,
      openedP2PChannels,
      blockId,
      isFileUploadEnabled,
    };
    data.isHostOfSpace = spaceUserSession?.role === SpaceUserRole.Host;
    data.isGlobalResourcesEnabled =
      spaceUserSession?.subscriptionTypeId === SubscriptionType.enterprise;
    data.isPublicSpace = space?.spacePrivacy === PrivacySpace.PUBLIC;
    data.isPrivateSpace = space?.spacePrivacy === PrivacySpace.PRIVATE;
    data.isTemplate = space.isTemplate;
    return data;
  }
);

export { SpaceContext, useSpaceContext };

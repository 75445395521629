import React from 'react';
import _find from 'lodash/find';
import memoizeOne from 'memoize-one';
import {
  getGoogleDriveClientId,
  getIntegrationAppUrl,
  getOneDriveClientId,
  sendNotification,
} from '../commons/utils';
import ConfirmService from '../components/ConfirmService';
import Icons from '../components/Icons';
import i18n from '../i18n';
import {
  serviceProviderList,
  SERVICE_PROVIDER,
  subscriptionIntegration,
} from './integrationConstants';
import currentOrganization from '../commons/CurrentOrganization';

export function showCommonError() {
  sendNotification(
    i18n.t('Please refresh your browser and give it another try.'),
    { type: 'error' },
    i18n.t('Uh-oh! Something went wrong.')
  );
}

export async function showSuccessMessage(confirmMessage, okLabel) {
  return ConfirmService.show(
    i18n.t('AWESOME!'),
    confirmMessage,
    okLabel || i18n.t('OK'),
    i18n.t(''),
    null,
    <Icons name="icon-correct" />,
    true
  );
}

export async function showErrorMessage(errorMessage, externalErrors) {
  await ConfirmService.show(
    i18n.t('OOPS!'),
    errorMessage ||
      'Something went wrong! We cannot process your request for now. Please try later.',
    'OK',
    i18n.t(''),
    null,
    <Icons className="icon-error" />,
    true
  );
  // TODO: populate more information to show from externalErrors
  console.log('externalErrors', externalErrors);
}

export const mapProviders = memoizeOne((connections, providers) => {
  const newProviders = providers || [...serviceProviderList];
  let foundProvider = null;
  let visible = false;
  let upgradeSubscriptionRequire = false;
  let disabled = false;
  const currentSubscriptionTypeId = currentOrganization.getSubscriptionType();
  const subscribeBy = subscriptionIntegration[currentSubscriptionTypeId];
  if (!subscribeBy) {
    return newProviders;
  }
  return newProviders.map((provider) => {
    foundProvider = _find(
      connections,
      (connection) => connection.serviceProvider === provider.type
    );
    if (subscribeBy.visible) {
      visible = subscribeBy.visible.includes(provider.type);
    }
    if (subscribeBy.upgradeSubscriptionRequire) {
      upgradeSubscriptionRequire = subscribeBy.upgradeSubscriptionRequire.includes(provider.type);
    }
    if (subscribeBy.disabled) {
      disabled = subscribeBy.disabled.includes(provider.type);
    }
    return {
      ...provider,
      isActive: foundProvider?.isActive,
      visible,
      upgradeSubscriptionRequire,
      disabled,
      isConnectLater: foundProvider?.isConnectLater,
      isAdminConnection: foundProvider?.isAdminConnection,
      connectionId: foundProvider?.id,
    };
  });
});

export function showConfirmPopup(confirmMessage, title, okLabel, cancelLabel, icon) {
  return ConfirmService.show(
    title || i18n.t("THIS CAN'T BE UNDONE"),
    confirmMessage,
    okLabel || 'Yes',
    cancelLabel || 'Cancel',
    null,
    icon || <Icons className="icon-error" />
  );
}

export function getSucceedMessage(provider) {
  let confirmMessage = '';
  switch (provider) {
    case SERVICE_PROVIDER.ONEDRIVE: {
      const oneDriveConstants = serviceProviderList.find(
        (providerItem) => providerItem.type === SERVICE_PROVIDER.ONEDRIVE
      );
      confirmMessage = (
        <>
          <p>{i18n.t('OneDrive integration is now enabled for your organization')}</p>
          <p>
            <a
              href={oneDriveConstants.learnUrl}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#527AEB', textDecoration: 'underline' }}
            >
              Learn more about the integration
            </a>
          </p>
        </>
      );
      break;
    }
    case SERVICE_PROVIDER.GOOGLE_DRIVE: {
      const providerData = serviceProviderList.find(
        (providerItem) => providerItem.type === SERVICE_PROVIDER.GOOGLE_DRIVE
      );
      confirmMessage = (
        <>
          <p>{i18n.t('GoogleDrive integration is now enabled for your organization')}</p>
          <p>
            <a
              href={providerData.learnUrl}
              target="_blank"
              rel="noreferrer"
              style={{ color: '#527AEB', textDecoration: 'underline' }}
            >
              Learn more about the integration
            </a>
          </p>
        </>
      );
      break;
    }
    default:
      break;
  }
  return confirmMessage;
}

export function getRemoveConnectionData(provider) {
  let data = {};
  switch (provider) {
    case SERVICE_PROVIDER.ONEDRIVE:
    case SERVICE_PROVIDER.GOOGLE_DRIVE:
    case SERVICE_PROVIDER.PIPE_DRIVE:
      data = { needConfirm: false };
      break;
    default:
      break;
  }
  return data;
}

export function getOneDriveAuthUrl() {
  const redirectUri = `${getIntegrationAppUrl()}/oauth/callback?state=onedrive`;
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${getOneDriveClientId()}&response_type=code&redirect_uri=${redirectUri}&scope=${process.env.REACT_APP_ONEDRIVE_SCOPE}`;
  return url;
}

export function getGoogleDriveAuthUrl() {
  const redirectUri = `${getIntegrationAppUrl()}/oauth/callback`;
  const url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?gsiwebsdk=3&client_id=${getGoogleDriveClientId()}&scope=${process.env.REACT_APP_GOOGLE_DRIVE_SCOPE}&redirect_uri=${redirectUri}&prompt=select_account consent&state=google-drive&access_type=offline&response_type=code&include_granted_scopes=true&enable_granular_consent=true&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
  return url;
}

export const getConnectionData = memoizeOne((serviceProvider, isDisconnect) => {
  const serviceProviderData = serviceProviderList.find((x) => x.type === serviceProvider);
  if (!serviceProviderData) return null;
  const action = isDisconnect ? 'Disconnect' : 'Connect';
  return {
    name: serviceProviderData.name,
    requireContact: serviceProviderData.requireContact,
    title: `${action} ${serviceProviderData.name}`,
    message: `Contact customer success to ${action.toLowerCase()} ${
      serviceProviderData.name
    } integration for your organization.`,
    logo: serviceProviderData.logo,
  };
});

export function getRetryConnectEmailChangeData(serviceProvider, retryEmailChanged) {
  const serviceProviderData = serviceProviderList.find((x) => x.type === serviceProvider);
  return (
    <>
      <h1 className="title">{i18n.t(`Can't connect ${serviceProviderData.name}`)}</h1>
      <p>{`${i18n.t('This account is already connected with another email address')}:`}</p>
      <p>
        <strong>{retryEmailChanged}</strong>
      </p>
      <p>{i18n.t(`Contact Customer Success if you wish to change email.`)}</p>
    </>
  );
}

export function contactForIntegration(serviceProvider, isDisconnect) {
  const serviceProviderData = serviceProviderList.find((x) => x.type === serviceProvider);
  const recipient = 'support@spce.com';
  const subject = `${isDisconnect ? 'Disconnect' : 'Connect'} ${serviceProviderData.name} Support`;
  const body = `I would like support with ${isDisconnect ? 'disconnecting' : 'connecting'} the ${
    serviceProviderData.name
  } integration.`;
  window.open(`mailto:${recipient}?subject=${subject}&body=${body}`);
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import './CarouselPreview.scss';
import '../cmsStyle.scss';
import { checkAvailableFonts, checkAdditionalStyles } from '../commons/FontSelect/utils';
import { createResourceInteraction } from '../../commons/CommonServices';
import { ResourceInteractionType } from '../../app/appConstants';
import ButtonEnhanced from '../../components/ButtonEnhanced';
import { getDisplayText } from '../../commons/utils';
import {
  getHeadingFontSize,
  getTextLineHeight,
  getTextPositionCssClasses,
  handleLinkNavigation,
  isSharingPageOrBlockInSpace,
} from '../cmsUtils';
import { useThemeSettingsContext } from './context/ThemeSettingsContextProvider';
import { isIntegrationResourceDeleted } from '../../integrations/integrationResourceUtils';
import ImagePlaceholderSrc from '../../images/image-placeholder.png';
import CarouselDefaultImage from '../../images/cms/carousel-preview.jpeg';
import { useSpaceContext } from '../../spaces/SpaceContext';
import { parseDateTimeStringFromServer } from '../../commons/DateTimeUtils';
import DescriptionPreview from './DescriptionPreview';
import ArrowButton from '../../components/ArrowButton/ArrowButton';
import { getExtensionFromUrl } from '../../commons/ResourceUtils';
import TiffViewer from '../../components/TiffViewer/TiffViewer';
import useMyFontListQuery from '../commons/FontSelect/MyFontListQuery';

const defaultBackgroundImage = CarouselDefaultImage;

const CarouselPreview = (props) => {
  const { items, isMobile, resources, shouldDisplay } = props;
  const { workingData } = useThemeSettingsContext();
  const { scrollToBlockId } = useSpaceContext();

  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  const slider = React.useRef();
  const { myFonts } = useMyFontListQuery(true, true);

  React.useEffect(() => {
    if (workingData) {
      if (slider.current) {
        slider.current.slickGoTo(workingData.currentTab);
      }

      setCurrentSlideIndex(workingData.currentTab);
    }
  }, [workingData]);

  function handleResourceInteraction(linkResourceId, linkMaterialId) {
    if (linkResourceId && linkMaterialId && !props.isPortal) {
      createResourceInteraction(linkResourceId, {
        materialId: linkMaterialId,
        interactionType: ResourceInteractionType.Click,
        data: JSON.stringify({ isCms: true }),
      });
    }
  }

  function handleNavigation(link) {
    const paramIds = isSharingPageOrBlockInSpace(link, props.spaceId, props.urlSlug);
    if (paramIds) {
      scrollToBlockId(paramIds, props.isEnabledCMSPages);
      return;
    }
    handleLinkNavigation(link, props.spaceId, props.urlSlug, props.isPortal);
  }

  function handleClickButtonLink(fieldName, item) {
    const linkResourceId = item[`${fieldName}ResourceId`];
    const linkMaterialId = item[`${fieldName}MaterialId`];
    handleResourceInteraction(linkResourceId, linkMaterialId);

    const link = item[`${fieldName}`];
    handleNavigation(link);
  }

  const getFont = (fontFamily) => {
    return checkAvailableFonts(fontFamily, myFonts);
  };

  function renderInfo(item) {
    if (!item) return null;
    const {
      heading,
      description,
      buttonLabel,
      secondaryButtonLabel,
      headingColor,
      descriptionColor,
      buttonLabelColor,
      secondaryButtonLabelColor,
      headingFontFamily,
      descriptionFontFamily,
      buttonLabelFontFamily,
      secondaryButtonLabelFontFamily,
      buttonBackgroundColor,
      secondaryButtonBackgroundColor,
      headingFontSize,
      descriptionFontSize,
      buttonLabelFontSize,
      secondaryButtonLabelFontSize,
      descriptionLinks,
    } = item;
    const textSize = item.textSize || 'large';
    const { positionClass, textAlignClass } = getTextPositionCssClasses(item.textPosition);

    let descriptionPaddingLRClass;
    if (textAlignClass === 'center') {
      descriptionPaddingLRClass = 'paddingLR32';
    }
    if (isMobile) {
      descriptionPaddingLRClass = 'paddingLR0';
    }

    let finalHeadingFontSize = headingFontSize;
    const headingFontSizeValue = headingFontSize?.replace('px', '');

    finalHeadingFontSize = getHeadingFontSize(headingFontSizeValue, isMobile);

    const headingStyle = {
      color: headingColor,
      fontFamily: getFont(headingFontFamily),
      fontSize: finalHeadingFontSize,
      lineHeight: 1.25,
      ...checkAdditionalStyles(headingFontFamily),
    };
    const descriptionStyle = {
      color: descriptionColor,
      fontFamily: getFont(descriptionFontFamily),
      fontSize: descriptionFontSize,
      lineHeight: getTextLineHeight(descriptionFontSize),
      ...checkAdditionalStyles(descriptionFontFamily),
    };
    const buttonLabelStyle = {
      color: buttonLabelColor,
      fontFamily: getFont(buttonLabelFontFamily),
      backgroundColor: buttonBackgroundColor,
      fontSize: buttonLabelFontSize,
      ...checkAdditionalStyles(buttonLabelFontFamily),
    };
    const secondaryButtonLabelStyle = {
      color: secondaryButtonLabelColor,
      fontFamily: getFont(secondaryButtonLabelFontFamily),
      backgroundColor: secondaryButtonBackgroundColor,
      fontSize: secondaryButtonLabelFontSize,
      ...checkAdditionalStyles(secondaryButtonLabelFontFamily),
    };

    const { htmlAttributes: headingAttributes } = getDisplayText(heading);
    const { displayText: displayButtonLabel } = getDisplayText(buttonLabel);
    const { displayText: secondaryDisplayButtonLabel } = getDisplayText(secondaryButtonLabel);

    return (
      <div className={`cms-carousel-preview-info ${textAlignClass} ${positionClass}`}>
        {heading && (
          <span
            className={`cms-carousel-preview-title ${textAlignClass} ${textSize}`}
            style={headingStyle}
            {...headingAttributes}
          />
        )}
        {description && (
          <DescriptionPreview
            isPortal={props.isPortal}
            textAlignClass={textAlignClass}
            descriptionPaddingLRClass={descriptionPaddingLRClass}
            descriptionStyle={descriptionStyle}
            description={description}
            descriptionLinks={descriptionLinks}
            handleNavigation={handleNavigation}
            handleResourceInteraction={handleResourceInteraction}
            className="cms-carousel-preview-description"
          />
        )}
        <div className="cms-carousel-preview-buttons">
          {buttonLabel && (
            <ButtonEnhanced
              className="btn-cms"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleClickButtonLink('buttonLink', item);
              }}
              style={buttonLabelStyle}
            >
              <span dangerouslySetInnerHTML={{ __html: displayButtonLabel }} />
            </ButtonEnhanced>
          )}
          {secondaryButtonLabel && (
            <ButtonEnhanced
              className="btn-cms"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleClickButtonLink('secondaryButtonLink', item);
              }}
              style={secondaryButtonLabelStyle}
            >
              <span dangerouslySetInnerHTML={{ __html: secondaryDisplayButtonLabel }} />
            </ButtonEnhanced>
          )}
        </div>
      </div>
    );
  }

  function getImageRatioCssClass() {
    if (isMobile) {
      return 'paddingB-ratio916';
    }
    const imageRatio = items?.[0]?.imageRatio || '16:9';
    let cssClass;
    switch (imageRatio) {
      case '2:1':
        cssClass = 'paddingB-ratio21';
        break;
      default:
        cssClass = 'paddingB-ratio169';
        break;
    }
    return cssClass;
  }

  function afterChangeHandler(currentSlide) {
    if (!props.isEdit) {
      return;
    }
    setCurrentSlideIndex(currentSlide);
  }

  function renderImageSrc(imageSrc, alt = 'alt', styles = {}) {
    return (
      <img
        className="cms-carousel-image"
        style={styles}
        src={
          isIntegrationResourceDeleted(imageSrc)
            ? ImagePlaceholderSrc
            : imageSrc || defaultBackgroundImage
        }
        alt={alt}
      />
    );
  }

  function renderImage(item) {
    if (item.showImage === false) {
      return null;
    }
    const styles = {
      objectPosition: isMobile && item.imagePosition ? item.imagePosition : 'center center',
    };
    if (item.imageSrc) return renderImageSrc(item.imageSrc, item.heading, styles);

    const croppedResource = resources?.[item.backgroundImageUrlCropped];
    const originResource = resources?.[item.backgroundImageUrl];
    // console.log('### CardPreview useEffect originResource', originResource);
    // console.log('### CardPreview useEffect croppedResource', croppedResource);
    let displayResource = originResource;
    if (croppedResource) {
      displayResource = croppedResource;
      const lastModified = parseDateTimeStringFromServer(originResource?.lastModified)?.local();
      const created = parseDateTimeStringFromServer(croppedResource.created)?.local();
      if (lastModified > created) {
        displayResource = originResource;
      }
    }

    const mExtension = getExtensionFromUrl(displayResource?.url, displayResource?.extension);
    const isTiff = mExtension === 'tiff' || mExtension === 'tif';
    // console.log('### 512 CarouselPreview: ', displayResource, isTiff);

    if (isTiff) {
      return (
        <TiffViewer
          src={displayResource.url}
          alt={item.heading}
          className="cms-carousel-image"
          style={styles}
          renderAsImgTag
          resourceId={displayResource.resourceId}
        />
      );
    }
    return renderImageSrc(displayResource?.url, item.heading, styles);
  }

  const settings = {
    initialSlide: currentSlideIndex,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !props.isEdit,
    autoplaySpeed: 4000,
    nextArrow: <ArrowButton icon={<KeyboardArrowRight />} />,
    prevArrow: <ArrowButton icon={<KeyboardArrowLeft />} />,
  };
  const ratioClass = getImageRatioCssClass();
  const isMobileMode = isMobile ? 'mobile-mode' : '';
  const boxShadowBorderCssClass = props.isEdit && !props.isPreviewMode ? 'box-shadow-border' : '';

  return (
    <div className={`cms-carousel ${isMobileMode}`}>
      <Slider ref={slider} {...settings} afterChange={afterChangeHandler}>
        {items.map((item) => {
          const showImage = item.showImage === undefined ? true : item.showImage;
          return (
            <div key={item?.id} className="cms-card-preview-item">
              <div className={`cms-card-preview-image ${ratioClass}`}>
                {showImage && (
                  <div
                    className={`cms-card-preview-image-overlay ${boxShadowBorderCssClass}`} // carousel always shows border at this time
                    style={{ backgroundColor: `rgba(0, 0, 0, ${item.opacity})` }}
                  />
                )}
                {shouldDisplay && renderImage(item)}
                {renderInfo(item)}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

CarouselPreview.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  imageRatio: PropTypes.string,
  isMobile: PropTypes.bool,
  isPortal: PropTypes.bool,
  isEdit: PropTypes.bool,
  spaceId: PropTypes.string,
  urlSlug: PropTypes.string,
  resources: PropTypes.instanceOf(Object),
  shouldDisplay: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  isEnabledCMSPages: PropTypes.bool,
};

export default CarouselPreview;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Badge } from '@mui/material';
import './Badge.scss';

const BadgeCustom = (props) => {
  return <Badge {...props} className="badge-custom" />;
};

export default BadgeCustom;

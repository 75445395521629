import React, { FC, useMemo, useState } from 'react';
import './Tabs.scss';
import BadgeCustom from './Badge';
import LightTooltip from './LightTooltip';
import { getDataIdFromLabel } from '../commons/pendoTaggings';
import useArray from '../commons/useArray';

type Tab = {
  id?: string | number;
  label: string | null;
  active: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  message?: string;
  badgeCount?: number;
};

type TabsProps = {
  tabs: Tab[];
  onChange: (index: number) => void;
  variant?: string;
};

export const useTabs = (initialLabels: string[], defaultActiveIndex: number = 0) => {
  const labels = useArray(initialLabels);
  const [activeTab, setActiveTab] = useState<number>(defaultActiveIndex);

  const tabs = useMemo(() => {
    return labels.map((label, i) => {
      return {
        label,
        active: i === activeTab,
      } as Tab;
    });
  }, [activeTab, labels]);

  return {
    tabs,
    changeTab: setActiveTab,
    activeTab,
  };
};
const Tabs: FC<TabsProps> = ({ tabs, onChange, variant = 'dark' }) => {
  return (
    <ul className={`tabs ${variant}`}>
      {tabs?.map((tab, i) => (
        <LightTooltip title={tab.disabledMessage || ''} key={i}>
          <li
            key={i}
            className={`tab-item ${tab.active ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}${
              !tab.label ? 'tab-item-hidden' : ''
            }`}
          >
            <div
              className="tab-wrapper"
              data-id={getDataIdFromLabel(tab.label, variant)}
              onClick={tab.disabled ? undefined : () => onChange(i)}
            >
              <span className="tab-label">{tab.label}</span>
              {!!tab.badgeCount && <BadgeCustom badgeContent={tab.badgeCount} color="secondary" />}
              {!!tab.message && (
                <LightTooltip title={tab.message} placement="bottom">
                  <span className="icon-info"></span>
                </LightTooltip>
              )}
            </div>
          </li>
        </LightTooltip>
      ))}
    </ul>
  );
};

// Tabs.propTypes = {
//   tabs: PropTypes.instanceOf(Array),
//   onChange: PropTypes.func,
//   variant: PropTypes.string,
// };

export default Tabs;

import _cloneDeep from 'lodash/cloneDeep';

import Api from '../commons/api';
import { getLMSApi, getLMSRuntimeApi } from '../commons/utils';
import RuntimeApi from '../commons/RuntimeApi';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';

export function fetchCourseBlocks(keywords, offset = 0, limit = 10) {
  let url = `${getLMSApi()}/blocks?limit=${limit}&offset=${offset}`;
  if (keywords) {
    url = `${url}&keywords=${keywords}`;
  }
  return Api.get(url);
}

function getCourseBlock(data) {
  const newData = _cloneDeep(data);
  if (data.percentageNeededToPass) {
    newData.percentageNeededToPass = parseInt(data.percentageNeededToPass);
  } else {
    delete newData.percentageNeededToPass;
  }
  data.blockItems.forEach((block, index) => {
    const newBlock = _cloneDeep(block);
    if (newBlock?.options) {
      newBlock?.options?.forEach((option, indexOption) => {
        const newOption = _cloneDeep(option);
        if (newOption.isNewOption || newBlock.isNewBlock) {
          delete newOption.isNewOption;
          delete newOption.id;
          newBlock.options[indexOption] = newOption;
        }
      });
    }

    if (newBlock.isNewBlock) {
      delete newBlock.isNewBlock;
      delete newBlock.id;
    }

    if (newBlock.resources) {
      newBlock.resources = newBlock.resources.map((resource) => {
        const newResource = _cloneDeep(resource);
        if (newResource.file) {
          delete newResource.file;
        }
        return newResource;
      });
    }
    newData.blockItems[index] = newBlock;
  });
  return newData;
}

export function addCourseBlock(data) {
  return Api.post(`${getLMSApi()}/blocks`, data);
}

export function updateCourseBlock(data) {
  const newData = getCourseBlock(data);

  return Api.post(`${getLMSApi()}/blocks`, newData);
}

export function fetchCourseBlock(id) {
  return Api.get(`${getLMSApi()}/blocks/${id}`);
}

export function deleteCourseBlock(id) {
  return Api.delete(`${getLMSApi()}/blocks/${id}`);
}

export function duplicateBlock(id) {
  return Api.post(`${getLMSApi()}/blocks/${id}/clone`);
}

export function fetchCourseBlocksByIds(ids, offset = 0, limit = 10) {
  if (!ids) {
    return null;
  }
  let params = `?limit=${limit}&offset=${offset}`;

  if (ids?.length > 0) {
    params = `${params}&ids=${ids.join(',')}`;
  }
  return Api.get(`${getLMSApi()}/blocks${params}`);
}

export function fetchSpaceCourseBlocks(ids, isPortal = true) {
  if (ids?.length === 0) {
    return null;
  }
  const params = `?blockIds=${ids.join(',')}`;
  if (isPortal) {
    return Api.get(`${getLMSApi()}/user-blocks${params}`);
  }
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/user-blocks${params}`);
}

export function fetchSpaceCourseBlock(id) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/blocks/${id}`);
}

export function submitSpaceCourseBlock(data) {
  return RuntimeApi.post(`${getLMSRuntimeApi()}/space/user-blocks`, data);
}

export function runCourseBlock(blockId) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/run/blocks/${blockId}`);
}

export function fetchUserSpaceCourseBlock(blockId) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/user-blocks/${blockId}`);
}

export function startSpaceCourseBlock(blockId) {
  return RuntimeApi.post(`${getLMSRuntimeApi()}/space/user-blocks/start`, {
    blockId,
  });
}

export function fetchResultUserSpaceCourseBlock(userBlockId) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/user-blocks/${userBlockId}`);
}

export function getCourseBlocksQueryUrl(url, externalId) {
  if (externalId) {
    let blockId = '';
    if (externalId.indexOf(`${SERVICE_PROVIDER.LMS}:`) > -1) {
      blockId = externalId.replace(`${SERVICE_PROVIDER.LMS}:`, '');
      if (blockId !== 'my-certificates') {
        return `${url}?blockId=${blockId}`;
      }
    }
  }
  return url;
}

export function fetchSpaceUserCertificates(externalId) {
  let url = `${getLMSRuntimeApi()}/space/blocks/user-certificates`;
  url = getCourseBlocksQueryUrl(url, externalId);
  return RuntimeApi.get(url);
}

export async function getCertificateFile(userCredentialId) {
  const response = await RuntimeApi.fetch(
    `${getLMSRuntimeApi()}/space/blocks/user-certificates/${userCredentialId}/document`
  );
  if (!response) {
    return null;
  }
  const blob = await response.blob();
  return {
    url: URL.createObjectURL(blob),
  };
}

export function fetchCourses(keywords, offset = 0, limit = 10) {
  let url = `${getLMSApi()}/courses?limit=${limit}&offset=${offset}`;
  if (keywords) {
    url = `${url}&keywords=${keywords}`;
  }

  return Api.get(url);
}

export function fetchAvailableCourses(blockId) {
  const url = `${getLMSApi()}/blocks/${blockId}/available-courses`;
  return Api.get(url);
}

export function addBlockToCourse(courseId, blockId) {
  return Api.post(`${getLMSApi()}/courses/${courseId}/blocks`, {
    blockId,
  });
}

export function addCourse(data) {
  return Api.post(`${getLMSApi()}/courses`, data);
}
export function deleteCourse(id) {
  return Api.delete(`${getLMSApi()}/courses/${id}`);
}

export function duplicateCourse(id) {
  return Api.post(`${getLMSApi()}/courses/${id}/clone`);
}

export function fetchCourse(id) {
  return Api.get(`${getLMSApi()}/courses/${id}`);
}

export function updateCourse(data) {
  return Api.post(`${getLMSApi()}/courses`, data);
}

export function getCredentials() {
  return Api.get(`${getLMSApi()}/certificates/templates`);
}

export function fetchSpaceCourses(ids, isPortal = true) {
  if (ids?.length === 0) {
    return null;
  }
  const params = `?courseIds=${ids.join(',')}`;
  if (isPortal) {
    return Api.get(`${getLMSApi()}/user-blocks/courses${params}`);
  }
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/user-blocks/courses${params}`);
}

export async function getPreviewCertificatePreview(templateId) {
  const response = await Api.fetch(`${getLMSApi()}/certificates/templates/${templateId}/preview`);
  if (!response) {
    return null;
  }
  const blob = await response.blob();
  return {
    url: URL.createObjectURL(blob),
  };
}

export function fetchUserSpaceCourse(id) {
  return RuntimeApi.get(`${getLMSRuntimeApi()}/space/user-blocks/courses/${id}`);
}

export function getCertificatesItemsCount() {
  const url = `${getLMSRuntimeApi()}/space/blocks/user-certificates/items-count`;
  return RuntimeApi.get(url);
}

export async function downloadCertificateFile(resource) {
  const { userCredentialId, name } = resource;
  if (userCredentialId) {
    const resp = await getCertificateFile(userCredentialId);
    if (resp?.url) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = name;
      link.href = resp.url;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function fetchBlockResources(id, isPortal = true) {
  if (isPortal) {
    return Api.get(`${getLMSApi()}/blocks/${id}/resources`);
  } else {
    return RuntimeApi.get(`${getLMSRuntimeApi()}/space/blocks/${id}/resources`);
  }
}

export function startCourse(id) {
  return RuntimeApi.post(`${getLMSRuntimeApi()}/space/user-courses/start`, {
    courseId: id,
  });
}

import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import { Alert } from '../components/NotificationMessages';
import WarningMessage from '../components/WarningMessage';
import { SERVICE_PROVIDER } from './integrationConstants';
import currentOrganization from '../commons/CurrentOrganization';
import {
  addIntegrationConnection,
  clearConnectionsCache,
  removeIntegrationConnection,
} from './integrationService';
import Widget from '../components/Widget';
import { getPipeDriveClientId } from '../deals/getClientId';

const IntegrationConnectionHandler = () => {
  const [provider, setProvider] = useState(null);
  const [openConnectDialog, setOpenConnectDialog] = useState(false);

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(EVENT_BUS.IntegrationConnectionDisconnected, (data) => {
      setProvider(data.provider);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleClose = () => {
    setProvider(null);
    setOpenConnectDialog(false);
  };

  const handleClickReconnect = async () => {
    await removeIntegrationConnection(provider);
    setOpenConnectDialog(true);
  };

  if (openConnectDialog) {
    if (provider === SERVICE_PROVIDER.PIPE_DRIVE) {
      return (
        <Widget
          id="ConnectPipeDriveDialog"
          packageId="@sp-ce/spp-widget-dms/connect-pipedrive-dialog"
          clientId={getPipeDriveClientId()}
          subDomain={currentOrganization.getSubDomain()}
          addIntegrationConnection={addIntegrationConnection}
          onClose={handleClose}
          onConnected={() => clearConnectionsCache()}
        />
      );
    }
  }

  if (!provider) {
    return null;
  }

  const iconError = (
    <span className="icon-white-error">
      <span className="path1"></span>
      <span className="path2"></span>
    </span>
  );

  return (
    <Snackbar
      open
      className="notification-mes"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <span>
        <Alert icon={iconError} severity="error" onClose={() => setProvider(null)}>
          <WarningMessage
            title={`Whoops! ${provider} has lost connection with SP_CE.`}
            description={`Please reconnect your ${provider}.`}
            // actionButton={renderReconnectButton()}
            buttonLabel="Reconnect"
            onClickButton={handleClickReconnect}
          />
        </Alert>
      </span>
    </Snackbar>
  );
};

export default IntegrationConnectionHandler;

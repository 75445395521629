import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { ICourse } from '../types';
import ConfirmRemoveBlockDialog from '../../cms/components/ConfirmRemoveBlockDialog';
import { uuidv4 } from '../../commons/utils';
import { useMountEffect } from '../../commons/CustomHooks';
import useSpaceCoursesQuery from '../queries/useSpaceCoursesQuery';

const CmsCourseBlocks = React.lazy(() => import('./CmsCourseBlocks'));

type ICmsCourse = ICourse & {
  courseId: string;
};

type IProps = {
  data: Array<ICmsCourse>;
  onChange: (newList: Array<{ courseId: string }>) => void;
};

const CourseBlocks = (props: IProps) => {
  const { data, onChange } = props;
  const [list, setList] = useState<ICmsCourse[]>([]);

  const ids = data?.map((item) => item.courseId);
  const { spaceCourses, refetch } = useSpaceCoursesQuery(ids, true, false);

  useMountEffect(() => {
    if (ids?.length > 0) {
      refetch();
    }
  });

  useEffect(() => {
    if (spaceCourses) {
      const courses = spaceCourses.map((item: ICourse, index: number) => {
        return {
          id: index,
          name: item.name,
          courseId: item.courseId,
        };
      });
      setList(courses);
    }
  }, [spaceCourses]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (newList: any[]) => {
    setList(newList);
    onChange(newList);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSelectItems = (selectedCourses: any[]) => {
    setList((prev) => {
      let newItems = prev || [];
      selectedCourses.forEach((selectedCourse) => {
        newItems = update(newItems, {
          $push: [
            {
              id: uuidv4(),
              name: selectedCourse.name,
              courseId: selectedCourse.id,
            } as ICmsCourse,
          ],
        });
      });
      onChange(newItems);
      return newItems;
    });
  };

  const handleOnRemove = async () => {
    const { hasConfirmed } = await ConfirmRemoveBlockDialog.show(
      'Remove Courses',
      `Are you sure you want to remove this course in the widget? All historical data of this
        course in this space will be kept in analytics.`,
      false,
      null,
      'Yes',
      'Cancel'
    );
    return hasConfirmed;
  };

  return (
    <CmsCourseBlocks
      label="Number of courses"
      name="course"
      isCourse
      list={list}
      onRemove={handleOnRemove}
      handleOnChange={handleOnChange}
      handleOnSelectItems={handleOnSelectItems}
    />
  );
};

export default CourseBlocks;

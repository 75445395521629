import React from 'react';
import PropTypes from 'prop-types';
import { loadMyFonts } from '../cms/commons/FontSelect/LoadFonts';
import useMyFontListQuery from '../cms/commons/FontSelect/MyFontListQuery';

const LoadFontsOrganization = React.memo(({ isReady, includeDeletedFonts }) => {
  // console.log('### LoadFontsOrganization', isReady, includeDeletedFonts);
  const { myFonts } = useMyFontListQuery(isReady, includeDeletedFonts);

  React.useEffect(() => {
    if (myFonts) {
      loadMyFonts(myFonts);
    }
  }, [myFonts]);

  return null;
});

LoadFontsOrganization.propTypes = {
  isReady: PropTypes.bool,
  includeDeletedFonts: PropTypes.bool,
};

LoadFontsOrganization.defaultProps = {
  isReady: false,
  includeDeletedFonts: false,
};

export default LoadFontsOrganization;

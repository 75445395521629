import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';
import ResponsiveDialog from '../components/ResponsiveDialog';
import Icons from '../components/Icons';
import './DisconnectDialog.scss';
import { getConnectionData } from './integrationUtils';

const DisconnectDialog = (props) => {
  const { serviceProvider, isOpen, onSubmit, onClose, submitButtonContent } = props;
  const disconnectData = getConnectionData(serviceProvider, true);
  function handleOnClickSubmit() {
    if (onSubmit) {
      onSubmit();
    }
  }

  if (!isOpen || !disconnectData) return null;

  const { title, message, logo } = disconnectData;

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className={`white confirm-dialog disconnect-dialog`}
      onClose={onClose}
      showCloseIcon
    >
      <>
        <Box className="content" display="flex" flexDirection="column">
          <div className="logo">
            {logo}
            {!logo && <Icons name="icon-integration" />}
          </div>

          <h1 className="title">
            {title}
            {!title && 'Disconnect Integration service'}
          </h1>
          <p>{message}</p>
        </Box>
        <div className="buttons">
          <Button onClick={handleOnClickSubmit} variant="contained" className="button confirm">
            {submitButtonContent}
            {!submitButtonContent && 'Contact us'}
          </Button>
        </div>
      </>
    </ResponsiveDialog>
  );
};

DisconnectDialog.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  submitButtonContent: PropTypes.string,
  serviceProvider: PropTypes.string,
};

export default DisconnectDialog;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppContainer from '../../components/AppContainer';
import Loading from '../../components/Loading';
import currentOrganization from '../../commons/CurrentOrganization';
import { useAppContext } from '../AppProvider';
import authService from './AuthorizeService';

function AuthorizeRoute(props) {
  const { component: Component, ...rest } = props;
  const { isAuthenticated, userInfo } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const setOrganization = useCallback(() => {
    try {
      const organization = {
        id: userInfo.organizationId,
        organizationId: userInfo.organizationId,
        organizationName: userInfo.organizationName,
        subdomain: userInfo.subDomain,
        subscriptionTypeId: Number(userInfo.subscriptionTypeId),
        role: userInfo.orgRole,
        userInfoId: userInfo.id,
        createdOn: userInfo.orgCreatedOn,
        isSuspended: userInfo.isSuspended === 'true',
      };
      console.log('organization: ', organization, userInfo);
      setSelectedOrganization(organization);
      currentOrganization.setData(organization);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      authService.signOut();
    }
  }, [userInfo]);

  useEffect(() => {
    async function initialize() {
      if (isAuthenticated) {
        const organizationData = currentOrganization.getData();
        if (organizationData?.userInfoId !== userInfo?.id) {
          currentOrganization.clearData();
        }
        await setOrganization();
      }
    }
    console.log('AuthorizeRoute useEffect 1');
    initialize();
  }, [isAuthenticated, userInfo?.id, setOrganization]);

  if (isLoading) {
    return (
      <div className="authorization-container">
        <Loading />
      </div>
    );
  }

  if (!selectedOrganization) {
    return null;
  }

  let hideHeader = rest.hideHeader || false;
  let hideFooter = rest.hideFooter || false;
  const noSignalR = rest.noSignalR || false;
  const fullScreen = rest.fullScreen || false;
  if (fullScreen) {
    hideHeader = true;
    hideFooter = true;
  }

  return (
    <AppContainer
      noSignalR={noSignalR}
      fullScreen={fullScreen}
      hideHeader={hideHeader}
      hideFooter={hideFooter}
      includeDeletedFonts={rest.includeDeletedFonts}
      organizationId={selectedOrganization?.id}
      isSuspended={selectedOrganization.isSuspended}
    >
      <Component {...rest} />
    </AppContainer>
  );
}

AuthorizeRoute.propTypes = {
  component: PropTypes.instanceOf(Object),
};

export default AuthorizeRoute;

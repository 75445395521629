import React, { Fragment, Suspense, lazy, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { useDebouncedCallback } from 'use-debounce';
import _findIndex from 'lodash/findIndex';
import i18n from '../../../i18n';
import {
  CONTENT_COMPONENT,
  CONTENT_FORM_COMPONENT,
  CMS_COMPONENT_TYPE,
  BACKGROUND_IMAGE_POSITION_OPTION,
} from '../../cmsConstants';
import WidgetSelect from '../WidgetSelect';
import CmsFileUploader from '../../commons/CmsFileUploader';
import { ResourceType } from '../../../app/appConstants';
import {
  extractContent,
  isUrlsEqual,
  newGuid,
  trimSentence,
  trimText,
} from '../../../commons/utils';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import ScriptInput from '../ScriptInput';
import { requestCheckEmbedableUrl } from '../../commons/useCheckLinkInIframe';
import useCmsDirectory from '../useCmsDirectory';
import Icons from '../../../components/Icons';
import Loading from '../../../components/Loading';
import { getVideoContentFormByTextPosition } from '../../cmsUtils';
import { findDeletedLinks, findNewLinks } from '../../commons/DescriptionLinkUtils';
import ShareLinkCmsBlock from '../ShareLinkCmsBlock';
import PageNameInput from '../PageNameInput';
import { onResourceChange } from '../../commons/resourceFunctions';
import CmsButtonGroup from '../../commons/CmsButtonGroup';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';

const FormatCmsContent = lazy(() => import('../../commons/FormatCmsContent'));

function ContentForm(props) {
  const { type, content, onChange, layout, spaceId, isAdmin } = props;
  console.log('ContentForm content: ');
  console.log('ContentForm layout: ', layout);
  console.log('ContentForm type: ', type);
  console.log('ContentForm content: ', content);
  const { showHeading, showDescription, showButton, showSecondaryButton, textPosition, showImage } =
    layout;
  const {
    heading,
    headingLink,
    description,
    descriptionLink,
    buttonLabel,
    buttonLink,
    secondaryButtonLabel,
    secondaryButtonLink,
  } = content;
  const { isMobileMode } = useThemeSettingsContext();
  const { getFeatureSpace } = useSpaceContext();
  const spaceFeatures = getFeatureSpace(true);
  let formComponents = CONTENT_FORM_COMPONENT[type];
  if (type === CMS_COMPONENT_TYPE.VIDEO) {
    formComponents = getVideoContentFormByTextPosition(textPosition);
  }
  const [cmsDirectory] = useCmsDirectory(spaceId);
  console.log('formComponents: ', formComponents);

  function getResourceName(fieldName, value, maxwords = 5) {
    const trimValue = trimSentence(value, maxwords);
    switch (fieldName) {
      case 'heading':
      case 'headingLink':
        return `${trimValue} (${i18n.t('Heading')})`;
      case 'description':
        return `${trimValue} (${i18n.t('Description')})`;
      case 'descriptionLink':
        return trimText(value, 200, ` ...(${i18n.t('Description')})`);
      case 'buttonLabel':
      case 'buttonLink':
        return `${trimValue} (${i18n.t('Primary button')})`;
      case 'secondaryButtonLabel':
      case 'secondaryButtonLink':
        return `${trimValue} (${i18n.t('Secondary button')})`;
      case 'videoUrl':
        return `${trimValue} (${i18n.t('Video url')})`;
      case 'backgroundImageUrl':
        return `${trimValue} (${i18n.t('Background image url')})`;
      default:
        return 'Untitled';
    }
  }

  const getUrlFieldName = (fieldName) => {
    switch (fieldName) {
      case 'heading':
        return 'headingLink';
      case 'description':
        return 'descriptionLink';
      case 'buttonLabel':
        return 'buttonLink';
      case 'secondaryButtonLabel':
        return 'secondaryButtonLink';
      case 'videoUrl':
      case 'backgroundImageUrl':
        return fieldName;
      default:
        return '';
    }
  };

  const getParentValue = (urlLabel, urlValue) => {
    switch (urlLabel) {
      case 'headingLink':
        return heading;
      case 'descriptionLink':
        return extractContent(description);
      case 'buttonLink':
        return buttonLabel;
      case 'secondaryButtonLink':
        return secondaryButtonLabel;
      case 'videoUrl':
      case 'backgroundImageUrl':
        return urlValue;
      default:
        return '';
    }
  };

  const getRelatedLinkValue = (parentLabel) => {
    switch (parentLabel) {
      case 'heading':
        return headingLink;
      case 'description':
        return descriptionLink;
      case 'button':
        return buttonLink;
      case 'secondaryButton':
        return secondaryButtonLink;
      case 'videoUrl':
      case 'backgroundImageUrl':
        return parentLabel;
      default:
        return '';
    }
  };

  const handleUrlValueChange = (name, value) => {
    const foundParentValue = getParentValue(name, value);
    if (!foundParentValue) {
      return [];
    }
    let resources = content.resources;
    if (!resources) {
      resources = [];
    }
    const currentResourceId = content[`${name}ResourceId`];
    const action = currentResourceId ? 'update' : 'add';
    const resourceName = getResourceName(name, foundParentValue);
    const newResource = {
      uniqueId: newGuid(),
      data: {
        resourceId: currentResourceId,
        fileName: resourceName,
        fileDescription: getResourceName(name, foundParentValue, 20),
        src: value,
        type: ResourceType.externalLink,
        order: 0,
        isExternal: true,
      },
      fieldName: name,
      action: action,
    };
    if (action === 'add') {
      resources.push(newResource);
    } else if (action === 'update') {
      const foundUpdatingResouceIndex = _findIndex(
        resources,
        (item) => item.data?.resourceId === currentResourceId && item.action === action
      );
      if (foundUpdatingResouceIndex !== -1) {
        resources[foundUpdatingResouceIndex].data.fileName = resourceName;
        resources[foundUpdatingResouceIndex].data.fileDescription = foundParentValue;
      } else {
        resources.push(newResource);
      }
    }
    return resources;
  };

  const handleParentValueChange = (name, value) => {
    console.log('value: ', value);
    let resources = content.resources;
    if (!resources) {
      resources = [];
    }
    const action = 'update';
    const urlFieldName = getUrlFieldName(name);
    const resourceId = content[`${urlFieldName}ResourceId`];
    const materialId = content[`${urlFieldName}MaterialId`];
    const resourceName = getResourceName(name, value);
    if (resourceId) {
      const foundResourceIndex = _findIndex(
        resources,
        (item) => item.data?.resourceId === resourceId
      );
      if (foundResourceIndex !== -1) {
        resources[foundResourceIndex].data.fileName = resourceName;
        resources[foundResourceIndex].data.fileDescription = value;
      } else {
        const newResource = {
          uniqueId: newGuid(),
          fieldName: urlFieldName,
          action: action,
          data: {
            resourceId: resourceId,
            fileName: resourceName,
            fileDescription: getResourceName(name, value, 20),
          },
        };
        resources.push(newResource);
      }
    } else if (materialId) {
      const foundUpdatingMaterialIndex = _findIndex(
        resources,
        (item) => item.data?.materialId === materialId
      );
      if (foundUpdatingMaterialIndex !== -1) {
        resources[foundUpdatingMaterialIndex].data.fileName = resourceName;
        resources[foundUpdatingMaterialIndex].data.fileDescription = value;
      } else {
        const newMaterial = {
          uniqueId: newGuid(),
          fieldName: urlFieldName,
          action: action,
          data: {
            materialId: materialId,
            fileName: resourceName,
            fileDescription: value,
          },
        };
        resources.push(newMaterial);
      }
    } else {
      const foundRelatedLinkValue = getRelatedLinkValue(name);
      if (foundRelatedLinkValue) {
        const newResource = {
          uniqueId: newGuid(),
          data: {
            fileName: resourceName,
            fileDescription: value,
            src: value,
            type: ResourceType.externalLink,
            order: 0,
            isExternal: true,
          },
          fieldName: name,
          action: 'add',
        };
        resources.push(newResource);
      }
    }
    return resources;
  };

  const handleTextResource = (name, value) => {
    console.log('### handleTextResource', content, name, value);
    let resources = content.resources;
    if (!resources) {
      resources = [];
    }
    let action = null;
    if (value) {
      if (name.indexOf('Link') !== -1) {
        resources = handleUrlValueChange(name, value);
      } else {
        resources = handleParentValueChange(name, value);
      }
    } else {
      action = 'delete';
      // delete material if any
      resources.push({
        uniqueId: newGuid(),
        fieldName: name,
        action: action,
      });
    }
    // console.log('onChange resources', resources);
    onChange([name, 'resources'], [value, resources], true);
  };

  // spc-5431 should not debounce here, coz this function is used for many text fields, it will skip the previous change if do changes immediately
  const handleTextFieldChangeWithResources = (event) => {
    // notice that this function is only used for changes related to resources.
    const name = event.target.name;
    const value = event.target.value;
    handleTextResource(name, value);
  };

  const handleHeadingChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleHeadingLinkChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleButtonLabelChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleButtonLinkChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleSecondaryButtonLabelChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleSecondaryButtonLinkChange = useDebouncedCallback(
    (event) => handleTextFieldChangeWithResources(event),
    400
  );

  const handleDescriptionLinksChange = useCallback(
    (event) => {
      console.log('### handleDescriptionLinksChange');
      const name = event.target.name;
      const value = event.target.value;
      const { content: descriptionContent, links } = value;
      const resources = [];
      const descriptionLinks = content.descriptionLinks || [];
      const newLinks = findNewLinks(links, descriptionLinks);
      const deletedLinks = findDeletedLinks(links, descriptionLinks);

      deletedLinks.forEach((link) => {
        const foundLinkIndex = _findIndex(
          descriptionLinks,
          (item) => isUrlsEqual(item.url, link.url) && item.text === link.text
        );
        if (foundLinkIndex !== -1) {
          resources.push({
            uniqueId: newGuid(),
            link: link,
            resourceId: descriptionLinks[foundLinkIndex].resourceId,
            materialId: descriptionLinks[foundLinkIndex].materialId,
            fieldName: 'descriptionLinks',
            action: 'delete',
          });
          descriptionLinks.splice(foundLinkIndex, 1);
        }
      });

      newLinks.forEach((link) => {
        const foundLinkIndex = _findIndex(
          descriptionLinks,
          (item) => item.url === link.url && item.text === link.text
        );
        if (foundLinkIndex === -1) {
          const uniqueId = newGuid();
          descriptionLinks.push({ ...link, uniqueId });

          const newResource = {
            uniqueId: uniqueId,
            data: {
              resourceId: undefined,
              fileName: link.url,
              fileDescription: link.text,
              src: link.url,
              type: ResourceType.externalLink,
              order: 0,
              isExternal: true,
            },
            link: link,
            fieldName: 'descriptionLinks',
            action: 'add',
          };

          resources.push(newResource);
        }
      });

      console.log('descriptionLinks', descriptionLinks);
      console.log('resources', resources);

      onChange(
        [name, 'descriptionLinks', 'resources'],
        [descriptionContent, descriptionLinks, resources],
        true
      );
    },
    [content.descriptionLinks, onChange]
  );

  const handleTextFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    onChange(name, value);
  };

  const handleHTMLContentChange = useDebouncedCallback(
    (event) => handleTextFieldChange(event),
    400
  );

  const handleWebContentLinkChange = useDebouncedCallback(async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const embeddable = await requestCheckEmbedableUrl(value);
    onChange([name, 'embeddable'], [value, embeddable]);
  }, 400);

  const handleOnChangeWidget = useDebouncedCallback((value) => {
    // console.log('value', value);
    onChange('widget', value);
  }, 300);

  const handleOnChangeScriptInput = useDebouncedCallback((name, value) => {
    // console.log('value', value);
    onChange(name, value);
  }, 300);

  const handleOnChangeWidgetName = useDebouncedCallback((event) => {
    const name = event.target.name;
    const value = event.target.value;
    onChange(name, value);
  }, 400);

  const handleResourceChange = (resource, contentType) => {
    const { fieldsUpdate, valuesUpdate } = onResourceChange(content, resource, contentType);
    onChange(fieldsUpdate, valuesUpdate, true);
  };

  const handleVideoChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const handleImageChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const handleFileChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const onChangePageName = (name, value) => {
    onChange(name, value);
  };

  const handlePageNameChange = useDebouncedCallback((name, value) => {
    onChangePageName(name, value);
  }, 400);

  const handleOnChangeImagePosition = useDebouncedCallback((value) => {
    onChange('imagePosition', value);
  }, 400);

  function renderComponent(component, index) {
    let element = null;
    const showDivider = showDescription || showHeading || showButton || showSecondaryButton;
    switch (component.component) {
      case CONTENT_COMPONENT.VIDEO_UPLOAD:
        element = (
          <>
            <CmsFileUploader
              key={index}
              contentType={component.component}
              componentType={type}
              onChange={handleVideoChange}
              resourceId={content.videoUrl || content.videoUrlResourceId}
              spaceId={spaceId}
              currentTab={props.currentTab}
              content={content}
              folderId={cmsDirectory?.id}
              isAdmin={isAdmin}
            />
            <div className="divider"></div>
          </>
        );
        break;
      case CONTENT_COMPONENT.IMAGE_UPLOAD: {
        if (type !== CMS_COMPONENT_TYPE.WIDGET && showImage) {
          element = (
            <>
              <CmsFileUploader
                key={index}
                contentType={component.component}
                componentType={type}
                onChange={handleImageChange}
                resourceId={content.backgroundImageUrl}
                spaceId={spaceId}
                ratio={layout?.imageRatio}
                currentTab={props.currentTab}
                content={content}
                folderId={cmsDirectory?.id}
              />
              {!!content.backgroundImageUrl && isMobileMode && (
                <CmsButtonGroup
                  className="bg-position-select"
                  options={BACKGROUND_IMAGE_POSITION_OPTION}
                  label={i18n.t('Focus point')}
                  onChange={handleOnChangeImagePosition}
                  value={content.imagePosition || 'center center'}
                />
              )}
              {showDivider && <div className="divider"></div>}
            </>
          );
        }
        break;
      }
      case CONTENT_COMPONENT.FILE_UPLOAD:
        element = (
          <>
            <CmsFileUploader
              key={index}
              contentType={component.component}
              componentType={type}
              onChange={handleFileChange}
              resourceId={content.backgroundImageUrl}
              spaceId={spaceId}
              ratio={layout?.imageRatio}
              currentTab={props.currentTab}
              content={content}
              folderId={cmsDirectory?.id}
            />
            {!!content.backgroundImageUrl && isMobileMode && (
              <CmsButtonGroup
                className="bg-position-select"
                options={BACKGROUND_IMAGE_POSITION_OPTION}
                label={i18n.t('Focus point')}
                onChange={handleOnChangeImagePosition}
                value={content.imagePosition || 'center center'}
              />
            )}
            {showDivider && <div className="divider"></div>}
          </>
        );
        break;
      case CONTENT_COMPONENT.HEADING:
        element = showHeading && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Heading')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              multiline
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={heading}
              name="heading"
              onChange={handleHeadingChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.HEADING_LINK:
        element = showHeading && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Heading link')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter link')}
              defaultValue={headingLink}
              name="headingLink"
              onChange={handleHeadingLinkChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.DESCRIPTION:
        element = showDescription && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Description')}</label>
            <Suspense fallback={<Loading />}>
              <FormatCmsContent
                key={index}
                id={`cms-description-${content.id}-${props.currentTab}`}
                defaultValue={description}
                name="description"
                onChange={handleDescriptionLinksChange}
              />
            </Suspense>
          </div>
        );
        break;
      case CONTENT_COMPONENT.BUTTON_LABEL:
        element = showButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Primary button text')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={buttonLabel}
              name="buttonLabel"
              onChange={handleButtonLabelChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.BUTTON_LINK:
        element = showButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Primary button link')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter link')}
              defaultValue={buttonLink}
              name="buttonLink"
              onChange={handleButtonLinkChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.SECONDARY_BUTTON_LABEL:
        element = showSecondaryButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Secondary button text')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={secondaryButtonLabel}
              name="secondaryButtonLabel"
              onChange={handleSecondaryButtonLabelChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.SECONDARY_BUTTON_LINK:
        element = showSecondaryButton && (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('Secondary button link')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter link')}
              defaultValue={secondaryButtonLink}
              name="secondaryButtonLink"
              onChange={handleSecondaryButtonLinkChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.WIDGET: {
        element = (
          <WidgetSelect
            onChange={handleOnChangeWidget}
            defaultValue={content}
            key={index}
            contentType={component.component}
            spaceFeatures={spaceFeatures}
          />
        );
        break;
      }
      case CONTENT_COMPONENT.URL_LINK:
        element = (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Website link')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder="www.google.com"
              defaultValue={content.webUrl}
              name="webUrl"
              onChange={handleWebContentLinkChange}
            />
            <div className="alert-info" style={{ marginTop: 20 }}>
              <Icons name="icon-new-info" className="icon" />
              <span>
                {i18n.t(
                  "This component is an iframe. Please note that some websites don't allow to be opened in an iframe."
                )}
              </span>
            </div>
          </div>
        );
        break;
      case CONTENT_COMPONENT.SCRIPT:
        element = (
          <ScriptInput
            key={index}
            onChange={handleOnChangeScriptInput}
            defaultValue={content.script}
            componentSettings={content}
          />
        );
        break;
      case CONTENT_COMPONENT.HTML_CONTENT:
        element = (
          <div className="cms-component-panel-control cms-html-control" key={index}>
            <label className="cms-component-panel-control-label">
              {i18n.t('HTML/CSS content')}
            </label>
            <TextField
              autoComplete="off"
              fullWidth
              multiline
              rows={10}
              className="cms-component-panel-control-input cms-html-control-input"
              variant="outlined"
              placeholder={i18n.t('Enter your code here')}
              defaultValue={content.htmlContent}
              name="htmlContent"
              onChange={handleHTMLContentChange}
            />
          </div>
        );
        break;
      case CONTENT_COMPONENT.PAGE_HEADING:
        element = showHeading && (
          <PageNameInput key={index} defaultValue={content.name} onChange={handlePageNameChange} />
        );
        break;
      case CONTENT_COMPONENT.PAGE_SHARE_LINK:
        element = <ShareLinkCmsBlock key={index} pageSlug={content.slug} />;
        break;

      case CONTENT_COMPONENT.WIDGET_TITLE:
        element = (
          <div className="cms-component-panel-control" key={index}>
            <label className="cms-component-panel-control-label">{i18n.t('Widget title')}</label>
            <TextField
              autoComplete="off"
              fullWidth
              className="cms-component-panel-control-input"
              variant="outlined"
              placeholder=""
              defaultValue={content.widgetName}
              name="widgetName"
              onChange={handleOnChangeWidgetName}
              InputProps={{
                inputProps: { maxLength: 50 },
              }}
            />
          </div>
        );
        break;
      default:
        break;
    }
    return <Fragment key={index}>{element}</Fragment>;
  }

  return formComponents.map((component, index) => renderComponent(component, index));
}

ContentForm.propTypes = {
  type: PropTypes.string,
  content: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  layout: PropTypes.shape({}),
  spaceId: PropTypes.string,
  currentTab: PropTypes.number,
  isAdmin: PropTypes.bool,
};

export default ContentForm;

export type FeaturePromo = {
  requestId: string;
  featureId: number;
  featureName: string;
  title: string;
  description: string;
  imageSrc: string;
  organizationId: string;
  successText: string;
  successButtonLabel: string;
  successButtonUrl: string;
};

export type PromoPopupProps = {
  title: string;
  description: string;
  imageSrc: string;
  isSavingData: boolean;
  onClose: () => void;
  onClickActivateTrial: () => void;
};

export type PromoPopupPayload = {
  status: number;
};

export const PromoRequestStatus = {
  Accepted: 1,
  Rejected: 2,
  Dismissed: 3,
};

export type PromoRequestResponse = {
  success: boolean;
  successText: string;
  successButtonLabel: string;
};

export type SuccessPromoPopupProps = {
  successText: string;
  successButtonLabel: string;
  onClose: () => void;
  onClickGoToFeature: () => void;
};

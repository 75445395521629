/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover, List, ListItemIcon, ListItemText, Checkbox, ListItemButton } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LockIcon from '@mui/icons-material/Lock';
import { useSelectedItems } from '../../commons/CustomHooks';
import './CustomizedMenu.scss';
import LightTooltip from '../../components/LightTooltip';

const Item = ({ item, checked, classname, onToggle }) => {
  const [isProcessing, setProcessing] = React.useState(false);

  async function onClick() {
    if (item.onClick) {
      setProcessing(true);
      await item.onClick(item);
    } else {
      onToggle(item.id);
    }
  }
  const labelId = `checkbox-list-label-${item.id}`;

  return (
    <>
      <ListItemButton
        key={item.id}
        className={classname}
        disabled={item.disabled === true || isProcessing}
        role={undefined}
        dense
        onClick={onClick}
        divider={item.showDivider}
        data-id={item.dataId}
      >
        {!item.icon ? (
          <ListItemIcon>
            {isProcessing ? (
              <div style={{ padding: 8, marginLeft: -8 }}>
                <i className="icon-spinner" />
              </div>
            ) : (
              <Checkbox
                edge="start"
                checked={checked}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
                icon={<RadioButtonUncheckedIcon fontSize="small" />}
                checkedIcon={<FiberManualRecordIcon fontSize="small" />}
              />
            )}
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <div className="menu-item-icon">{item.icon}</div>
          </ListItemIcon>
        )}

        <ListItemText id={labelId} primary={item.name} />
        {item.isLocked && (
          <LightTooltip title="Locked feature" placement="bottom-start">
            <LockIcon />
          </LightTooltip>
        )}
      </ListItemButton>
      {item.showDivider && <div className="divider"></div>}
    </>
  );
};
// const CustomizedMenuDefaultProps = {
//   items: [],
//   actionButtonLabel: 'save',
//   title: 'The title',
//   renderFooter: null,
//   isItemChecked: (checked, item) => checked.indexOf(item.id) !== -1,
//   defaultChecked: [],
//   renderHeader: null,
//   open: false,
// };
const CustomizedMenu = ({
  title,
  items,
  onClick,
  onChange,
  isItemChecked,
  defaultChecked,
  renderHeader,
  ...otherProps
}) => {
  const [checked, onToggleItem] = useSelectedItems(defaultChecked);

  const handleToggle = async (value) => {
    const newChechedValue = await onToggleItem(value);
    if (onChange && typeof onChange === 'function') {
      onChange(newChechedValue);
    }
  };

  if (!otherProps.open) {
    return null;
  }

  return (
    <>
      <Popover className="customized-menu-popover" {...otherProps}>
        <div className="customized-menu-wrapper">
          {renderHeader ? (
            renderHeader()
          ) : (
            <div className="customized-menu-header">
              <div className="customized-menu-title">{title}</div>
            </div>
          )}
          <div className="customized-menu-body styled-scrollbar">
            <List className="customized-menu-list">
              {items.map((item) => {
                return (
                  <Item
                    key={item.id}
                    item={item}
                    classname={item.name}
                    checked={isItemChecked(checked, item)}
                    onToggle={handleToggle}
                  />
                );
              })}
            </List>
          </div>
        </div>
      </Popover>
    </>
  );
};

CustomizedMenu.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isItemChecked: PropTypes.func,
  defaultChecked: PropTypes.instanceOf(Array),
  renderHeader: PropTypes.func,
  classname: PropTypes.string,
};

Item.propTypes = {
  item: PropTypes.instanceOf(Object),
  checked: PropTypes.bool,
  onToggle: PropTypes.func,
  classname: PropTypes.string,
  showDivider: PropTypes.bool,
};

export default CustomizedMenu;

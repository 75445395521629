import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { uniqBy } from 'lodash';

import memoizeOne from 'memoize-one';
import _find from 'lodash/find';
import _reject from 'lodash/reject';
import _forEachRight from 'lodash/forEachRight';
import _endsWith from 'lodash/endsWith';
import { useSpaceContext } from '../../SpaceContext';
import { fetchCommentsWithOffset } from '../CommentService';
import { formatDate, formatTimestamp } from '../../../commons/DateTimeUtils';
import useSpaceMaterialsByIdsQuery from '../../Materials/useSpaceMaterialsByIdsQuery';
import { CommentState } from '../../../app/appConstants';
import { CacheKeys } from '../../../app/queryCache';

function getInFoComment(comment) {
  const dateSend = formatDate(comment.created);
  let startTime = formatTimestamp(comment.created);
  const isToday = dateSend === formatDate(new Date());
  if (!isToday) {
    startTime = `${dateSend} ${startTime}`;
  }

  return {
    dateSend,
    startTime,
  };
}

const mappingDateComments = memoizeOne((data, materials) => {
  if (!data) return [];
  let dateCreated;
  let timeCreated;
  let participantId;

  _forEachRight(data, (comment) => {
    const { dateSend, startTime } = getInFoComment(comment);
    comment.startDateSend = null;
    comment.startTime = null;
    if (!_endsWith(comment.created, 'Z')) {
      comment.created = `${comment.created}Z`;
    }
    if (dateCreated !== dateSend) {
      dateCreated = dateSend;
      comment.startDateSend = dateSend;
    }
    if (comment.participantId !== participantId) {
      participantId = comment.participantId;
      timeCreated = comment.created;
      comment.startTime = startTime;
    } else {
      // if created of comment is in a 5 minutes range of the timeCreated
      const diff = new Date(comment.created) - new Date(timeCreated);
      if (diff > 300000) {
        comment.startTime = startTime;
        timeCreated = comment.created;
      }
    }
    if (comment.materialId > 0 && materials) {
      const resource = materials.find((r) => r.materialId === comment.materialId);
      if (resource) {
        comment.resource = resource;
      }
    }
    if (comment.parentComment?.materialId && materials) {
      const resource = materials.find((r) => r.materialId === comment.parentComment.materialId);
      if (resource) {
        comment.parentComment.resource = resource;
      }
    }
    if (comment.materialId > 0 || comment.resourceId > 0) {
      comment.hasAttachment = true;
    }
  });
  return data;
});

function useChatMessages(spaceId, channelId) {
  const [comments, setComments] = useState([]);
  const commentsRef = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [materialIds, setMaterialIds] = useState([]);
  const [pendingComments, setPendingComments] = useState([]);
  const { signalRConnection } = useSpaceContext();
  const { materials, isFetching: isFetchingMaterials } = useSpaceMaterialsByIdsQuery(
    spaceId,
    materialIds
  );

  const commentsQuery = useQuery({
    queryKey: [CacheKeys.fetchComments, spaceId, channelId],
    queryFn: async () => {
      const resp = await fetchCommentsWithOffset(spaceId, channelId, 0);
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: !!channelId,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    commentsRef.current = comments || [];
  }, [comments]);

  useEffect(() => {
    if (isFetchingMaterials) {
      return;
    }
    if (pendingComments.length > 0) {
      setComments((prevState) => {
        const newComments = pendingComments.concat(prevState);
        return uniqBy(newComments, 'id');
      });
      setPendingComments([]);
    }
  }, [pendingComments, isFetchingMaterials]);

  const loadMore = async () => {
    const resp = await fetchCommentsWithOffset(spaceId, channelId, comments.length);
    if (resp.materialIds?.length > 0) {
      setMaterialIds(resp.materialIds);
      setPendingComments(resp.items);
    } else {
      setComments((prevState) => prevState.concat(resp.items));
    }
    setIsLoading(false);
    if (!resp.hasMore) {
      setHasMore(false);
    }
  };
  const handleOnCommentAdded = useCallback(
    (detail) => {
      if (detail.channelId !== channelId) {
        return;
      }
      const newComments = [...commentsRef.current];

      if (detail.parentCommentId && !detail.parentComment) {
        // find parent then attach new comment to replies
        const foundComment = _find(newComments, (item) => item.id === detail.parentCommentId);
        if (foundComment) {
          detail.parentComment = foundComment;
        }
      }
      if (
        detail.parentComment?.materialId &&
        !materialIds?.includes(detail.parentComment?.materialId)
      ) {
        setMaterialIds((prevState) => prevState.concat(detail.parentComment.materialId));
      }
      const foundComment = _find(
        newComments,
        (item) => item.id === detail.id && !!detail.id && !!item.id
      );
      if (!foundComment) {
        newComments.unshift(detail);
      }
      setComments(newComments);
    },
    [channelId, materialIds]
  );

  const onCommentAdded = useCallback(
    (detail) => {
      const foundCommentRef = commentsRef.current.find(
        (item) => detail.refId && item.isAdded && item.refId === detail.refId
      );

      if (!foundCommentRef) {
        handleOnCommentAdded(detail);
      }
    },
    [handleOnCommentAdded]
  );

  const handleOnCommentUpdate = useCallback((comment) => {
    const newComments = [...commentsRef.current];
    const foundIndex = newComments.findIndex((item) => item.refId === comment.refId);
    if (foundIndex !== -1) {
      if (comment.id) {
        newComments[foundIndex].id = comment.id;
      }
      newComments[foundIndex].status = comment.status;
      newComments[foundIndex].refId = null;
    }
    setComments(newComments);
  }, []);

  useEffect(() => {
    if (!commentsQuery.data) {
      return;
    }
    const data = commentsQuery.data;
    if (data.materialIds?.length > 0) {
      setMaterialIds(data.materialIds);
      setPendingComments(data.items);
    } else {
      setComments(data.items);
    }
    if (!data.hasMore) {
      setHasMore(false);
    }
  }, [commentsQuery.data]);

  useEffect(() => {
    function handleOnCommentUpdated(detail) {
      if (detail.channelId !== channelId) {
        return;
      }
      const newComments = [...commentsRef.current];
      const foundCommentReply = newComments.filter((item) => item.parentCommentId === detail.id);

      if (foundCommentReply?.length > 0) {
        foundCommentReply.forEach((reply) => {
          reply.parentComment.text = detail.text;
          reply.parentComment.resourceId = detail.resourceId;
          reply.parentComment.resourceName = detail.resourceName;
          reply.parentComment.materialId = detail.materialId;
          if (
            reply.parentComment.resource &&
            reply.parentComment.resource.materialId !== detail.materialId
          ) {
            reply.parentComment.resource = null;
          }
        });
      }

      const foundComment = _find(newComments, (item) => item.id === detail.id);
      if (foundComment) {
        foundComment.text = detail.text;
        foundComment.resourceId = detail.resourceId;
        foundComment.resourceName = detail.resourceName;
        foundComment.materialId = detail.materialId;
        if (foundComment.resource && foundComment.resource.materialId !== detail.materialId) {
          foundComment.resource = null;
        }
      }
      setComments(newComments);
    }

    function handleOnCommentDeleted(detail) {
      if (detail.channelId !== channelId) {
        return;
      }
      const { id } = detail;
      const newComments = [...commentsRef.current];

      const foundCommentReply = newComments.filter((item) => item.parentCommentId === detail.id);

      if (foundCommentReply?.length > 0) {
        foundCommentReply.forEach((reply) => {
          reply.parentComment.state = CommentState.Deleted;
          reply.parentComment.text = 'This message has been removed';
        });
      }
      const foundComment = _find(newComments, (item) => item.id === id);
      if (foundComment) {
        foundComment.state = CommentState.Deleted;
        foundComment.text = 'This message has been removed';
      }

      setComments(newComments);
    }

    function handleOnLikeAdded(detail) {
      if (detail.channelId !== channelId) {
        return;
      }
      const { commentId, commentInteraction } = detail;
      const newComments = [...commentsRef.current];
      const foundComment = _find(newComments, (item) => item.id === commentId);
      if (foundComment) {
        if (foundComment.commentInteractions) {
          const foundInteraction = _find(
            foundComment.commentInteractions,
            (item) => item.id === commentInteraction.id
          );
          if (!foundInteraction) {
            foundComment.commentInteractions.push(commentInteraction);
          }
        } else {
          foundComment.commentInteractions = [commentInteraction];
        }
      }
      console.log('foundComment: ', foundComment);
      setComments(newComments);
    }

    function handleOnLikeDeleted(detail) {
      if (detail.channelId !== channelId) {
        return;
      }

      const { commentId, commentInteraction } = detail;
      const newComments = [...commentsRef.current];
      const foundComment = _find(newComments, (item) => item.id === commentId);
      if (foundComment) {
        foundComment.commentInteractions = _reject(
          foundComment.commentInteractions,
          (item) => item.id === commentInteraction.id
        );
      }
      setComments(newComments);
    }

    if (signalRConnection && channelId) {
      console.log('connection on');
      signalRConnection.on('CommentAddedEvent', onCommentAdded);
      signalRConnection.on('CommentUpdatedEvent', handleOnCommentUpdated);
      signalRConnection.on('CommentDeletedEvent', handleOnCommentDeleted);
      signalRConnection.on('InteractionAddedEvent', handleOnLikeAdded);
      signalRConnection.on('InteractionRemovedEvent', handleOnLikeDeleted);
    }

    return () => {
      if (signalRConnection && channelId) {
        console.log('connection off');
        signalRConnection.off('CommentAddedEvent', onCommentAdded);
        signalRConnection.off('CommentUpdatedEvent', handleOnCommentUpdated);
        signalRConnection.off('CommentDeletedEvent', handleOnCommentDeleted);
        signalRConnection.off('InteractionAddedEvent', handleOnLikeAdded);
        signalRConnection.off('InteractionRemovedEvent', handleOnLikeDeleted);
      }
    };
  }, [channelId, signalRConnection, onCommentAdded]);

  return {
    comments: mappingDateComments(comments, materials),
    setComments,
    isLoading: commentsQuery.isLoading || isLoading,
    hasMore,
    loadMore,
    handleAddComment: handleOnCommentAdded,
    handleUpdateComment: handleOnCommentUpdate,
  };
}

export default useChatMessages;

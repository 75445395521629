import { useQuery } from '@tanstack/react-query';
import { getPreviewCertificatePreview } from '../services';
import { CacheKeys } from '../../app/queryCache';

function useGetCertificateTemplateQuery(id) {
  const userBlocks = useQuery({
    queryKey: [CacheKeys.courseBlocks_GetCertificateTemplate, id],
    queryFn: async () => {
      const resp = await getPreviewCertificatePreview(id);
      return resp;
    },
    enabled: !!id,
  });

  return userBlocks;
}

export default useGetCertificateTemplateQuery;

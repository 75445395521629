import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';

import './CmsHideBlock.scss';
import { CMS_COMPONENT_STYLE } from '../cmsConstants';
import Icons from '../../components/Icons';
import LightTooltip from '../../components/LightTooltip';

const CmsHideBlock = (props) => {
  const { onChange, options, label } = props;
  const [hideBlock, setHideBlock] = React.useState(
    props.value || {
      [CMS_COMPONENT_STYLE.HIDDEN.DESKTOP]: false,
      [CMS_COMPONENT_STYLE.HIDDEN.MOBILE]: false,
    }
  );
  const icons = useMemo(() => {
    return {
      [CMS_COMPONENT_STYLE.HIDDEN.DESKTOP]: (
        <Icons
          className="icon"
          name={
            hideBlock[CMS_COMPONENT_STYLE.HIDDEN.DESKTOP]
              ? 'icon-desktop-dark'
              : 'icon-desktop-light'
          }
        />
      ),
      [CMS_COMPONENT_STYLE.HIDDEN.MOBILE]: <PhoneIcon />,
    };
  }, [hideBlock]);

  const handleOnChange = (value) => {
    setHideBlock((prev) => {
      const newValue = { ...prev };
      newValue[value] = !newValue[value];
      onChange(newValue);
      return newValue;
    });
  };

  const getByTitle = (value, name) => {
    if (hideBlock[value]) {
      return `Show on ${name}`;
    }
    return `Hide on ${name}`;
  };

  return (
    <Grid className="cms-hide-block">
      <div className="label">
        {label}
        <LightTooltip
          title={
            'Control the visibility of this block based on device type. Choose to hide or display it on mobile or desktop view.'
          }
          placement="bottom-start"
        >
          <i className="icon-info" />
        </LightTooltip>
      </div>
      <div className="cms-hide-block-content">
        {options.map(({ value, name }, index) => (
          <LightTooltip key={index} title={getByTitle(value, name, value)}>
            <div
              className={`item ${hideBlock[value] ? 'active' : ''}`}
              onClick={() => handleOnChange(value)}
            >
              {icons[value]}
              {name}
            </div>
          </LightTooltip>
        ))}
      </div>
      <div className="alert-info">
        <span>
          The display settings (show/hide for mobile or desktop) will only take effect once you are
          on the live page, not when you’re in editing mode.
        </span>
      </div>
    </Grid>
  );
};

CmsHideBlock.propTypes = {
  label: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Object),
};

export default CmsHideBlock;

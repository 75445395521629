import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { getAppInsightsKey, isLocal, isProd, isStaging } from '../commons/utils';
import spaceUser from '../spaces/spaceUser';
import currentOrganization from '../commons/CurrentOrganization';

export const LogLevels = {
  INFO: SeverityLevel.Information,
  WARN: SeverityLevel.Warning,
  ERROR: SeverityLevel.Error,
  CRITICAL: SeverityLevel.Critical,
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getAppInsightsKey(),
    enableAutoRouteTracking: true,
    disableAjaxTracking: true,
    disableFetchTracking: true,
    excludeRequestFromAutoTrackingPatterns: ['/api/insights', '/interactions'],
    extensions: [reactPlugin],
    // extensionConfig: {
    //   [reactPlugin.identifier]: { history: browserHistory },
    // },
    disableCookiesUsage: true,
  },
});

if (!isLocal()) {
  if (isProd()) {
    console.log = () => null;
    console.warn = () => null;
  }
  appInsights.loadAppInsights();
  console.error = (error, data = null) => {
    try {
      // if (typeof error === 'string' && error.indexOf('The above error occurred in the') === 0) {
      //   // console.trace(`Error logging to AppInsights: ${error}`);
      //   return;
      // }
      const exception = error instanceof Error ? error : null;
      if (!exception) {
        return;
      }

      const msg = exception.message || '';

      if (
        msg.indexOf('StrictMode') >= 0 ||
        msg.indexOf('Warning:') >= 0 ||
        msg.indexOf('Server timeout elapsed without receiving a message from the server') >= 0 ||
        msg.indexOf('WebSocket closed with status code: 1006') >= 0 ||
        msg.indexOf('Failed to complete negotiation with the server') >= 0
      ) {
        return;
      }
      const properties = data instanceof Object ? { ...data } : {};
      properties.env = isStaging() ? 'stg' : process.env.REACT_APP_ENV;
      const spaceAccessSession = spaceUser.getSpaceAccessSession();
      if (spaceAccessSession) {
        properties.spaceId = spaceUser.getSpaceId();
        properties.email = spaceAccessSession.email;
        properties.organizationId = spaceAccessSession.organizationId;
      } else {
        properties.organizationId = currentOrganization.getOrganizationId();
      }
      const severityLevel = exception.severityLevel || LogLevels.ERROR;
      appInsights.trackException({ exception, severityLevel, properties });
    } catch (err) {
      console.trace(`Error logging to AppInsights: ${err.message}`);
    }
  };
}

export { reactPlugin, appInsights };

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
// import i18n from '../../i18n';
import '../cmsStyle.scss';
import './PagesNavigation.scss';
// import { useAppContext } from '../../components/AppProvider';
import { useTabs } from '../../components/Tabs';
import { checkAdditionalStyles, checkAvailableFonts } from '../commons/FontSelect/utils';
import { useSpaceContext } from '../../spaces/SpaceContext';
import useMyFontListQuery from '../commons/FontSelect/MyFontListQuery';

function PagesNavigation(props) {
  // const { onClick, pagesBlock, isPortal, sticky, onChangeNavigation } = props;
  // const { isTablet, isDesktop } = useAppContext();
  const {
    headingColor,
    headingFontFamily,
    headingFontSize,
    headingSelectedColor,
    headingSelectedFontSize,
    headingSelectedFontFamily,
    underlineColor,
    // bottomPaddingSize,
    // rightPaddingSize,
    // topPaddingSize,
    backgroundColor,
    pages,
    defaultTab,
    isMobile,
  } = props;

  const initialTabs = pages.map((page) => page.name);
  const { tabs, changeTab, activeTab } = useTabs(initialTabs, defaultTab);
  const [isOpen, setIsOpen] = React.useState(false);
  const { myFonts } = useMyFontListQuery(true, true);

  const { onChangeNavigation } = useSpaceContext();

  const sticky = false;

  useEffect(() => {
    if (defaultTab !== undefined && defaultTab !== activeTab) {
      changeTab(defaultTab);
    }
  }, [activeTab, defaultTab, changeTab]);

  const getFont = (fontFamily) => {
    return checkAvailableFonts(fontFamily, myFonts);
  };
  const headingStyle = {
    color: headingColor,
    fontFamily: getFont(headingFontFamily),
    fontSize: headingFontSize,
    lineHeight: 1.25,
    ...checkAdditionalStyles(headingFontFamily),
  };

  const headingSelectedStyle = {
    color: headingSelectedColor,
    fontFamily: getFont(headingSelectedFontFamily),
    fontSize: headingSelectedFontSize,
    lineHeight: 1.25,
    borderBottomColor: underlineColor,
    ...checkAdditionalStyles(headingSelectedFontFamily),
  };

  const handleOnChangeNavigation = (value) => {
    changeTab(value);
    onChangeNavigation(value);
  };
  const handleToggleMobileNavigation = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const onClick = (e) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(props.index);
    }
  };

  const handleClick = (event) => {
    onClick(event);

    if (isMobile && !event.target.closest('.cms-pages-navigation-tab')) {
      setIsOpen(false);
    }
  };

  function renderPagesNavigation() {
    return (
      <div className={`cms-pages-navigation ${sticky ? 'sticky' : ''}`} onClick={handleClick}>
        <div
          className={`tabs tabs-level-2 cms-pages-navigation-tab`}
          style={{ backgroundColor: backgroundColor }}
        >
          {tabs?.map((e, i) => (
            <div
              key={i}
              className={`
                tab-item ${e.active === true ? 'active' : ''} ${!e.label ? 'tab-item-hidden' : ''}`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleOnChangeNavigation(i);
                handleClose();
              }}
            >
              <div
                className="tab-wrapper"
                id={`page-tab-${pages[i]?.id}`}
                style={e.active ? headingSelectedStyle : headingStyle}
              >
                <span className="tab-label">{e?.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (isMobile) {
    return (
      <div className="cms-pages-navigation-mobile" onClick={onClick}>
        <IconButton onClick={handleToggleMobileNavigation}>
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        {isOpen && renderPagesNavigation()}
      </div>
    );
  }
  return renderPagesNavigation();
}

PagesNavigation.propTypes = {
  index: PropTypes.number,
  onClick: PropTypes.func,
  // pagesBlock: PropTypes.instanceOf(Array),
  headingColor: PropTypes.string,
  headingFontFamily: PropTypes.string,
  headingFontSize: PropTypes.string,
  headingSelectedColor: PropTypes.string,
  headingSelectedFontFamily: PropTypes.string,
  headingSelectedFontSize: PropTypes.string,
  underlineColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  pages: PropTypes.instanceOf(Array),
  defaultTab: PropTypes.number,
  isMobile: PropTypes.bool,
  // sticky: PropTypes.bool,
};

export default PagesNavigation;

import React from 'react';
import PropTypes from 'prop-types';
// import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import {
  ResourceType,
  ResourceState,
  ResourceInteractionType,
  SpaceInteractionType,
  ResourceTypeNames,
} from '../app/appConstants';
import ErrorMessage from '../components/ErrorMessage';
import ImageViewer from '../components/ImageViewer';
import OfficeAppViewer from '../components/OfficeAppViewer';
import ExternalResourceViewer from '../components/ExternalResourceViewer';
import PdfViewer from '../components/PdfViewer';
import CadViewer from '../components/CadViewer';
import i18n from '../i18n';
import spaceUser from '../spaces/spaceUser';
import AudioViewer from '../components/AudioViewer';
import { getExtensionFromUrl, getVideoResourceData } from '../commons/ResourceUtils';
import { isIntegrationResource } from '../integrations/integrationResourceUtils';
import IntegrationResourceViewer from '../integrations/IntegrationResourceViewer';
import { useResourceInteractionContext } from './ResourceInteractionContextProvider';
import { createResourceInteraction } from '../commons/CommonServices';
import eventBus, { EVENT_BUS } from '../commons/EventBus';
import TiffViewer from '../components/TiffViewer/TiffViewer';

const ResourceViewer = (props) => {
  const {
    context,
    materialId,
    resourceId,
    controls,
    autoPlay,
    documentName,
    showDownloadView,
    showInfoView,
    isRecordingFile,
    isDownloadable,
    preventEvents,
    resource,
  } = props;
  const { url, token, videoIndexer, orgSrc, extension } = resource;

  const { resourceInteractionSessionId } = useResourceInteractionContext();
  const isPortal = context?.type === 'portal';
  const isHost = isPortal ? true : spaceUser.isHost();
  const spaceId = props.spaceId || spaceUser.getSpaceId();

  function getUrlResource() {
    if (!resource) {
      return '';
    }
    const { videoURL } = getVideoResourceData(resource);
    console.log('### 192 ResourceViewer videoURL:', videoURL);
    return videoURL;
  }
  if (!resource?.resourceId) {
    return (
      <ErrorMessage message={i18n.t('Sorry, this resource is not available at the moment!')} />
    );
  }

  function handleAudioOnchange() {
    if (!isPortal && resourceId && materialId) {
      createResourceInteraction(resourceId, {
        materialId: materialId,
        interactionType: ResourceInteractionType.Click,
      });
    }
  }

  function sentAudioViewEventToInsight(totalTime) {
    if (!isPortal && resourceId && materialId) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId: resourceId,
        materialId: materialId,
        isCms: false,
        duration: Math.round(totalTime),
        resourceType: ResourceTypeNames.audio,
      });
    }
  }

  function renderViewer() {
    const documentUrl = url;
    const type = resource.resourceType;
    console.log('### 192 ResourceViewer orgSrc:', orgSrc);

    if (isIntegrationResource(url)) {
      return (
        <IntegrationResourceViewer
          resourceId={resourceId}
          materialId={materialId}
          src={url}
          name={documentName}
          externalViewerToken={token}
          type={type}
          isPortal={isPortal}
          showDownloadView={showDownloadView}
          showInfoView={showInfoView}
          // onDownloaded={handleOnDownloaded} // tracking download IA
          extension={extension}
          fileSize={props.fileSize}
        />
      );
    } else if (isIntegrationResource(orgSrc)) {
      return (
        <IntegrationResourceViewer
          resourceId={resourceId}
          materialId={materialId}
          publicUrl={url}
          src={orgSrc}
          name={documentName}
          externalViewerToken={token}
          type={type}
          isPortal={isPortal}
          showDownloadView={showDownloadView}
          showInfoView={showInfoView}
          // onDownloaded={handleOnDownloaded} // tracking download IA
          extension={extension}
          fileSize={props.fileSize}
        />
      );
    }
    // if (!showDownloadView && isOneOfResourceTypes(type, ConvertToPDFResourceTypes)) {
    //   const nonParamUrl = url.split('?')[0];
    //   const extension = nonParamUrl.substring(nonParamUrl.lastIndexOf('.') + 1);
    //   if (extension !== 'pdf') {
    //     // in case of the state = converted, but the url is not a pdf.
    //     queryCache.removeQueries([CacheKeys.getResourceForViewer, resourceId, materialId]);
    //     return <DownloadResourceView resourceId={resourceId} url={url} name={documentName} />;
    //   }
    // }

    switch (type) {
      case ResourceType.image: {
        const mExtension = getExtensionFromUrl(url, extension);
        const isTiff = mExtension === 'tiff' || mExtension === 'tif';
        if (isTiff) {
          return <TiffViewer src={url} className="image-viewer" resourceId={resourceId} />;
        }
        return <ImageViewer src={props.isExternal ? decodeURIComponent(url) : url} />;
      }
      case ResourceType.video: {
        const src = getUrlResource();
        console.log('### 192 ResourceViewer src:', src);
        return (
          <ExternalResourceViewer
            laUrl={resource.drm?.laUrl}
            src={src}
            token={token}
            videoIndexer={videoIndexer}
            controls={controls}
            autoPlay={autoPlay}
            resourceId={resourceId}
            materialId={materialId}
            isRecordingFile={isRecordingFile}
            resourceInteractionSessionId={resourceInteractionSessionId}
          />
        );
      }

      case ResourceType.powerpoint:
      case ResourceType.excel:
      case ResourceType.word:
        if (resource.resourceState === ResourceState.Converted)
          return (
            <PdfViewer
              resourceId={resourceId}
              materialId={materialId}
              src={documentUrl}
              downloadable
            />
          );
        return <OfficeAppViewer src={orgSrc} />;
      case ResourceType.pdf:
      case ResourceType.keynote:
      case ResourceType.signingDocument:
        return (
          <PdfViewer
            resourceId={resourceId}
            materialId={materialId}
            src={documentUrl}
            downloadable={isDownloadable}
            preventEvents={preventEvents}
            spaceId={spaceId}
          />
        );
      case ResourceType.cad:
        return (
          <CadViewer
            urn={documentUrl}
            token={token}
            triggerResourceConversion={props.triggerResourceConversion}
            isHost={isHost}
          />
        );
      case ResourceType.audio:
        return (
          <AudioViewer
            src={url}
            autoPlay={autoPlay}
            name={documentName}
            onChange={handleAudioOnchange}
            sentAudioViewEventToInsight={sentAudioViewEventToInsight}
          />
        );
      default: {
        let finalUrl = url;
        if (url?.toLowerCase().includes('google')) {
          finalUrl = decodeURIComponent(url);
        }

        return (
          <iframe
            title="resource-external-url"
            src={finalUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{ backgroundColor: '#fff' }}
          ></iframe>
        );
      }
    }
  }

  return renderViewer();
};

ResourceViewer.propTypes = {
  resource: PropTypes.instanceOf(Object),
  materialId: PropTypes.number,
  resourceId: PropTypes.number,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  documentName: PropTypes.string,
  context: PropTypes.instanceOf(Object),
  showDownloadView: PropTypes.bool,
  showInfoView: PropTypes.bool,
  isExternal: PropTypes.bool,
  spaceId: PropTypes.string,
  isRecordingFile: PropTypes.bool,
  fileSize: PropTypes.string,
  isDownloadable: PropTypes.bool,
  preventEvents: PropTypes.bool,
  triggerResourceConversion: PropTypes.func,
};

ResourceViewer.defaultProps = {
  controls: true,
  autoPlay: false,
  showDownloadView: false,
  showInfoView: false,
  isExternal: false,
};

export default ResourceViewer;

import { useQuery } from '@tanstack/react-query';
import memoizeOne from 'memoize-one';
import { fetchSpaceCourses } from '../services';
import { CacheKeys } from '../../app/queryCache';

// sort ids function
const sortIds = memoizeOne((ids) => {
  return ids?.sort();
});

function useSpaceCoursesQuery(ids, isPortal, enabled = true) {
  const sortedIds = sortIds(ids);
  const query = useQuery({
    queryKey: [CacheKeys.courseBlocks_fetchSpaceCourses, sortedIds, isPortal],
    queryFn: async () => {
      const resp = await fetchSpaceCourses(sortedIds, isPortal);
      return resp;
    },
    enabled: enabled,
  });

  return {
    query,
    isLoading: query.isLoading,
    spaceCourses: query.data,
    refetch: query.refetch,
  };
}

export default useSpaceCoursesQuery;

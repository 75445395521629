import memoizeOne from 'memoize-one';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../../app/queryCache';
import getGoogleFonts from './getGoogleFonts';

// spc-4278
const STATIC_POPULAR_FONTS = [
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Noto Sans JP',
  'Roboto Condensed',
  'Oswald',
  'Source Sans Pro',
  'Raleway',
  'Rubik',
  'Poppins',
  'PT Sans',
  'Roboto Slab',
  'Noto Sans',
  'Ubuntu',
  'Merriweather',
  'Roboto Mono',
  'Lora',
  'Playfair Display',
  'Nunito',
];

const getAppGoogleFonts = memoizeOne((fonts) => {
  const dictStaticFonts = STATIC_POPULAR_FONTS.reduce((dict, el) => {
    const key = el;
    dict[key] = el;
    return dict;
  }, {});
  const filteredFonts = fonts.filter((f) => dictStaticFonts[f.name] || dictStaticFonts[f.family]);
  return filteredFonts;
});

const useGoogleFontsQuery = () => {
  const getFontList = useQuery({
    queryKey: [CacheKeys.requestGoogleFontsList],
    queryFn: async () => {
      const resp = await getGoogleFonts();
      const popularGoogleFonts = getAppGoogleFonts(resp);
      // const popularGoogleFonts = resp.slice(0, 20);
      // console.log('### popularGoogleFonts', popularGoogleFonts);
      return popularGoogleFonts;
    },
    ...{
      retry: 1,
      retryDelay: () => 3000,
      // refetchOnMount: 'always',
    },
  });

  return {
    fonts: getFontList.data,
    isLoading: getFontList.isLoading,
  };
};

export default useGoogleFontsQuery;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';
import './LightTooltip.scss';

type LightTooltipProps = {
  title?: React.ReactNode;
  children: React.ReactNode;
} & TooltipProps;

const LightTooltip: React.FC<LightTooltipProps> = ({ title, children, ...props }) => {
  if (!title) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      {...props}
      title={<>{title}</>}
      classes={{ tooltip: 'tooltip-custom', popper: props.className }}
    >
      {children}
    </Tooltip>
  );
};

export default LightTooltip;

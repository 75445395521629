import React, { useMemo } from 'react';
import { Collapse, InputAdornment, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import { useDebouncedCallback } from 'use-debounce';
import { useThemeSettingsContext } from '../components/context/ThemeSettingsContextProvider';
import {
  BACKGROUND_IMAGE_POSITION_OPTION,
  BACKGROUND_IMAGE_SIZE_OPTION,
  CMS_COMPONENT_STYLE,
  CMS_DEFAULT_DESIGN_SETTING,
  CONTENT_COMPONENT,
} from '../cmsConstants';
import i18n from '../../i18n';
import CmsSelect from './CmsSelect';
import CmsFileUploader from './CmsFileUploader';
import { getWidthAndHeightImage, onResourceChange } from './resourceFunctions';
import useCmsDirectory from '../components/useCmsDirectory';
import CmsButtonGroup from './CmsButtonGroup';
import './CmsBackgroundImageBlock.scss';
import useGetResourceForViewerQuery from '../../resources/getResourceForViewerQuery';
import OfficialButton from '../../components/OfficialButtons';

const StyleBackgroundDefault = {
  blockBackgroundSize: CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_SIZE,
  blockBackgroundPosition: CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_POSITION,
  blockBackgroundOpacity: CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_OPACITY,
  widthBackgroundSize: 0,
  heightBackgroundSize: 0,
};

export const getStyleBackgroundValue = (block, mode) => {
  const style = block?.styles?.[mode] || block?.styles?.desktop || StyleBackgroundDefault;
  return {
    blockBackgroundSize:
      style.blockBackgroundSize || CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_SIZE,
    blockBackgroundPosition:
      style.blockBackgroundPosition || CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_POSITION,
    blockBackgroundOpacity:
      style.blockBackgroundOpacity === null || style.blockBackgroundOpacity === undefined
        ? CMS_DEFAULT_DESIGN_SETTING.BLOCK_BACKGROUND_OPACITY
        : style.blockBackgroundOpacity,
    widthBackgroundSize: style.widthBackgroundSize || 0,
    heightBackgroundSize: style.heightBackgroundSize || 0,
  };
};

function CmsBackgroundImageBlock(props) {
  const { type } = props;
  const { spaceId, selectedBlock, updateBlock, isMobileMode } = useThemeSettingsContext();
  const { backgroundImageUrl } = selectedBlock;
  const [cmsDirectory] = useCmsDirectory(spaceId);
  const designMode = isMobileMode ? 'mobile' : 'desktop';
  const resourceId = backgroundImageUrl;
  const imageRatio = '16:9';
  const getResourceQuery = useGetResourceForViewerQuery(
    spaceId,
    resourceId,
    null,
    true,
    resourceId > 0
  );
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  const {
    blockBackgroundSize,
    blockBackgroundPosition,
    blockBackgroundOpacity,
    widthBackgroundSize,
    heightBackgroundSize,
  } = useMemo(() => {
    return getStyleBackgroundValue(selectedBlock, designMode);
  }, [selectedBlock, designMode]);

  function updateValueBlock(prop, value) {
    const newBlock = _cloneDeep(selectedBlock);
    if (typeof prop === 'string') {
      newBlock[prop] = value;
      updateBlock(newBlock);
    } else if (typeof prop === 'object' && typeof value === 'object') {
      prop.forEach((propItem, index) => {
        newBlock[propItem] = value[index];
      });
      updateBlock(newBlock, true);
    }
  }

  const handleResourceChange = (resource, contentType) => {
    const { fieldsUpdate, valuesUpdate } = onResourceChange(selectedBlock, resource, contentType);
    updateValueBlock(fieldsUpdate, valuesUpdate);
  };
  const handleUpdateStyles = (styles) => {
    const newBlock = _cloneDeep(selectedBlock);
    newBlock.styles = newBlock.styles || {
      desktop: {},
      mobile: {},
    };
    newBlock.styles = {
      ...newBlock.styles,
      [designMode]: {
        ...newBlock.styles?.[designMode],
        ...styles,
      },
    };
    updateBlock(newBlock);
  };
  const handleOnChangeBlockBackgroundSize = useDebouncedCallback(async (value) => {
    const updateValues = {};
    updateValues.blockBackgroundSize = value;
    if (value === CMS_COMPONENT_STYLE.BACKGROUND_IMAGE_SIZE.CUSTOM) {
      updateValues.blockBackgroundPosition = CMS_COMPONENT_STYLE.BG_IMAGE_POSITION.CENTER_CENTER;

      if (getResourceQuery.data.url) {
        try {
          const { width, height } = await getWidthAndHeightImage(getResourceQuery.data.url);
          updateValues.widthBackgroundSize = width;
          updateValues.heightBackgroundSize = height;
        } catch (error) {
          console.error('Error get width and height image: ', error);
        }
      }
    }
    handleUpdateStyles(updateValues);
  }, 400);

  const handleOnChangeOverlayOpacity = useDebouncedCallback((value) => {
    handleUpdateStyles({ blockBackgroundOpacity: value });
  }, 400);

  const handleOnChangePosition = useDebouncedCallback((value) => {
    handleUpdateStyles({ blockBackgroundPosition: value });
  }, 400);

  const handleImageChange = useDebouncedCallback(
    (resource, contentType) => handleResourceChange(resource, contentType),
    400
  );

  const handleChangeWidthBackgroundSize = useDebouncedCallback((event) => {
    handleUpdateStyles({ widthBackgroundSize: event.target.value });
  }, 400);

  const handleChangeHeightBackgroundSize = useDebouncedCallback((event) => {
    handleUpdateStyles({ heightBackgroundSize: event.target.value });
  }, 400);

  const renderAdvancedSettings = () => {
    if (!backgroundImageUrl) {
      return null;
    }
    return (
      <>
        <OfficialButton
          className="advanced-button"
          label={i18n.t('Advanced option')}
          variant="small-rectangle-secondary"
          onClick={() => setShowAdvanced(!showAdvanced)}
          icon={<ExpandMoreIcon />}
        />
        <Collapse in={showAdvanced} className="advanced-settings">
          <div className="field mt-24">
            <CmsSelect
              label={i18n.t('Fitting options')}
              options={BACKGROUND_IMAGE_SIZE_OPTION}
              value={blockBackgroundSize}
              onChange={handleOnChangeBlockBackgroundSize}
            />
          </div>
          {blockBackgroundSize === CMS_COMPONENT_STYLE.BACKGROUND_IMAGE_SIZE.CUSTOM && (
            <div className="two-field">
              <div className="field-control">
                <label>{i18n.t('Width')}</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  key={designMode}
                  defaultValue={widthBackgroundSize}
                  onChange={handleChangeWidthBackgroundSize}
                  type="number"
                  InputProps={{
                    inputProps: { max: 2000, min: 0 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="pixels">px</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="field-control">
                <label>{i18n.t('Height')}</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  key={designMode}
                  defaultValue={heightBackgroundSize}
                  onChange={handleChangeHeightBackgroundSize}
                  type="number"
                  InputProps={{
                    inputProps: { max: 2000, min: 0 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <span className="pixels">px</span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          )}
          <div className="field">
            <CmsButtonGroup
              className="bg-position-select"
              options={BACKGROUND_IMAGE_POSITION_OPTION}
              label={i18n.t('Position')}
              onChange={handleOnChangePosition}
              value={blockBackgroundPosition}
            />
          </div>
          <div className="field">
            <CmsButtonGroup
              options={[0.3, 0.2, 0.1, 0]}
              label={i18n.t('Overlay opacity')}
              onChange={handleOnChangeOverlayOpacity}
              value={blockBackgroundOpacity}
            />
          </div>
        </Collapse>
      </>
    );
  };

  return (
    <>
      <div className="field">
        <CmsFileUploader
          contentType={CONTENT_COMPONENT.BACKGROUND_IMAGE}
          componentType={type}
          onChange={handleImageChange}
          resourceId={backgroundImageUrl}
          spaceId={spaceId}
          ratio={imageRatio}
          content={selectedBlock}
          folderId={cmsDirectory?.id}
          currentTab={0}
        />
      </div>
      {renderAdvancedSettings()}
      <div className="divider" />
    </>
  );
}

CmsBackgroundImageBlock.propTypes = {
  type: PropTypes.string,
};

export default CmsBackgroundImageBlock;

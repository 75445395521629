import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import memoizeOne from 'memoize-one';
import { useSpaceContext } from './SpaceContext';
import {
  updateNotificationRead,
  updateNotificationsBadgesBySpaceId,
} from '../settings/notifications/service';
import { notificationType } from '../components/Notification/constants';
import { useChatContext } from './Comments/ChatContextProvider';
import { CacheKeys, clearCache } from '../app/queryCache';
// import { OnBoardSpaces } from '../app/appConstants';
import FloatingMenu from '../components/FloatingMenu';
import { triggerPanelOpenedEvent } from '../SpaceAnalytics/services/SpaceAnalyticsRuntimeService';

const checkAnySpaceFeatureEnabled = memoizeOne((spaceFeatures, alwaysShowParticipant) => {
  const newSpaceFeatures = { ...spaceFeatures };
  if (alwaysShowParticipant) {
    newSpaceFeatures.Participants = true;
  }
  return newSpaceFeatures;
});

const SpaceFooter = (props) => {
  const {
    sidebar,
    setSidebar,
    isHostOfSpace,
    space,
    newNotifications,
    setNotifications,
    spaceFeatures,
  } = useSpaceContext();

  const { chatPublicChannel } = useChatContext();

  const alwaysShowParticipant = isHostOfSpace;

  const features = checkAnySpaceFeatureEnabled(spaceFeatures, alwaysShowParticipant);

  function handleToggleSideBar(newSidebar) {
    if (newSidebar === sidebar) {
      setSidebar(null);
    } else {
      setSidebar(newSidebar);
      triggerPanelOpenedEvent(space.id, newSidebar);
    }
  }

  async function onClickIcons(type) {
    if (props.isDemoView) {
      return;
    }
    handleToggleSideBar(type);
    const context = notificationType[type];
    const newNotify = cloneDeep(newNotifications);

    if (newNotifications[context]?.total && context !== 'Resources') {
      const resp = await updateNotificationsBadgesBySpaceId(space.id, {
        totalBadgesSeen: newNotifications[context].total,
        context: context,
      });
      if (resp) {
        if (newNotify[context]?.total) {
          newNotify[context].total = 0;
          setNotifications(newNotify);
        }
      }
    }

    // check and fetch public channel again if the first attempt failed
    if (!chatPublicChannel) {
      clearCache([CacheKeys.fetchChannelRequest, space.id]);
    }

    let firstJoinedSpaceUsers = newNotifications?.Participants?.details?.firstJoinedSpaceUsers;

    if (context === notificationType.Participants && firstJoinedSpaceUsers?.length) {
      firstJoinedSpaceUsers = firstJoinedSpaceUsers.map((id) => id.toString());
      const data = {
        readItems: {
          firstJoinedSpaceUsers,
        },
        context: 'Participants',
      };
      const result = updateNotificationRead(space.id, data);
      if (result) {
        newNotify.Participants.details.firstJoinedSpaceUsers = [];
        setNotifications(newNotify);
      }
    }
  }

  return (
    <FloatingMenu
      features={features}
      className={props.className || ''}
      position={props.position || 'bottom'}
      isMobile={props.isMobile}
      sidebar={sidebar}
      onClick={onClickIcons}
      newNotifications={newNotifications}
    />
  );
};

SpaceFooter.propTypes = {
  className: PropTypes.string,
  isDemoView: PropTypes.bool,
  position: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default SpaceFooter;

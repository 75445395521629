import RuntimeApi from '../../commons/RuntimeApi';
import { getROIRuntimeApi } from '../../commons/utils';

export async function getRoiReports(data) {
  return RuntimeApi.get(`${getROIRuntimeApi()}/roi-calculation/reports`, data);
}

export async function getRoiReportDocument(id) {
  const response = await RuntimeApi.fetch(
    `${getROIRuntimeApi()}/roi-calculation/reports/${id}/document`
  );
  if (!response) {
    return null;
  }
  const blob = await response.blob();
  console.log('response: ', blob);

  return {
    url: URL.createObjectURL(blob),
  };
}

export async function downloadRoiFile(resource) {
  const { documentId, name } = resource;
  if (documentId) {
    const resp = await getRoiReportDocument(documentId);
    if (resp?.url) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = name;
      link.href = resp.url;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export async function deleteRoiReport(id) {
  return RuntimeApi.delete(`${getROIRuntimeApi()}/roi-calculation/reports/${id}`);
}

export async function bulkDeleteRoiReports(ids) {
  return RuntimeApi.delete(`${getROIRuntimeApi()}/roi-calculation/reports`, { ids });
}

export async function getRoiFolderInfo() {
  return RuntimeApi.get(`${getROIRuntimeApi()}/roi-calculation/info`);
}

import React, { useEffect } from 'react';

import { getUrlParameter, removeURLParams } from '../commons/utils';
import spaceUser from './spaceUser';
import { useSpaceContext } from './SpaceContext';
import { findAndScrollToBlock } from '../cms/cmsUtils';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType, SidebarType } from '../app/appConstants';

function SpaceShareLinks() {
  const sharingFolderId = getUrlParameter('drId', null, true);
  const sharingCmsBlockId = getUrlParameter('cbId');
  const pageId = getUrlParameter('pageId');

  const {
    isPublicSpace,
    isMobile,
    isMobilePortrait,
    setSidebar,
    pagesBlock,
    onChangeNavigation,
    setBlockId,
    currentPage,
  } = useSpaceContext();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledCMSPages] = checkEnabledFeatures([FeatureFlagsType.CMS_PAGES]);

  const isLoggedIn = spaceUser.hasLoggedIn() || (!spaceUser.hasLoggedIn() && isPublicSpace);

  React.useEffect(() => {
    if (sharingFolderId && isLoggedIn) {
      setSidebar(SidebarType.Material);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, sharingFolderId]);

  useEffect(() => {
    if (!isLoggedIn || (!pageId && !sharingCmsBlockId)) {
      return;
    }
    const dataIds = {
      cmsPagesId: pageId,
      cmsBlockId: sharingCmsBlockId,
    };

    findAndScrollToBlock(
      dataIds,
      isEnabledCMSPages ? pagesBlock : null,
      currentPage,
      onChangeNavigation,
      setBlockId,
      false,
      isMobile || isMobilePortrait
    ).then(() => {
      removeURLParams(['pageId', 'cbId']);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, sharingCmsBlockId, isLoggedIn]);

  return null;
}

export default SpaceShareLinks;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, createRef, useRef, useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { IconButton, Fade } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import i18n from '../../../i18n';
import VideoPlayer from '../../../components/VideoPlayer';
import './VideoPreview.scss';
import {
  getVideoResourceData,
  getExtensionFromUrl,
  getMediaKindConfig,
  getPlayer,
  getVideoThumbnail,
  parseYoutubePlaylistLink,
} from '../../../commons/ResourceUtils';
import { getDisplayText, sendNotification } from '../../../commons/utils';
import { checkAvailableFonts, checkAdditionalStyles } from '../../commons/FontSelect/utils';
import { createResourceInteraction } from '../../../commons/CommonServices';
import {
  ResourceInteractionType,
  ResourceTypeNames,
  SpaceInteractionType,
  VideoResourceViewedBatchTime,
} from '../../../app/appConstants';
import { CMS_COMPONENT_STYLE } from '../../cmsConstants';
import {
  getHeadingFontSize,
  getTextLineHeight,
  getTextPositionCssClasses,
  handleLinkNavigation,
  isSharingPageOrBlockInSpace,
} from '../../cmsUtils';
import { isIntegrationResourceDeleted } from '../../../integrations/integrationResourceUtils';
import ImagePlaceholder from '../../../components/ImagePlaceholder';
import VideoDefaultImage from '../../../images/cms/video-preview.jpeg';
import PlaceholderImage from '../../../images/image-placeholder.png';
import ButtonEnhanced from '../../../components/ButtonEnhanced';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import { parseDateTimeStringFromServer } from '../../../commons/DateTimeUtils';
import DescriptionPreview from '../DescriptionPreview';
import eventBus, { EVENT_BUS } from '../../../commons/EventBus';
import TiffViewer from '../../../components/TiffViewer/TiffViewer';
import { useAppContext } from '../../../components/AppProvider';
import useMyFontListQuery from '../../commons/FontSelect/MyFontListQuery';

// const defaultVideo = 'https://youtu.be/4Qb1sNaBDA8';
const defaultBackgroundImage = VideoDefaultImage;

function VideoPreview(props) {
  const { isTablet } = useAppContext();

  const { isEdit, resources, shouldDisplay, imagePosition } = props;

  const { myFonts } = useMyFontListQuery(true, true);

  const [playing, setPlaying] = useState(false);
  const [video, setVideo] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  // to store video info temporary, set it video when click play video
  const [videoInfo, setVideoInfo] = useState(null);
  const [isIntersecting, setIntersecting] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState(null);

  const playerRef = createRef(null); // createRef will always create a new ref.
  const playerStateRef = useRef(null);
  const isPlayedVideo = useRef(false); // to be used when the video play at the 1st time. send interaction only once.
  const componentRef = createRef(null);
  const isIntersected = useRef(false);
  const observedElements = useRef(0);

  const { scrollToBlockId } = useSpaceContext();

  const isOverlay = !props.layout || props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY;

  let autoplay = props.autoplay !== undefined && props.autoplay !== null ? props.autoplay : false; // spc-3589
  if (!props.isSpaceUserLoggedIn) {
    autoplay = false; // spc-4297
  }

  const [isVideoAutoPlayPlayFirstTime, setIsVideoAutoPlayPlayFirstTime] = useState(true);

  const resourceIds = [];
  if (props.videoUrl > 0) resourceIds.push(props.videoUrl);
  if (props.backgroundImageUrlCropped > 0) resourceIds.push(props.backgroundImageUrlCropped);
  if (props.backgroundImageUrl > 0) resourceIds.push(props.backgroundImageUrl);

  useEffect(() => {
    const onTranscodingFinished = (detail) => {
      console.log('### onTranscodingFinished', detail, video, videoInfo);
      const { id, src, token, thumbnail } = detail;
      if (videoInfo?.resourceId === id) {
        const newVideoInfo = { ...videoInfo, url: src, token, thumbnail };
        setVideoInfo(newVideoInfo);
        if (video) {
          setVideo(newVideoInfo);
        }
        if (!thumbnail && isEdit) {
          const msg = i18n.t('Cannot display the video thumbnail. Try uploading it instead.');
          sendNotification(msg, { duration: 5000, type: 'info' });
        }
      }
    };
    const unsubscribe = eventBus.subscribe(EVENT_BUS.TranscodingFinished, onTranscodingFinished);
    return () => {
      unsubscribe();
    };
  }, [video, videoInfo, isEdit]);

  useEffect(() => {
    // console.log('### CardPreview useEffect resources:', resources);
    if (resources) {
      const foundVideo = resources[props.videoUrl];
      // console.log('### CardPreview useEffect foundVideo', foundVideo, props.videoUrl);
      setVideoInfo(foundVideo);
      if (!foundVideo) {
        setVideo(null);
      }

      const croppedResource = resources[props.backgroundImageUrlCropped];
      const originResource = resources[props.backgroundImageUrl];

      // console.log('### CardPreview useEffect originResource', originResource);
      // console.log('### CardPreview useEffect croppedResource', croppedResource);
      if (croppedResource) {
        let displayResource = croppedResource;
        const lastModified = parseDateTimeStringFromServer(originResource?.lastModified)?.local();
        const created = parseDateTimeStringFromServer(croppedResource.created)?.local();
        if (lastModified > created) {
          displayResource = originResource;
        }
        setBackgroundImage(displayResource);
      } else {
        setBackgroundImage(originResource);
      }
    } else {
      setBackgroundImage(null);
      setVideoInfo(null);
    }
  }, [resources, props.videoUrl, props.backgroundImageUrl, props.backgroundImageUrlCropped]);

  const onIntersectDebounced = useDebouncedCallback((value) => setIntersecting(value), 500);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      onIntersectDebounced(entry.isIntersecting);
    });
    if (componentRef.current && !observedElements.current) {
      console.log('### observe');
      observer.observe(componentRef.current);
      observedElements.current += 1;
    }
    return () => {
      if (componentRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(componentRef.current);
        observedElements.current -= 1;
      }
    };
  }, [componentRef, onIntersectDebounced]);

  useEffect(() => {
    if (!props.videoUrl) {
      // console.log('### VideoPreview props.videoUrl is null', props.videoUrl);
      // in case when upload a video, then remove.
      if (isPlayedVideo.current) {
        isPlayedVideo.current = false;
      }
    }
  }, [props.videoUrl]);

  const playVideo = React.useCallback(() => {
    console.log('### playVideo');
    setVideo(videoInfo);
    setPlaying(true);
    if (!isPlayedVideo.current) {
      isPlayedVideo.current = true;
      // send interaction
      if (props.videoUrlResourceId && props.videoUrlMaterialId && !props.isPortal) {
        if (!isVideoAutoPlayPlayFirstTime && !autoplay) {
          createResourceInteraction(props.videoUrlResourceId, {
            materialId: props.videoUrlMaterialId,
            interactionType: ResourceInteractionType.Click,
            data: JSON.stringify({
              isCms: true,
              autoplay,
            }),
          });
        }
        if (autoplay && isVideoAutoPlayPlayFirstTime) {
          setIsVideoAutoPlayPlayFirstTime(false);
        }
      }
    }
    // dont have playerRef here, coz it was faded out.
  }, [
    autoplay,
    isVideoAutoPlayPlayFirstTime,
    props.isPortal,
    props.videoUrlMaterialId,
    props.videoUrlResourceId,
    videoInfo,
  ]);

  const pauseVideo = React.useCallback(() => {
    if (playerRef.current) {
      const currentState = playerRef.current.getCurrentState();
      // console.log('### pause video at the current time', currentState?.currentTime);
      playerStateRef.current = currentState;
    }
    setVideo(null);
    setPlaying(false);
  }, [playerRef]);

  useEffect(() => {
    // console.log('### effect 1', autoplay, videoInfo, props.isPortal);
    if (!props.isPortal && autoplay && videoInfo) {
      // console.log('### effect 1a', isIntersecting, isIntersected.current);
      if (isIntersecting) {
        if (!isIntersected.current) {
          // console.log('### effect 1a play');
          isIntersected.current = true;
          playVideo();
        }
      } else {
        // console.log('### effect 1b pause');
        pauseVideo();
        isIntersected.current = false;
      }
    }
  }, [autoplay, videoInfo, isIntersecting, playVideo, pauseVideo, props.isPortal]);

  useEffect(() => {
    if (!video || !playing) {
      return;
    }
    // console.log('### effect 2', playerRef.current, video, playing);
    setTimeout(() => {
      if (!playerRef.current) return;
      const currentState = playerRef.current.getCurrentState();
      if (playerStateRef.current && !currentState?.currentTime) {
        const currentTime = playerStateRef.current.currentTime;
        // console.log('### play video at the current time', currentTime);
        playerRef.current.seekTo(currentTime || 0, 'seconds');
      }
      playerRef.current.handleOnPlay();
    }, 300);
  }, [playerRef, playing, autoplay, video]);

  useEffect(() => {
    async function getThumbnail() {
      const thumbnail = await getVideoThumbnail(videoInfo);
      // console.log('### thumbnail', thumbnail);
      setVideoThumbnail(thumbnail);
      if (videoInfo && videoInfo.isExternal && !thumbnail && isEdit) {
        const msg = i18n.t('Cannot display the video thumbnail. Try uploading it instead.');
        sendNotification(msg, { duration: 5000, type: 'info' });
      }
    }
    getThumbnail();
  }, [videoInfo, isEdit]);

  function handleResourceInteraction(linkResourceId, linkMaterialId) {
    // console.log('### 2310 handleResourceInteraction:', linkResourceId, linkMaterialId);
    if (linkResourceId && linkMaterialId && !props.isPortal && !autoplay) {
      createResourceInteraction(linkResourceId, {
        materialId: linkMaterialId,
        interactionType: ResourceInteractionType.Click,
        data: JSON.stringify({ isCms: true, autoplay }),
      });
    }
  }

  function handleNavigation(link) {
    const paramIds = isSharingPageOrBlockInSpace(link, props.spaceId, props.urlSlug);
    if (paramIds) {
      scrollToBlockId(paramIds, props.isEnabledCMSPages);
      return;
    }
    // console.log('### 2310 handleNavigation:', link);
    handleLinkNavigation(link, props.spaceId, props.urlSlug, props.isPortal);
  }

  function handleLinkClick(fieldName) {
    const linkResourceId = props[`${fieldName}ResourceId`];
    const linkMaterialId = props[`${fieldName}MaterialId`];
    handleResourceInteraction(linkResourceId, linkMaterialId);

    const link = props[`${fieldName}`];
    handleNavigation(link);
  }

  function handleOnPlay(event) {
    event.stopPropagation();
    playVideo();
  }

  const trackInteractionDebounced = useDebouncedCallback(() => {
    if (autoplay) return;
    createResourceInteraction(props.videoUrlResourceId, {
      materialId: props.videoUrlMaterialId,
      interactionType: ResourceInteractionType.Click,
    });
  }, 400);

  function handleOnChange(value) {
    if (value !== playing) {
      setPlaying(value);
    }
  }

  function handleOnPlayed() {
    trackInteractionDebounced();
  }

  function handleSentViewedEventToInsight(totalTime) {
    if (totalTime > 0) {
      eventBus.publish(EVENT_BUS.SpaceAnalyticsEvents, SpaceInteractionType.VIEW_RESOURCE, {
        resourceId: props.videoUrlResourceId,
        materialId: props.videoUrlMaterialId,
        isCms: true,
        autoplay,
        duration: Math.round(totalTime),
        resourceType: ResourceTypeNames.video,
      });
    }
  }

  function handleOnUnmount(play, currentTime, totalTime) {
    if (totalTime !== 0) {
      createResourceInteraction(props.videoUrlResourceId, {
        materialId: props.videoUrlMaterialId,
        interactionType: ResourceInteractionType.Viewed,
        totalSeconds: totalTime,
        data: JSON.stringify({
          isCms: true,
          autoplay,
          resourceType: ResourceTypeNames.video,
        }),
      });
    }
    setPlaying(play);
  }

  function handleOnClick(event) {
    event.stopPropagation();
    if (props.onClick) {
      props.onClick(props.index);
    }
  }

  function renderVideoPlayer() {
    if (!props.videoUrl || !video?.url) return null;
    if (isIntegrationResourceDeleted(video.url)) {
      return (
        <div className="cms-video-preview-video">
          <ImagePlaceholder />
        </div>
      );
    }

    const videoResourceData = getVideoResourceData(video);
    let videoUrl = videoResourceData?.videoURL;

    const { type } = getPlayer(video.url);
    let externalPlayerConfig = {};
    if (type === 'youtube') {
      // https://www.youtube.com/watch?v=9zEl-FQLI4A&list=RDGMEMQ1dJ7wXfLlqCjwV0xfSNbA&start_radio=1
      // https://www.youtube.com/watch?v=cVOGpCGOEKk&list=RDEMYr8iwtqFtZ20YgA9pmLWmQ&start_radio=1
      const parsingResult = parseYoutubePlaylistLink(videoUrl);
      videoUrl = parsingResult.src;
      externalPlayerConfig = parsingResult.config;
    } else if (type === 'mediakind') {
      const { src, config } = getMediaKindConfig(
        videoUrl,
        video.laUrl,
        video.token,
        isMobile,
        isIOS
      );
      videoUrl = src;
      externalPlayerConfig = config;
    }
    console.log('### renderVideo', video, externalPlayerConfig, type);

    const fadeIn = playing || isPlayedVideo.current;
    // console.log('### renderVideo fadeIn', fadeIn, playing);
    return (
      <Fade in={fadeIn}>
        <div className="cms-video-preview-player">
          <VideoPlayer
            ref={playerRef}
            url={videoUrl}
            materialId={props.videoUrlMaterialId}
            autoPlay={autoplay}
            muted={autoplay} // always muted when autoplay
            controls
            onChange={handleOnChange}
            onPlayed={handleOnPlayed}
            playsinline
            externalPlayerConfig={externalPlayerConfig}
            disableAutoplayHandler
            onUnmount={handleOnUnmount}
            sentViewedEventToInsight={handleSentViewedEventToInsight}
            sentToInsightEventBatchTimeInSeconds={VideoResourceViewedBatchTime}
          />
        </div>
      </Fade>
    );
  }

  const getFont = (fontFamily) => {
    return checkAvailableFonts(fontFamily, myFonts);
  };

  function renderInfo() {
    const textSize = props.textSize || 'large';
    const sizeCssClass = `column-${props.columns}`;

    let justifyContentClass;
    let textPositionClass = '';
    let textAlignClass = props.textAlign || 'center';
    if (props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE) {
      // beside
      justifyContentClass = 'justifyContentCenter';
    } else if (props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER) {
      // under
    } else {
      // overlay
      const cssClasses = getTextPositionCssClasses(props.textPosition, props.isMobile);
      textPositionClass = cssClasses.positionClass;
      textAlignClass = cssClasses.textAlignClass;
    }

    let headingFontSize = props.headingFontSize;
    const headingFontSizeValue = props.headingFontSize?.replace('px', '');

    headingFontSize = getHeadingFontSize(headingFontSizeValue, props.isMobile);

    const headingStyle = {
      color: props.headingColor,
      fontFamily: getFont(props.headingFontFamily),
      fontSize: headingFontSize,
      lineHeight: 1.25,
      ...checkAdditionalStyles(props.headingFontFamily),
    };
    const descriptionStyle = {
      color: props.descriptionColor,
      fontFamily: getFont(props.descriptionFontFamily),
      fontSize: props.descriptionFontSize,
      lineHeight: getTextLineHeight(props.descriptionFontSize),
      ...checkAdditionalStyles(props.descriptionFontFamily),
    };
    const buttonLabelStyle = {
      color: props.buttonLabelColor,
      fontFamily: getFont(props.buttonLabelFontFamily),
      fontSize: props.buttonLabelFontSize,
      backgroundColor: props.buttonBackgroundColor,
      ...checkAdditionalStyles(props.buttonLabelFontFamily),
    };
    const secondaryButtonLabelStyle = {
      color: props.secondaryButtonLabelColor,
      fontFamily: getFont(props.secondaryButtonLabelFontFamily),
      fontSize: props.secondaryButtonLabelFontSize,
      backgroundColor: props.secondaryButtonBackgroundColor,
      ...checkAdditionalStyles(props.secondaryButtonLabelFontFamily),
    };

    const fadeIn = (!playing && !isPlayedVideo.current && isOverlay) || !isOverlay;
    // console.log('### renderInfo fadeIn', fadeIn, playing);
    const { heading, description, buttonLabel, secondaryButtonLabel } = props;

    const { htmlAttributes: headingAttributes } = getDisplayText(heading);
    const { displayText: displayButtonLabel } = getDisplayText(buttonLabel);
    const { displayText: secondaryDisplayButtonLabel } = getDisplayText(secondaryButtonLabel);

    let positionClass;
    let widthClass;
    if (isOverlay) {
      // video and text overlay
      positionClass = 'positionAbsolute';
      widthClass = 'maxWidth300px';
      if (props.columns === 1 && !props.isMobile) {
        widthClass = 'maxWidth700px';
      }
    }

    return (
      <Fade in={fadeIn}>
        <div className={`cms-video-preview-info-wrapper`}>
          <div
            className={`cms-video-preview-info ${textPositionClass} ${sizeCssClass} ${positionClass} ${widthClass} ${justifyContentClass}`}
          >
            <div className={`cms-video-preview-content ${textAlignClass}`}>
              {heading && (
                <span
                  className={`cms-video-preview-title ${textSize}`}
                  style={headingStyle}
                  {...headingAttributes}
                />
              )}
              {description && (
                <DescriptionPreview
                  isPortal={props.isPortal}
                  textAlignClass={textAlignClass}
                  descriptionStyle={descriptionStyle}
                  description={description}
                  descriptionLinks={props.descriptionLinks}
                  handleNavigation={handleNavigation}
                  handleResourceInteraction={handleResourceInteraction}
                  className="cms-video-preview-description"
                />
              )}
              {!isOverlay && (
                <div className="cms-video-preview-buttons">
                  {buttonLabel && (
                    <ButtonEnhanced
                      className="btn-cms"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleLinkClick('buttonLink');
                      }}
                      style={buttonLabelStyle}
                    >
                      <span dangerouslySetInnerHTML={{ __html: displayButtonLabel }} />
                    </ButtonEnhanced>
                  )}
                  {secondaryButtonLabel && (
                    <ButtonEnhanced
                      className="btn-cms"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        handleLinkClick('secondaryButtonLink');
                      }}
                      style={secondaryButtonLabelStyle}
                    >
                      <span dangerouslySetInnerHTML={{ __html: secondaryDisplayButtonLabel }} />
                    </ButtonEnhanced>
                  )}
                </div>
              )}
            </div>
          </div>
          {isOverlay && props.videoUrl > 0 && (
            <IconButton className="control-button" onClick={handleOnPlay}>
              {!playing ? <PlayArrowIcon /> : <PauseIcon />}
            </IconButton>
          )}
        </div>
      </Fade>
    );
  }

  function renderThumbnail() {
    let mExtension = getExtensionFromUrl(videoThumbnail) || 'jpg'; // jpg is extension of defaultBackgroundImage
    let bgImage = videoThumbnail || defaultBackgroundImage;
    if (backgroundImage?.url) {
      bgImage = backgroundImage.url;
      mExtension = getExtensionFromUrl(backgroundImage.url, backgroundImage.extension);
    }
    const isResourceDeleted = isIntegrationResourceDeleted(backgroundImage?.url);
    if (isResourceDeleted) {
      bgImage = PlaceholderImage;
      mExtension = 'png'; // png is extension of PlaceholderImage
    }
    const isTiff = mExtension === 'tiff' || mExtension === 'tif';
    const backgroundColor = `rgba(0, 0, 0, ${props.opacity})`;

    const fadeIn = !playing && !isPlayedVideo.current;
    // console.log('### renderInfo fadeIn', fadeIn, playing);
    let ratioClass = 'paddingB-ratio169';
    if (isOverlay) {
      if (props.columns === 1) {
        if (props.isMobile) {
          ratioClass = 'paddingB-ratio916';
        }
      } else if (props.columns === 2) {
        if (isTablet) {
          ratioClass = 'paddingB-ratio12';
        }
      } else if (props.columns === 3) {
        if (isTablet) {
          ratioClass = 'paddingB-ratio11';
        }
      }
    }

    const displayBorder =
      !props.isPreviewMode &&
      isEdit &&
      props.columns === 1 &&
      props.size === CMS_COMPONENT_STYLE.SIZE.FULLWIDTH;
    const boxShadowBorderCssClass = displayBorder && isOverlay ? 'box-shadow-border' : '';
    const boxShadowBorderCssClass2 =
      displayBorder && props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER
        ? 'box-shadow-border-2'
        : '';
    const styles = {
      objectPosition: isMobile && imagePosition ? imagePosition : 'center center',
    };
    return (
      <Fade in={fadeIn}>
        <div className={`cms-video-preview-thumbnail-wrapper`}>
          <div className={`cms-video-preview-thumbnail ${ratioClass} ${boxShadowBorderCssClass2}`}>
            {isOverlay && (
              <div
                className={`cms-video-preview-thumbnail-overlay ${boxShadowBorderCssClass}`}
                style={{ backgroundColor }}
              />
            )}
            {shouldDisplay && (
              <>
                {!isTiff && <img src={bgImage} alt={props.heading} style={styles} />}
                {isTiff && (
                  <TiffViewer
                    src={bgImage}
                    alt={props.heading}
                    className="cms-video-image"
                    renderAsImgTag
                    style={styles}
                    resourceId={backgroundImage?.resourceId}
                  />
                )}
              </>
            )}
            {!isOverlay && props.videoUrl > 0 && (
              <IconButton className="control-button" onClick={handleOnPlay}>
                {!playing ? <PlayArrowIcon /> : <PauseIcon />}
              </IconButton>
            )}
          </div>
        </div>
      </Fade>
    );
  }

  function renderVideo() {
    let maxWidthClass;
    if (props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE) {
      // hero
      maxWidthClass = props.isMobile ? '' : 'maxWidth50p';
    }

    return (
      <div className={`cms-video-preview-video ${maxWidthClass}`}>
        {renderVideoPlayer()}
        {renderThumbnail()}
      </div>
    );
  }

  function renderVideoPreview() {
    let active;
    if (props.isColumnActive) {
      active = `active`;
    } else if (props.isRowActive) {
      active = 'allowHover';
    }

    const columnCssClass = `v-column-${props.columns}`;

    // console.log('### videoPreview render', props.textPosition, props.layout);

    const cssDirection = props.isMobile ? 'column-direction' : 'row-direction';
    const gapClass = 'gap32';

    if (props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.UNDER) {
      // video and text under
      if (props.textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.BOTTOM) {
        return (
          <div
            className={`cms-video-preview column-direction ${gapClass} ${active} ${columnCssClass}`}
            onClick={handleOnClick}
            ref={componentRef}
          >
            {renderVideo()}
            {renderInfo()}
          </div>
        );
      }
    } else if (props.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.BESIDE) {
      if (props.textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.LEFT) {
        // text beside on left
        return (
          <div
            className={`cms-video-preview ${cssDirection} ${gapClass} ${active} ${columnCssClass}`}
            onClick={handleOnClick}
            ref={componentRef}
          >
            {renderInfo()}
            {renderVideo()}
          </div>
        );
      } else if (props.textPosition === CMS_COMPONENT_STYLE.TEXT_POSITION.RIGHT) {
        // text beside on right
        return (
          <div
            className={`cms-video-preview ${cssDirection} ${gapClass} ${active} ${columnCssClass}`}
            onClick={handleOnClick}
            ref={componentRef}
          >
            {renderVideo()}
            {renderInfo()}
          </div>
        );
      }
    }

    // default, video and text overlay
    return (
      <div
        className={`cms-video-preview positionRelative ${active} ${columnCssClass}`}
        onClick={handleOnClick}
        ref={componentRef}
      >
        {renderVideo()}
        {renderInfo()}
      </div>
    );
  }

  return renderVideoPreview();
}

VideoPreview.propTypes = {
  index: PropTypes.number,
  heading: PropTypes.string,
  description: PropTypes.string,
  descriptionLinks: PropTypes.arrayOf(Object),
  textSize: PropTypes.string,
  backgroundImageUrl: PropTypes.number,
  opacity: PropTypes.number,
  videoUrl: PropTypes.number,
  columns: PropTypes.number,
  size: PropTypes.string,
  isMobile: PropTypes.bool,
  isPortal: PropTypes.bool,
  headingColor: PropTypes.string,
  descriptionColor: PropTypes.string,
  headingFontFamily: PropTypes.string,
  descriptionFontFamily: PropTypes.string,
  headingFontSize: PropTypes.string,
  descriptionFontSize: PropTypes.string,
  videoUrlResourceId: PropTypes.number,
  videoUrlMaterialId: PropTypes.number,
  textPosition: PropTypes.string,
  backgroundImageUrlCropped: PropTypes.number,
  autoplay: PropTypes.bool,
  onClick: PropTypes.func,
  isRowActive: PropTypes.bool,
  isColumnActive: PropTypes.bool,
  isSpaceUserLoggedIn: PropTypes.bool,
  spaceId: PropTypes.string,
  layout: PropTypes.string,
  urlSlug: PropTypes.string,
  textAlign: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonLabelColor: PropTypes.string,
  buttonLabelFontFamily: PropTypes.string,
  buttonLabelFontSize: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonLink: PropTypes.string,
  secondaryButtonLabelColor: PropTypes.string,
  secondaryButtonLabelFontFamily: PropTypes.string,
  secondaryButtonLabelFontSize: PropTypes.string,
  secondaryButtonBackgroundColor: PropTypes.string,
  isEdit: PropTypes.bool,
  resources: PropTypes.instanceOf(Object),
  shouldDisplay: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  isEnabledCMSPages: PropTypes.bool,
  imagePosition: PropTypes.string,
};

VideoPreview.defaultProps = {
  opacity: 0.3,
  isMobile: false,
  isPortal: false,
  isSpaceUserLoggedIn: false,
  layout: CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY,
  isPreviewMode: false,
};

// export default VideoPreview;
export default VideoPreview;

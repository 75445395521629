import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useUnmountEffect from '../../commons/useUnmountEffect';
import { useSpaceContext } from '../SpaceContext';
import { removeURLParams } from '../../commons/utils';
import { getChannelById } from './CommentService';

const ChatSidebarHandler = (props) => {
  const { mainChannelId, mainChannel, setChatTab, setSelectedChannel, setActiveChannel, spaceId } =
    props;
  const { selectedTargetChannel, setSelectedTargetChannel } = useSpaceContext();
  const [curSelectedChannel, setCurSelectedChannel] = useState(selectedTargetChannel);

  useEffect(() => {
    if (selectedTargetChannel) {
      setCurSelectedChannel((oldData) => {
        if (oldData !== selectedTargetChannel) {
          return selectedTargetChannel;
        }
        return oldData;
      });
      if (selectedTargetChannel === mainChannelId) {
        setChatTab(0);
        setSelectedChannel(null);
        setActiveChannel(mainChannel);
      } else {
        setChatTab(2);
      }
      removeURLParams(['channelId']);
    }

    // Only check for channelId changed in url
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTargetChannel]);

  useEffect(() => {
    const fetchData = async (id) => {
      const res = await getChannelById(spaceId, id);
      if (res) {
        if (res.participants.length > 0) {
          setSelectedChannel(res);
          setChatTab(2);
        }
      }
    };
    if (curSelectedChannel) {
      fetchData(curSelectedChannel).then().catch(console.error);
    }
  }, [spaceId, curSelectedChannel, setChatTab, setSelectedChannel]);

  useUnmountEffect(() => {
    setSelectedTargetChannel(null);
  });
  return null;
};

ChatSidebarHandler.propTypes = {
  mainChannelId: PropTypes.string,
  mainChannel: PropTypes.instanceOf(Object),
  setChatTab: PropTypes.func,
  setSelectedChannel: PropTypes.func,
  setActiveChannel: PropTypes.func,
  spaceId: PropTypes.string,
};

export default ChatSidebarHandler;

/* global System */
import { useEffect, useRef } from 'react';
import useUnmountEffect from './useUnmountEffect';

const useWidgetImport = (packageId, containerRef, props, forwardedRef = null) => {
  const moduleRef = useRef(null);

  useEffect(() => {
    System.import(packageId)
      .then((m) => {
        moduleRef.current = m;
        // console.log('###useWidgetImport mount', packageId);
        moduleRef.current?.mount(containerRef.current, props, forwardedRef);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [containerRef, forwardedRef, props, packageId]);

  useUnmountEffect(() => {
    // console.log('###useWidgetImport unmount', packageId);
    moduleRef.current?.unmount(containerRef.current);
    moduleRef.current = null;
  });
};

export default useWidgetImport;

import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import i18n from '../i18n';
import { formatDateTime } from '../commons/DateTimeUtils';
import Logo from './../images/spce-logo.png';
import useUserInfoByIdQuery from '../commons/useUserInfoByIdQuery';

const VaamPlayer = React.lazy(() => import('./VaamPlayer'));

function VideoMessageViewer(props) {
  const {
    spaceId,
    createdByUserInfoId,
    vaamId,
    title,
    description,
    createdDate,
    logoUrl,
    handleViewMessageViewedTime,
    handleOnStart,
  } = props;

  const { createdByUser } = useUserInfoByIdQuery(spaceId, createdByUserInfoId);
  return (
    <>
      <div className="header">
        {logoUrl ? (
          <img src={logoUrl} alt="Logo" className="logo" />
        ) : (
          <img src={Logo} alt="SPCE logo" className="logo" />
        )}
      </div>
      <div className="body">
        <Box className="details">
          {title && <div className="title">{i18n.t(title)}</div>}
          <div className="info">
            {createdByUser && (
              <>
                <span className="name">{createdByUser}</span>
                <div className="dot">
                  <span></span>
                </div>
              </>
            )}

            <span className="date">{formatDateTime(createdDate, 'DD MMM YYYY')}</span>
          </div>
          {description && <div className="description">{i18n.t(description)}</div>}
        </Box>
        <Box className="player">
          <Suspense fallback="">
            <VaamPlayer
              vaamId={vaamId}
              sendViewTimeToAnalyticsEvent={handleViewMessageViewedTime}
              handleOnStart={handleOnStart}
            />
          </Suspense>
        </Box>
      </div>
    </>
  );
}

VideoMessageViewer.propTypes = {
  vaamId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  createdByUserInfoId: PropTypes.number,
  createdDate: PropTypes.string,
  logoUrl: PropTypes.string,
  handleViewMessageViewedTime: PropTypes.func,
  handleOnStart: PropTypes.func,
  spaceId: PropTypes.string,
};

export default VideoMessageViewer;

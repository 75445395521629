import React, { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';

export const BlockContext = React.createContext();

const getCourseBlockContext = memoizeOne(
  (selectedBlock, setSelectedBlock, selectedCourse, lmsConnection, blockId) => ({
    selectedBlock,
    setSelectedBlock,
    selectedCourse,
    lmsConnection,
    blockId,
  })
);

export const useCourseBlocksContext = () => useContext(BlockContext);

function CourseBlockContextProvider(props) {
  const { children } = props;

  const [selectedBlock, setSelectedBlock] = React.useState(null);
  const [selectedCourse, setSelectedCourse] = React.useState(null);
  const [lmsConnection, setLmsConnection] = React.useState(null);
  const [blockId, setBlockId] = React.useState(props.blockId);

  const context = getCourseBlockContext(
    selectedBlock,
    setSelectedBlock,
    selectedCourse,
    lmsConnection,
    blockId
  );

  const handleClose = () => {
    setSelectedBlock(null);
  };

  context.openBlock = useCallback((block) => {
    setSelectedBlock(block);
    setBlockId(block.id);
  }, []);

  context.closeBlock = handleClose;
  context.openCourse = useCallback((course) => {
    setSelectedCourse(course);
  }, []);
  context.closeCourse = useCallback(() => {
    setSelectedCourse(null);
  }, []);

  context.setLmsConnection = useCallback((connection) => {
    setLmsConnection(connection);
  }, []);

  return <BlockContext.Provider value={context}>{children}</BlockContext.Provider>;
}

CourseBlockContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  blockId: PropTypes.string,
};

export default CourseBlockContextProvider;

import React, { lazy, useState, useEffect, useRef, Suspense } from 'react';
import PropTypes from 'prop-types';
import currentOrganization from '../commons/CurrentOrganization';
import './SpacesOverview.scss';
import { SubscriptionType } from '../commons/Subscription/subscriptionConstants';
import { useAppContext } from '../components/AppProvider';
import OverviewTabs from './OverviewTabs';
import usePartnerRedirectToSpace from './usePartnerRedirectToSpace';
import Loading from '../components/Loading';
import PromoFeatureHandler from '../promo/promoFeatureHandler';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlagsContext } from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../app/appConstants';
import ErrorMessage from '../components/ErrorMessage';
import Dashboard from './Dashboard';

const SpaceListContainer = lazy(() => import('./SpaceListContainer'));
const TemplateListContainer = lazy(() => import('./TemplateListContainer'));

const CreateSpaceDialog = lazy(() => import('../createSpace/CreateSpaceDialog'));
const CreateSpaceOrTemplateDialog = lazy(() => import('./CreateSpaceOrTemplateDialog'));

function SpacesOverview({ tab: selectedTab }) {
  const organization = currentOrganization.getData();
  const isEnterprise =
    organization?.subscriptionTypeId === SubscriptionType.enterprise ||
    organization?.subscriptionTypeId === SubscriptionType.super;

  const { isMobile } = useAppContext();
  const navigate = useNavigate();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isDashboardEnabled] = checkEnabledFeatures([FeatureFlagsType.DASHBOARD]);

  const { isCheckingPartnerRedirect } = usePartnerRedirectToSpace();

  const [showSpaceTemplateDialog, setShowSpaceTemplateDialog] = useState(false);

  // const [selectedTab, setSelectedTab] = useLocalStorage(`selectedTab-${userInfo.id}`, 'Spaces');

  const createSpaceRef = useRef();

  useEffect(() => {
    sessionStorage.removeItem('silentLoginAttemps');
    window.history.replaceState({}, document.title);
  }, []);

  function handleDialogClose() {
    setShowSpaceTemplateDialog(false);
  }

  function handleChangeTab(value) {
    // setSelectedTab(value);
    if (value === 'Spaces') {
      navigate('/');
    } else if (value === 'Templates') {
      navigate('/templates');
    } else if (value === 'Dashboard') {
      navigate('/dashboard');
    }
  }

  function handleOpenCreateSpacePopup(space, action) {
    createSpaceRef?.current?.onOpen(space, action);
  }

  if (isCheckingPartnerRedirect) {
    return <Loading />;
  }

  function renderContent() {
    switch (selectedTab) {
      case 'Spaces':
        return (
          <SpaceListContainer
            onOpenEditSpace={handleOpenCreateSpacePopup}
            setShowSpaceTemplateDialog={setShowSpaceTemplateDialog}
            isEnterprise={isEnterprise}
          />
        );
      case 'Templates':
        return (
          <TemplateListContainer
            onOpenEditSpace={handleOpenCreateSpacePopup}
            setShowSpaceTemplateDialog={setShowSpaceTemplateDialog}
          />
        );
      case 'Dashboard':
        if (!isDashboardEnabled) {
          return <ErrorMessage message="Dashboard is not enabled for this organization" />;
        }
        return <Dashboard />;
      default:
        return null;
    }
  }

  return (
    <div className="app-body space-list-container">
      <section>
        <div className="space-list-content-wrapper">
          <OverviewTabs
            isMobile={isMobile}
            isDashboardEnabled={isDashboardEnabled}
            handleChangeTab={handleChangeTab}
            showTemplates={selectedTab === 'Templates'}
            selectedTab={selectedTab}
          />
          <Suspense fallback={<div className="sections-container-loading" />}>
            {renderContent()}
          </Suspense>
        </div>
        {showSpaceTemplateDialog && (
          <Suspense fallback={null}>
            <CreateSpaceOrTemplateDialog
              open
              selectedTab={selectedTab}
              onClose={handleDialogClose}
              onCreateSpace={handleOpenCreateSpacePopup}
            />
          </Suspense>
        )}
        <Suspense fallback={null}>
          <CreateSpaceDialog ref={createSpaceRef} />
        </Suspense>
      </section>
      <PromoFeatureHandler />
    </div>
  );
}

SpacesOverview.propTypes = {
  tab: PropTypes.string,
};

export default SpacesOverview;

import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../components/AppProvider';
import Api from '../commons/api';
import RuntimeApi from '../commons/RuntimeApi';
import queryCache from '../app/queryCache';
import Widget from '../components/Widget';

const DealNotificationMessage = (props) => {
  const { id, messageTemplateId, names, messageTemplateData, currentUserInfoId } = props;
  const { isPortal } = useAppContext();

  return (
    <Widget
      id={`NotificationMessage-${id}`}
      packageId="@sp-ce/spp-widget-dms/notification-message"
      queryCache={queryCache}
      api={isPortal ? Api : RuntimeApi}
      isPortal={isPortal}
      messageTemplateId={messageTemplateId}
      names={names}
      messageTemplateData={messageTemplateData}
      currentUserInfoId={currentUserInfoId}
    />
  );
};

DealNotificationMessage.propTypes = {
  id: PropTypes.string.isRequired,
  messageTemplateId: PropTypes.string.isRequired,
  names: PropTypes.string.isRequired,
  messageTemplateData: PropTypes.instanceOf(Object).isRequired,
  currentUserInfoId: PropTypes.string.isRequired,
};

export default DealNotificationMessage;

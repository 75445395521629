import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import queryCache, { CacheKeys } from '../app/queryCache';
import Api from '../commons/api';
import {
  getIdentityApi,
  getFeatureFlagApi,
  getBffClientUrl,
  getCoreApi,
  getCoreRuntimeApi,
  getFeatureFlagRuntimeApi,
} from '../commons/utils';
import authService from '../components/api-authorization/AuthorizeService';
import RuntimeApi from '../commons/RuntimeApi';

export function createUser(data) {
  const payload = {
    firstName: _get(data, 'firstName', ''),
    lastName: _get(data, 'lastName', ''),
    email: _get(data, 'email', ''),
  };
  return Api.post(`${getCoreApi()}/users`, payload);
}

export function fetchUsers() {
  return Api.get(`${getCoreApi()}/users`).then((resp) => resp.users);
}

export function registerUser(user) {
  return Api.post(`${window.location.origin}/identity/api/users`, user, {}, false, false, true);
}

export function sendMagicLink(email, returnUrl, deviceId) {
  return Api.post(`${getIdentityApi()}/api/users/magiclink`, {
    email,
    returnUrl,
    device: deviceId,
  });
}

export function findUserInfoByEmail(email) {
  return Api.get(`${getCoreApi()}/users/userinfos/email`, { email }).catch((error) => {
    console.log('ERROR', error);
    return null;
  });
}

export function updateSeenIntro(seenIntro) {
  return Api.put(`${getCoreApi()}/users/seen-intro`, { seenIntro });
}

export async function updateSeenCompanyIAIntro(hasSeen = false) {
  try {
    await Api.put(`${getCoreApi()}/users/seen-company-analytics-intro`, { hasSeen });
    await authService.setSeenCompanyIAIntro(hasSeen);
  } catch (error) {
    console.log('ERROR updateSeenCompanyIAIntro', error);
  }
}

export function changePassword(oldPassword, newPassword) {
  return Api.put(`${getCoreApi()}/users/password`, {
    oldPassword,
    newPassword,
  });
}

export function deleteUserProfile() {
  return Api.delete(`${getCoreApi()}/users/profile`, {});
}

export function updateUserProfile(data) {
  return Api.put(`${getCoreApi()}/users/profile`, data);
}

export function updateOrgUserSubdomainSeenOn() {
  return Api.put(`${getCoreApi()}/users/seen-subdomain`);
}

export async function requestForCustomSubscription(subject, note) {
  return Api.post(`${getCoreApi()}/users/request-for-custom-subscription`, { subject, note });
}

export function sendRequestForgotPassword(email) {
  return Api.post(`${getIdentityApi(false)}/api/users/forgotpassword`, { email: email });
}

export function resetPassword(code, userId, newPassword) {
  return Api.post(`${getIdentityApi(false)}/api/users/resetpassword`, {
    code,
    userId,
    newPassword,
  });
}

export function inviteOrganizationUser(data) {
  return Api.put(`${getCoreApi()}/users/invite`, data);
}

export function getOrganizationUserInvitation(code) {
  return RuntimeApi.get(`${getCoreRuntimeApi()}/users/invitations?code=${code}`);
}

export function confirmOrganizationUserInvitation(data) {
  return RuntimeApi.post(`${getCoreRuntimeApi()}/users/confirm-invitation`, data);
}

export function updateOrganizationUserProfile(userInfoId, data) {
  return Api.put(`${getCoreApi()}/users/profile/${userInfoId}`, data);
}

export function signin(email, password) {
  return Api.post(
    `${getBffClientUrl()}/api/users/signin`,
    {
      email,
      password,
      rememberLogin: false,
    },
    {},
    false,
    false,
    true
  ).catch((error) => {
    console.error(error);
  });
}

export function sendOrganizationUserInvitation(userInfoId) {
  return Api.post(`${getCoreApi()}/users/invitation`, { userInfoId });
}

export const getUsersByIds = _memoize(
  async (spaceIds) => {
    let filterString = '';
    if (spaceIds && spaceIds.length > 0) {
      spaceIds.forEach((id) => {
        if (filterString) {
          filterString += '&';
        }
        filterString += `ids=${id}`;
      });
    }
    return Api.get(`${getCoreApi()}/users/list-by-ids?${filterString}`);
  },
  (spaceIds) => spaceIds
);

export async function getOrganizationSubscription(organizationId) {
  try {
    const resp = await Api.get(`${getCoreApi()}/organizations/${organizationId}/subscription`);
    return resp;
  } catch (error) {
    console.log('ERROR getOrganizationSubscription', error);
    return null;
  }
}

export function getOrganizationById(organizationId) {
  return Api.get(`${getCoreApi()}/organizations/${organizationId}`);
}

export async function updateDesignSettings(organizationId, data) {
  const resp = await Api.put(
    `${getCoreApi()}/organizations/${organizationId}/design-settings`,
    data
  );

  queryCache.setQueryData([CacheKeys.getOrganizationById, organizationId], (oldData) => {
    if (!oldData) {
      return oldData;
    }
    return { ...oldData, theme: { ...oldData.theme, ...data } };
  });

  return resp;
}

export function deleteUserOrganization(organizationId, data) {
  return Api.put(`${getCoreApi()}/organizations/${organizationId}/users/delete`, data);
}

export function updateIntegrationNotice(data) {
  return Api.put(`${getCoreApi()}/users/integration-notice/seen`, data);
}

export function fetchTagsOrganization(organizationId) {
  return Api.get(`${getCoreApi()}/organizations/${organizationId}/tags`);
}

export function addNewTagOrganization(organizationId, data) {
  return Api.post(`${getCoreApi()}/organizations/${organizationId}/tags`, data);
}

export function updateTagOrganization(organizationId, id, data) {
  return Api.put(`${getCoreApi()}/organizations/${organizationId}/tags/${id}`, data);
}

export function deleteTagOrganization(organizationId, id) {
  return Api.delete(`${getCoreApi()}/organizations/${organizationId}/tags/${id}`);
}

export function fetchCheckExistingTagName(organizationId, name) {
  return Api.get(`${getCoreApi()}/organizations/${organizationId}/tags/${name}/exists`);
}

export function getOrganizationsUsers(organizationId, isPortal = true) {
  if (isPortal) {
    return Api.get(`${getCoreApi()}/organizations/${organizationId}/users`);
  } else {
    return RuntimeApi.get(`${getCoreRuntimeApi()}/runtime/organizations/${organizationId}/users`);
  }
}

export async function getFeatureFlagsOrg(isPortal = false) {
  let resp = null;
  try {
    if (isPortal) {
      resp = await Api.get(`${getFeatureFlagApi()}/api/features/enabled`);
    } else {
      resp = await RuntimeApi.get(`${getFeatureFlagRuntimeApi()}/api/runtime/features/enabled`);
    }
  } catch (error) {
    console.log('error', error.message);
  }
  return resp;
}

export function signInOrganization() {
  return Api.put(`${getCoreApi()}/users/sign-in-organization`)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
    });
}

export function inviteOrganizationUsers(data) {
  return Api.put(`${getCoreApi()}/users/bulk-invite`, data);
}

import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import { ResourceState, ResourceInteractionType } from '../app/appConstants';
import ErrorMessage from '../components/ErrorMessage';
import ImageViewer from '../components/ImageViewer';
import { triggerResourceConversionRequest } from './ResourceServices';
import Loading from '../components/Loading';
import i18n from '../i18n';
import spaceUser from '../spaces/spaceUser';
import DownloadResourceView from './DownloadResourceView';
import { createResourceInteraction } from '../commons/CommonServices';
import useGetResourceForViewerQuery from './getResourceForViewerQuery';
import ResourceInfoView from './ResourceInfoView';
import ResourceViewer from './ResourceViewer';

const ResourceViewerWrapper = (props) => {
  const {
    context,
    materialId,
    resourceId,
    documentName,
    showDownloadView,
    showInfoView,
    externalResourceUrl,
    directoryId,
    resourceHubConnection,
    isIntegrationMaterial,
  } = props;

  const isPortal = context?.type === 'portal';
  const spaceId = props.spaceId || spaceUser.getSpaceId();

  const getResourceForViewerResp = useGetResourceForViewerQuery(
    spaceId,
    resourceId,
    materialId,
    isPortal,
    !externalResourceUrl
  );

  useEffect(() => {
    function onConversionFinished({ id, state }) {
      if (id === resourceId && state === ResourceState.Converted) {
        getResourceForViewerResp.refetch();
      }
    }
    if (resourceHubConnection) {
      resourceHubConnection?.on('ConversionFinished', onConversionFinished);
    }
    return () => {
      if (resourceHubConnection) {
        resourceHubConnection?.off('ConversionFinished', onConversionFinished);
      }
    };
    // eslint-disable-next-line
  }, []);

  const triggerResourceConversion = useCallback(() => {
    triggerResourceConversionRequest(spaceId, directoryId, materialId, resourceId, isPortal);
  }, [spaceId, directoryId, materialId, resourceId, isPortal]);

  function handleOnDownloaded() {
    if (!isPortal && resourceId && materialId && !isIntegrationMaterial) {
      createResourceInteraction(resourceId, {
        materialId: materialId,
        interactionType: ResourceInteractionType.Downloaded,
      });
    }
  }

  if (showDownloadView) {
    return (
      <DownloadResourceView
        resourceId={resourceId}
        materialId={materialId}
        url={getResourceForViewerResp.data?.url}
        name={documentName}
        isPortal={isPortal}
        spaceId={spaceId}
        isLoading={getResourceForViewerResp.isFetching}
        onDownloaded={handleOnDownloaded} // tracking download IA
        fileSize={props.fileSize}
      />
    );
  }

  if (showInfoView) {
    return (
      <ResourceInfoView
        name={documentName}
        isLoading={getResourceForViewerResp.isFetching}
        onClose={props.onClose}
        fileSize={props.fileSize}
      />
    );
  }

  if (getResourceForViewerResp.isFetching) {
    return <Loading className="loading" />;
  }

  if (externalResourceUrl) {
    return <ImageViewer src={externalResourceUrl} />;
  }

  const resource = getResourceForViewerResp.data;

  if (!resource?.resourceId) {
    return (
      <ErrorMessage message={i18n.t('Sorry, this resource is not available at the moment!')} />
    );
  }

  function renderViewer() {
    return (
      <ResourceViewer
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        resource={resource}
        triggerResourceConversion={triggerResourceConversion}
      />
    );
  }

  return renderViewer();
};

ResourceViewerWrapper.propTypes = {
  materialId: PropTypes.number,
  resourceId: PropTypes.number,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  documentName: PropTypes.string,
  context: PropTypes.instanceOf(Object),
  showDownloadView: PropTypes.bool,
  showInfoView: PropTypes.bool,
  externalResourceUrl: PropTypes.string,
  isExternal: PropTypes.bool,
  spaceId: PropTypes.string,
  directoryId: PropTypes.number,
  resourceHubConnection: PropTypes.instanceOf(Object),
  isRecordingFile: PropTypes.bool,
  onClose: PropTypes.func,
  fileSize: PropTypes.string,
  isIntegrationMaterial: PropTypes.bool,
  isDownloadable: PropTypes.bool,
  preventEvents: PropTypes.bool,
};

ResourceViewerWrapper.defaultProps = {
  controls: true,
  autoPlay: false,
  showDownloadView: false,
  showInfoView: false,
  isExternal: false,
  onClose: () => {},
};

export default ResourceViewerWrapper;

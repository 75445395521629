import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _cloneDeep from 'lodash/cloneDeep';
import _reject from 'lodash/reject';
import { useDebouncedCallback } from 'use-debounce';
import _find from 'lodash/find';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import _isEqual from 'lodash/isEqual';
import {
  moveArrayItemUp,
  moveArrayItemDown,
  getMaterialIdsFromBlocks,
  getPagesStyleSettings,
  getModifiedBlocksInSameSpace,
  getSavedColors,
  updateBlocksMaterials,
  checkMaterialFirstBlock,
  getModifiedPagesBlock,
  getCompareablePagesBlock,
  getAllCourseBlocksFromBlocks,
} from '../../cmsUtils';
import i18n from '../../../i18n';
import useCmsDirectory from '../useCmsDirectory';
import { removeMaterial } from '../../../resources/ResourceServices';
import { FeatureFlagsType } from '../../../app/appConstants';
import { isJSON, newGuid, sendNotification } from '../../../commons/utils';
import { CacheKeys, clearCache } from '../../../app/queryCache';
import { CMS_COMPONENT_TYPE } from '../../cmsConstants';
import BlockUI from '../../../components/BlockUI';
import { processPastingBlock, localStorageKey } from '../../copyPasteUtils';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import { deletePagesBlock, handleUpdatePagesBlock } from '../../cmsServices';
import eventBus, { EVENT_BUS } from '../../../commons/EventBus';
import { updateCustomThemeSpace } from '../../../createSpace/services';
import { useFeatureFlagsContext } from '../../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { saveCMSChangesLog } from '../../../homepage/spaceService';
import CreateMaterialHandler from './CreateMaterialHandler';
import useSpaceCourseBlocks from './useSpaceCourseBlocks';

export const ThemeSettingsContext = React.createContext({});

export const useThemeSettingsContext = () => React.useContext(ThemeSettingsContext);

export const getThemeSettingsContext = memoizeOne(
  (
    space,
    blocks,
    selectedBlock,
    siblingBlock,
    openSidePanel,
    spaceId,
    isTemplate,
    cmsDirectory,
    openUnsplashPreview,
    workingData,
    isCreatedFromTemplate,
    savedColors,
    setSavedColors,
    fetchSpaceInvitationQuery,
    hasChanges,
    isSaving,
    globalTemplateHasChanges,
    isMobileMode
  ) => {
    console.log('### 1 hasChanges getThemeSettingsContext', hasChanges);
    return {
      space,
      blocks,
      selectedBlock,
      siblingBlock,
      openSidePanel,
      spaceId,
      isTemplate,
      cmsDirectory,
      openUnsplashPreview,
      workingData,
      isCreatedFromTemplate,
      savedColors,
      setSavedColors,
      fetchSpaceInvitationQuery,
      hasChanges,
      isSaving,
      globalTemplateHasChanges,
      isMobileMode,
    };
  }
);

function ThemeSettingsContextProvider(props) {
  const { children, space, fetchSpaceInvitationQuery } = props;
  const spaceId = space?.id;
  const { setCurrentPage, setPagesBlock, pagesBlock, currentPage } = useSpaceContext();
  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [siblingBlock, setSiblingBlock] = useState(null); // to add a new block beneath this block
  const [openSidePanel, setOpenSidePanel] = useState(true);
  const [openUnsplashPreview, setOpenUnsplashPreview] = useState(false);
  const [workingData, setWorkingData] = useState(null);
  const [cmsDirectory, , isFetchingCmsDirectory] = useCmsDirectory(spaceId);
  const [savedColors, setSavedColors] = useState(space?.customTheme?.colors || []);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [globalTemplateHasChanges, setGlobalTemplateHasChanges] = useState(false);
  const [isMobileMode, setIsMobileMode] = useState(false);

  const materialsShouldBeRemoved = useRef([]);
  const isTemplate = space?.isTemplate;
  const cmsDirectoryId = cmsDirectory?.id;
  const currentPageId = currentPage.id;
  const blocks = currentPage.blocks;
  const currentPageRef = useRef(currentPage);
  const originPageRef = useRef(null);
  const originPagesBlockRef = useRef(null);
  const isTriggered = useRef(false);

  const resourceIdNeedCreateMaterial = checkMaterialFirstBlock(blocks?.[0], isTriggered.current);
  const [enabledCMSGlobalTemplate] = checkEnabledFeatures([
    FeatureFlagsType.CMS_GLOBAL_TEMPLATE_UPDATE,
  ]);
  const appliedGlobalTemplate = enabledCMSGlobalTemplate && isTemplate && !!space?.templateRefId;

  const { createBlockDirectory, removeBlockDirectory } = useSpaceCourseBlocks(space);

  const updateBlocksDebounced = useDebouncedCallback(async (pageId, newBlocks, newSavedColors) => {
    // inner functions
    setIsSaving(true);
    setHasChanges(false);
    try {
      // console.log('### handleUpdateBlocks', latestBlocks, blocks);
      // handle add/update/delete material if any after uploading, remove resource object out of blocks before saving
      const colorsOptions = getSavedColors(newSavedColors, newBlocks);

      setSelectedBlock((prevState) => {
        if (!prevState?.id) return prevState;
        const curentBlock = _find(newBlocks, (item) => item.id === prevState.id);
        if (curentBlock && JSON.stringify(curentBlock) !== JSON.stringify(prevState)) {
          return curentBlock;
        }
        return prevState;
      });
      if (JSON.stringify(colorsOptions) !== JSON.stringify(newSavedColors)) {
        setSavedColors(colorsOptions);
      }

      // save to db
      const customTheme = {
        items: newBlocks,
        colors: colorsOptions,
      };

      console.log('### updateBlocksDebounced customTheme newBlocks', customTheme);

      await updateCustomThemeSpace(spaceId, { customTheme }, pageId);

      const isBlocksExisted = getAllCourseBlocksFromBlocks(newBlocks).length > 0;
      if (isBlocksExisted) {
        createBlockDirectory();
      } else {
        console.log('### blocks', blocks);
        const foundBlocks = getAllCourseBlocksFromBlocks(blocks);
        if (foundBlocks.length === 0) {
          removeBlockDirectory();
        }
      }
      console.log('### blocks', blocks);

      const { latestBlocks, unUsedMaterialIds } = await updateBlocksMaterials(
        cmsDirectoryId,
        spaceId,
        pageId,
        newBlocks,
        colorsOptions
      );

      if (latestBlocks !== newBlocks) {
        if (currentPageRef.current.id === pageId) {
          currentPageRef.current.blocks = latestBlocks;
          setCurrentPage((prev) => {
            if (!prev) return prev;
            if (prev.id !== pageId) return prev;
            return { ...prev, blocks: latestBlocks };
          });
          setSelectedBlock((prevState) => {
            if (!prevState?.id) return prevState;
            const curentBlock = _find(latestBlocks, (item) => item.id === prevState.id);
            if (curentBlock && JSON.stringify(curentBlock) !== JSON.stringify(prevState)) {
              return curentBlock;
            }
            return prevState;
          });
        } else {
          setPagesBlock((prev) => {
            if (!prev) {
              return null;
            }
            const newPagesBlock = { ...prev };
            const index = pagesBlock.items[0].pages.findIndex((page) => page.id === pageId);
            if (index >= 0) {
              console.log('### updateBlocksDebounced index', index, latestBlocks);
              newPagesBlock.items[0].pages[index].blocks = latestBlocks;
            }
            return newPagesBlock;
          });
        }
      }
      if (unUsedMaterialIds.length > 0) {
        materialsShouldBeRemoved.current.push(...unUsedMaterialIds);
      }
      if (materialsShouldBeRemoved.current.length > 0) {
        const materialsInUse = getMaterialIdsFromBlocks(latestBlocks);
        console.log('### materialsInUse', materialsInUse);
        materialsShouldBeRemoved.current.forEach((materialId) => {
          if (materialsInUse.includes(materialId)) return;
          removeMaterial(spaceId, materialId, cmsDirectoryId);
        });
        materialsShouldBeRemoved.current = [];
      }
    } catch (error) {
      console.error(error);
      sendNotification(i18n.t('Something went wrong! Please try again later.'), { type: 'error' });
    }
    setIsSaving(false);
  }, 5000);

  const handleScrollingToBlock = useDebouncedCallback((block, position = 'center') => {
    if (!block?.id) return;
    document.getElementById(block.id)?.scrollIntoView({ behavior: 'smooth', block: position });
  }, 600);

  const saveSpaceCMSDesign = useCallback(async () => {
    let currentModifiedOtherBlocks = null;
    let currentModifiedPagesBlock = null;
    const shouldSendCmsDesignChangeLogs =
      appliedGlobalTemplate &&
      currentPageRef.current &&
      currentPageRef.current.id === originPageRef.current?.id;

    if (shouldSendCmsDesignChangeLogs) {
      const { modifiedBlocks, hasOrderChanged } = getModifiedBlocksInSameSpace(
        originPageRef.current.blocks,
        currentPageRef.current.blocks
      );
      if (modifiedBlocks?.length > 0 || hasOrderChanged) {
        currentModifiedOtherBlocks = {
          modifiedBlocks,
          hasOrderChanged,
          pageId: currentPageRef.current.id,
        };
      }

      currentModifiedPagesBlock = getModifiedPagesBlock(
        originPagesBlockRef.current,
        getCompareablePagesBlock(pagesBlock)
      );
    }

    // modifiedPagesBlockRef.current = null;
    currentPageRef.current = currentPage;
    originPageRef.current = { ...currentPage };
    originPagesBlockRef.current = getCompareablePagesBlock(pagesBlock);

    if (updateBlocksDebounced.isPending()) {
      await updateBlocksDebounced.flush();
    }

    if (currentModifiedOtherBlocks) {
      await saveCMSChangesLog(spaceId, currentModifiedOtherBlocks);
    }

    if (currentModifiedPagesBlock) {
      await saveCMSChangesLog(spaceId, {
        modifiedBlocks: [currentModifiedPagesBlock],
      });
    }

    setGlobalTemplateHasChanges(false);
  }, [appliedGlobalTemplate, currentPage, spaceId, pagesBlock, updateBlocksDebounced]);

  const handleUpdateBlock = useCallback(
    (newBlock, forceUpdate = false) => {
      if (!newBlock?.id) return;
      setSelectedBlock(newBlock);
      if (newBlock.isPagesBlock) {
        setPagesBlock((prevPagesBlock) => {
          const newPagesBlock = _cloneDeep(newBlock);
          const prevPagesStyleSettings = getPagesStyleSettings(prevPagesBlock);
          const newPagesStyleSettings = getPagesStyleSettings(newBlock);
          if (!_isEqual(prevPagesStyleSettings, newPagesStyleSettings)) {
            handleUpdatePagesBlock(spaceId, newBlock.id, newPagesStyleSettings);
          }
          return newPagesBlock;
        });
      } else {
        setCurrentPage((prevState) => {
          if (!prevState) return prevState;
          const foundBlockIndex = prevState.blocks.findIndex((r) => r.id === newBlock.id);
          if (foundBlockIndex === -1) {
            return prevState;
          }
          const newBlocks = _cloneDeep(prevState.blocks);
          const oldMaterials = getMaterialIdsFromBlocks([newBlocks[foundBlockIndex]]);
          newBlocks[foundBlockIndex] = newBlock;
          const newMaterials = getMaterialIdsFromBlocks([newBlock]);

          // Remove materials that are not used anymore when delete column
          if (JSON.stringify(oldMaterials) !== JSON.stringify(newMaterials)) {
            // console.log('### handleUpdateBlock oldMaterials', oldMaterials);
            // console.log('### handleUpdateBlock newMaterials', newMaterials);
            oldMaterials.forEach((materialId) => {
              if (
                !newMaterials.includes(materialId) &&
                !materialsShouldBeRemoved.current.includes(materialId)
              ) {
                materialsShouldBeRemoved.current.push(materialId);
              }
            });
          }
          return { ...prevState, blocks: newBlocks };
        });
        if (forceUpdate) {
          // console.log('handleUpdateBlock newThemeSettings', newThemeSettings);
          setTimeout(() => {
            if (updateBlocksDebounced.isPending()) {
              updateBlocksDebounced.flush();
            }
          }, 500);
        } // otherwise, autosave after 5 seconds
      }
    },
    [setCurrentPage, setPagesBlock, spaceId, updateBlocksDebounced]
  );

  useEffect(() => {
    if (
      getModifiedPagesBlock(originPagesBlockRef.current, getCompareablePagesBlock(pagesBlock)) !==
      null
    ) {
      saveSpaceCMSDesign();
    }
  }, [pagesBlock, saveSpaceCMSDesign]);

  useEffect(() => {
    if (pagesBlock) {
      setCurrentPage((prevState) => {
        if (!prevState) return prevState;
        const foundCurrentPage = pagesBlock.items[0].pages.find((r) => r.id === prevState.id);
        if (!foundCurrentPage) {
          return pagesBlock.items[0].pages[0];
        }
        return prevState;
      });
    }
  }, [pagesBlock, setCurrentPage]);

  useEffect(() => {
    if (originPageRef.current?.id !== currentPage.id) {
      saveSpaceCMSDesign();

      setSiblingBlock(null);
      setSelectedBlock((prev) => {
        if (prev?.isPagesBlock) {
          return prev;
        }
        return null;
      });
      setHasChanges(false);
    }
  }, [currentPage, saveSpaceCMSDesign]);

  useEffect(() => {
    // console.log('### has changed', hasChanges);
    setHasChanges((prevState) => {
      console.log('### 3011 setHasChanges', currentPageRef.current.id, currentPage.id);
      if (currentPageRef.current.id !== currentPage.id) {
        return false;
      }
      if (!prevState) {
        const { modifiedBlocks, hasOrderChanged } = getModifiedBlocksInSameSpace(
          currentPageRef.current.blocks,
          currentPage.blocks
        );
        console.log('### modifiedBlocks', modifiedBlocks);
        if (!modifiedBlocks?.length && !hasOrderChanged) {
          currentPageRef.current.blocks = currentPage.blocks;
          return false;
        }
      }
      currentPageRef.current.blocks = currentPage.blocks;
      console.log('### 3011 setHasChanges updateBlocksDebounced', currentPage.id);
      if (appliedGlobalTemplate) {
        setGlobalTemplateHasChanges(true);
      }
      updateBlocksDebounced(currentPage.id, currentPage.blocks, savedColors);
      return true;
    });
  }, [appliedGlobalTemplate, currentPage, savedColors, updateBlocksDebounced]);

  useEffect(() => {
    return () => {
      // console.log('### 3011 unmount:', space?.id);
      // Anything in here is fired on component unmount.
      // clear caches
      clearCache([CacheKeys.getSpaceRuntime], false);
      if (spaceId) {
        clearCache([CacheKeys.fetchSpaceInvitation, spaceId], false);
      }
    };
  }, [spaceId]);

  function handleMoveUp(block) {
    const currentBlock = block || selectedBlock;
    if (!currentBlock?.id) return;
    // console.log('### handleMoveUp');
    setCurrentPage((prevState) => {
      if (!prevState) return prevState;
      let newBlocks = _cloneDeep(prevState.blocks);
      const index = newBlocks.findIndex((r) => r.id === currentBlock.id);
      newBlocks = moveArrayItemUp(newBlocks, index);
      return { ...prevState, blocks: newBlocks };
    });
    setSelectedBlock(currentBlock);
    handleScrollingToBlock(currentBlock);
  }

  function handleMoveDown(block) {
    const currentBlock = block || selectedBlock;
    if (!currentBlock?.id) return;
    // console.log('### handleMoveDown');
    setCurrentPage((prevState) => {
      if (!prevState) return prevState;
      let newBlocks = _cloneDeep(prevState.blocks);
      const index = newBlocks.findIndex((r) => r.id === currentBlock.id);
      newBlocks = moveArrayItemDown(newBlocks, index);
      return { ...prevState, blocks: newBlocks };
    });
    setSelectedBlock(currentBlock);
    handleScrollingToBlock(currentBlock);
  }

  async function handleRemoveBlock(block) {
    const currentBlock = block || selectedBlock;
    // console.log('### handleRemoveBlock', currentBlock);
    if (!currentBlock?.id) return;
    if (currentBlock.isPagesBlock) {
      eventBus.publish(EVENT_BUS.BlockScreenChanged, true);
      try {
        if (updateBlocksDebounced.isPending()) {
          await updateBlocksDebounced.flush();
        }
        const resp = await deletePagesBlock(spaceId, currentBlock.id);

        fetchSpaceInvitationQuery.updateSpace({ pagesBlock: null, customTheme: resp?.customTheme });
        setSelectedBlock(null);
      } catch (error) {
        console.error(error);
      }
      eventBus.publish(EVENT_BUS.BlockScreenChanged, false);
    } else {
      setCurrentPage((prevState) => {
        if (!prevState) return prevState;
        let newBlocks = _cloneDeep(prevState.blocks);
        newBlocks = _reject(newBlocks, (r) => r.id === currentBlock.id);
        if (selectedBlock && (newBlocks.length === 0 || selectedBlock.id === currentBlock.id)) {
          setSelectedBlock(null);
        }
        return { ...prevState, blocks: newBlocks };
      });
      const materials = getMaterialIdsFromBlocks([currentBlock]);
      materials.forEach((materialId) => {
        materialsShouldBeRemoved.current.push(materialId);
      });
    }
  }

  function canMoveUp(block) {
    const id = block?.id || selectedBlock?.id;
    if (!id) return false;
    const index = blocks.findIndex((r) => r.id === id);
    return index !== 0;
  }

  function canMoveDown(block) {
    const id = block?.id || selectedBlock?.id;
    if (!id) return false;
    const index = blocks.findIndex((r) => r.id === id);
    return index !== blocks.length - 1;
  }

  function handleDuplicateColumn(block) {
    const currentBlock = block || selectedBlock;
    if (!currentBlock?.id) return;
    const type = currentBlock.items?.[0]?.type;

    const duplicatedBlock = _cloneDeep(currentBlock);
    const blockId = newGuid();
    duplicatedBlock.id = blockId;
    const isCarousel = type === CMS_COMPONENT_TYPE.CAROUSEL;
    duplicatedBlock.items = duplicatedBlock.items.map((item) => {
      if (isCarousel) {
        item.items = item.items.map((x) => ({ ...x, id: newGuid() }));
      }
      return {
        ...item,
        id: newGuid(),
      };
    });
    setCurrentPage((prevState) => {
      if (!prevState || prevState.id !== currentPageId) {
        return prevState;
      }
      const newBlocks = _cloneDeep(blocks);
      const index = blocks.findIndex((r) => r.id === currentBlock.id);
      newBlocks.splice(index + 1, 0, duplicatedBlock);
      return { ...prevState, blocks: newBlocks };
    });
    setSelectedBlock(duplicatedBlock);
    handleScrollingToBlock(duplicatedBlock);
    setOpenSidePanel(true);
  }

  const handleSetSelectedBlock = useCallback((block) => {
    setSelectedBlock(block);
    if (block) {
      setWorkingData({ contentType: block?.items?.[0]?.type, currentTab: 0 });
    } else {
      setWorkingData(null);
    }
  }, []);

  function onClickPreviewScript(blockSettings) {
    if (!blockSettings || !selectedBlock) {
      return;
    }
    const newSelectedBlock = { ...selectedBlock };
    const foundBlock = _find(newSelectedBlock.items, (item) => item.id === blockSettings.id);
    if (foundBlock) {
      foundBlock.key = newGuid();
      // console.log('newSelectedBlock', newSelectedBlock);
      setSelectedBlock(newSelectedBlock);
    }
  }

  async function handleCopyBlock(block) {
    const currentBlock = block || selectedBlock;
    // console.log('### 203 handleCopyBlock', currentBlock, space);
    if (!currentBlock) return;
    try {
      const data = {
        block: currentBlock,
        fromSpaceId: space?.id,
        fromSpaceUrlSlug: space?.urlSlug,
        fromOrganizationId: space?.organizationId,
      };
      const text = JSON.stringify(data);
      localStorage.setItem(localStorageKey, text);
      await navigator.clipboard?.writeText(text);
      sendNotification(i18n.t('Block copied!'), { duration: 3000 });
    } catch {
      // console.error('Failed to copy: ', err);
      // console.log('### Failed to copy: ', err);
    }
  }

  function validateBlock(block) {
    if (!block) return false;
    const isValidBlock =
      _isObject(block) &&
      block.id &&
      _isString(block.id) &&
      block.items &&
      _isArray(block.items) &&
      block.items.length > 0;
    if (!isValidBlock) return false;
    const type = block.items[0].type;
    const isInValidType = type === CMS_COMPONENT_TYPE.WIDGET || type === CMS_COMPONENT_TYPE.SCRIPT;
    if (isInValidType) return false;
    return true;
  }

  function validateCopiedData(data) {
    if (!data) return false;
    const isValid =
      _isObject(data) &&
      data.fromSpaceId &&
      !!data.fromOrganizationId &&
      data.fromOrganizationId === space?.organizationId &&
      validateBlock(data.block);
    return isValid;
  }

  async function readCopiedCmsBlockData(useClipboard = false) {
    try {
      let text = '';
      const textInLocalStorage = localStorage.getItem(localStorageKey);
      const result = await navigator.permissions.query({ name: 'clipboard-read' });
      // console.log('### 293 check permission result: ', useClipboard, result);
      const flag = result.state === 'granted' || (useClipboard && result.state === 'prompt');
      if (flag) {
        text = await navigator?.clipboard.readText();
      } else {
        // result.state === 'denied'
        text = textInLocalStorage;
      }
      if (text !== textInLocalStorage) localStorage.setItem(localStorageKey, text);
      if (!isJSON(text)) return null;
      const data = JSON.parse(text);
      const isValid = validateCopiedData(data);
      if (isValid) {
        return data;
      } else {
        // console.log('### 293 invalid copied data: ', data);
      }
    } catch {
      // console.error(err);
      // console.log('### 293 error in readCopiedData: ', err);
    }
    return null;
  }

  async function handlePasteBlock(block, fromMenuClick = false) {
    const currentBlock = block || selectedBlock;
    // console.log('### 123 handlePasteBlock', currentBlock);
    try {
      const useClipboard = fromMenuClick;
      const data = await readCopiedCmsBlockData(useClipboard);
      if (data) {
        const newBlock = _cloneDeep(data.block);
        const params = {
          fromSpaceId: data.fromSpaceId,
          fromSpaceUrlSlug: data.fromSpaceUrlSlug,
          toSpaceId: space?.id,
          toSpaceUrlSlug: space?.urlSlug,
        };
        processPastingBlock(newBlock, params);
        setCurrentPage((prevState) => {
          if (!prevState) return { id: currentPageId, blocks: [newBlock] };
          const newBlocks = _cloneDeep(prevState.blocks);
          if (currentBlock) {
            const index = newBlocks.findIndex((r) => r.id === currentBlock.id);
            newBlocks.splice(index + 1, 0, newBlock);
          } else {
            newBlocks.push(newBlock);
          }
          sendNotification(i18n.t('Block pasted!'), { duration: 3000 });
          return { ...prevState, blocks: newBlocks };
        });
        setSelectedBlock(newBlock);
        handleScrollingToBlock(newBlock);
      }
    } catch (err) {
      // console.error('Failed to paste: ', err);
      console.log('### Failed to paste: ', err);
    }
  }

  const isCreatedFromTemplate = space?.isUseCMSScriptFromTemplate;

  const context = getThemeSettingsContext(
    space,
    blocks,
    selectedBlock,
    siblingBlock,
    openSidePanel,
    spaceId,
    isTemplate,
    cmsDirectory,
    openUnsplashPreview,
    workingData,
    isCreatedFromTemplate,
    savedColors,
    setSavedColors,
    fetchSpaceInvitationQuery,
    hasChanges,
    isSaving,
    globalTemplateHasChanges,
    isMobileMode
  );
  context.moveUp = handleMoveUp;
  context.moveDown = handleMoveDown;
  context.removeBlock = handleRemoveBlock;
  context.updateBlock = handleUpdateBlock;
  context.canMoveUp = canMoveUp;
  context.canMoveDown = canMoveDown;
  context.handleDuplicateColumn = handleDuplicateColumn;
  context.setSelectedBlock = handleSetSelectedBlock;
  context.setSiblingBlock = setSiblingBlock;
  context.setOpenSidePanel = setOpenSidePanel;
  context.setCurrentPage = setCurrentPage;
  context.setOpenUnsplashPreview = setOpenUnsplashPreview;
  context.setWorkingData = setWorkingData;
  context.onClickPreviewScript = onClickPreviewScript;
  context.handleScrollingToBlock = handleScrollingToBlock;
  context.handleCopyBlock = handleCopyBlock;
  context.handlePasteBlock = handlePasteBlock;
  context.readCopiedCmsBlockData = readCopiedCmsBlockData;
  context.validateCopiedData = validateCopiedData;
  context.saveSpaceCMSDesign = saveSpaceCMSDesign;
  context.currentPageRef = currentPageRef;
  context.setIsMobileMode = setIsMobileMode;

  return (
    <ThemeSettingsContext.Provider value={context}>
      {isFetchingCmsDirectory && (
        <BlockUI
          fullScreen
          display
          backgroundColor="#fff"
          backgroundOpacity={0.5}
          iconColor="#222"
        />
      )}
      {children}
      {resourceIdNeedCreateMaterial && cmsDirectoryId && (
        <CreateMaterialHandler
          firstBlockId={blocks?.[0]?.id}
          resourceId={resourceIdNeedCreateMaterial}
          spaceId={spaceId}
          cmsDirectoryId={cmsDirectoryId}
          setCurrentPage={setCurrentPage}
          onTrigger={() => {
            isTriggered.current = true;
          }}
        />
      )}
    </ThemeSettingsContext.Provider>
  );
}

ThemeSettingsContextProvider.propTypes = {
  children: PropTypes.instanceOf(Object),
  space: PropTypes.instanceOf(Object),
  fetchSpaceInvitationQuery: PropTypes.instanceOf(Object),
};

export default ThemeSettingsContextProvider;

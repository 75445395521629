import React, { useRef, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import update from 'immutability-helper';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './CmsPagesList.scss';
import { useLocalStorage } from '../../commons/CustomHooks';
import ConfirmRemoveBlockDialog from '../components/ConfirmRemoveBlockDialog';
import i18n from '../../i18n';
import OfficialButton from '../../components/OfficialButtons';
import { Ids } from '../../commons/pendoTaggings';
import { useSpaceContext } from '../../spaces/SpaceContext';
import LightTooltip from '../../components/LightTooltip';
import Icons from '../../components/Icons';
import Loading from '../../components/Loading';
import { useThemeSettingsContext } from '../components/context/ThemeSettingsContextProvider';

const PagesItem = (props) => {
  const { index, id, canMove, canRemove, hideDeleteButton, isActive, onClick, hidden } = props;
  const ref = useRef(null);
  const type = 'PagesItem';

  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // drop(item) {
    //   props.onDropped(item);
    // },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.movePage(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { type, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      props.onDropped();
    },
  });

  useLayoutEffect(() => {
    if (canMove) {
      drag(drop(ref));
    }
    return () => {
      if (canMove) {
        drag(null);
        drop(null);
      }
    };
  }, [canMove, drag, drop, ref]);

  const className = ` ${isDragging ? ' dragging' : ''} ${canMove ? '' : 'disabled-drag'}`;

  const handleOnRemovePage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.onRemovePage(id);
  };

  const handleOnClick = () => {
    onClick(index);
  };

  return (
    <div
      ref={ref}
      className={`cms-pages-item ${className} ${isActive ? 'active' : ''} ${hidden ? 'hidden' : ''}`}
      data-handler-id={handlerId}
      onClick={handleOnClick}
    >
      <span className="cms-pages-item-left">
        <span className="icon-columns"></span>
        <div className="text-truncate">{props.pages.name}</div>
      </span>
      <div className="cms-pages-item-icons">
        {hidden && (
          <LightTooltip title={i18n.t('This page is hidden for users')}>
            <VisibilityOffIcon className="hidden-icon" />
          </LightTooltip>
        )}
        {!hideDeleteButton && (
          <LightTooltip
            title={i18n.t('You have reached the minimum limit of 1 page.')}
            disableHoverListener={canRemove}
            disableTouchListener={canRemove}
          >
            <span>
              <IconButton
                className="delete-button"
                disabled={!canRemove}
                onClick={handleOnRemovePage}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </LightTooltip>
        )}
      </div>
    </div>
  );
};

PagesItem.propTypes = {
  pages: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  id: PropTypes.number,
  canMove: PropTypes.bool,
  canRemove: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  movePage: PropTypes.func,
  onDropped: PropTypes.func,
  onRemovePage: PropTypes.func,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
};

// const getList = memoizeOne((labels, pages) => {
//   console.log('array', labels);
//   const array = [];
//   for (let index = 0; index < pages; index += 1) {
//     array.push({ id: index, name: `${labels} ${index}` });
//   }
//   return array;
// });

const PagesList = (props) => {
  const { name, labelName, pages, max, min, hideDeleteButton, onChangePositionPages } = props;
  const { currentPage, onChangeNavigation } = useSpaceContext();
  const { isMobileMode } = useThemeSettingsContext();
  const [list, setList] = useState(
    pages && pages.length > 0 ? pages : [{ id: 0, name: 'Page Title', position: 0 }]
  );
  const [loading, setLoading] = useState(false);
  const [dismissConfirmRemove, setDismissConfirmRemove] = useLocalStorage(
    'dismissConfirmRemovePages',
    ''
  );
  const hidden = isMobileMode ? props.hidden.mobile : props.hidden.desktop;

  const disabledAddButton = list.length === max || loading;

  useEffect(() => {
    if (pages && pages.length > 0) {
      setList(pages);
    }
  }, [pages]);

  const movePage = useCallback((dragIndex, hoverIndex) => {
    setList((prev) => {
      const dragCard = prev[dragIndex];
      const newPages = update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      return newPages;
    });
    // props.onChangePositionPages(dragIndex, hoverIndex);
  }, []);

  const onDropped = () => {
    onChangePositionPages(list);
  };

  // const handleMoveDebounce = useDebouncedCallback((dragIndex, hoverIndex) => {
  //   movePage(dragIndex, hoverIndex);
  // }, 400);

  const handleRemovePage = async (id) => {
    if (!dismissConfirmRemove) {
      const { hasConfirmed, doNotShow } = await ConfirmRemoveBlockDialog.show(
        'Remove page',
        <div>
          Do you want to delete this page? <br></br>Removing it will also delete all blocks on the
          page.
        </div>,
        true,
        `Don't ask me again`
      );
      if (hasConfirmed) {
        setDismissConfirmRemove(doNotShow);
        props.onChangePages('remove', { id });
      }
    } else {
      props.onChangePages('remove', { id });
    }
  };

  const handleAddPage = async () => {
    const lastPosition = list[list.length - 1].position;

    // temporary
    const newItem = {
      name: 'Page Title',
      position: lastPosition + 1,
    };
    setLoading(true);
    await props.onChangePages('add', newItem);
    setLoading(false);
  };

  const handleOnClick = (index) => {
    onChangeNavigation(index);
  };

  return (
    <div className="cms-pages-list">
      <div className="label">{labelName}</div>
      <div>
        {list.map((item, index) => (
          <PagesItem
            name={name}
            index={index}
            key={`pages-${item.id}`}
            id={item.id}
            isActive={currentPage?.id === item.id}
            pages={item}
            canMove={list.length > 1}
            canRemove={list.length > min}
            hideDeleteButton={hideDeleteButton}
            movePage={movePage}
            onDropped={onDropped}
            onRemovePage={handleRemovePage}
            onClick={handleOnClick}
            hidden={hidden && index !== 0}
          />
        ))}
      </div>
      <LightTooltip
        title={loading ? '' : i18n.t('You have reached the maximum limit of 7 pages.')}
        disableHoverListener={!disabledAddButton}
        disableTouchListener={!disabledAddButton}
        placement="bottom-start"
      >
        <span>
          <OfficialButton
            className="add-button"
            label={i18n.t('Add')}
            onClick={handleAddPage}
            variant="rectangle-primary"
            dataId={Ids.AddPagesButton}
            disabled={disabledAddButton}
            icon={loading ? <Loading /> : <AddIcon />}
          />
        </span>
      </LightTooltip>
      <div className="alert-info" style={{ marginTop: 24 }}>
        <Icons name="icon-new-info" className="icon" />
        <span>{i18n.t('Design settings apply to all pages.')}</span>
      </div>
    </div>
  );
};

PagesList.propTypes = {
  name: PropTypes.string,
  pages: PropTypes.instanceOf(Array),
  max: PropTypes.number,
  min: PropTypes.number,
  onChangePages: PropTypes.func,
  hideDeleteButton: PropTypes.bool,
  onChangePositionPages: PropTypes.func,
  labelName: PropTypes.string,
  hidden: PropTypes.bool,
};

export default PagesList;

import React, { useEffect } from 'react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import queryCache, { CacheKeys } from '../../../app/queryCache';
import {
  dismissNotification,
  sendCustomNotification,
  sendNotification,
} from '../../../commons/utils';
import { getGoogleDriveResources } from '../googleDriveService';
import { UseGoogleDriveInfiniteQueryResult } from '../types';
import i18n from '../../../i18n';
import { useIntegrationContext } from '../../../commons/IntegrationContext';
import { SERVICE_PROVIDER } from '../../integrationConstants';
import './googleDriveError.scss';
import WarningMessage from '../../../components/WarningMessage';
import ApiError from '../../../commons/ApiError';
import { Order, RowData, SelectedFolderProps } from '../../../globalResources/context/Interfaces';
import { useBreadcrumbContext } from '../../../globalResources/context/BreadcrumbsContextProvider';

export default function useGoogleDriveInfiniteQuery(
  selectedFolder: SelectedFolderProps | null,
  driveId: string | null | undefined,
  includeChildrenItemsCount: boolean,
  pageSize: number,
  order: Order,
  orderBy: keyof RowData | null,
  enabled: boolean,
  handleReconnect?: (() => void) | null | undefined
): UseGoogleDriveInfiniteQueryResult {
  const { updateBreadcrumbItem, clearBreadcrumbsData } = useBreadcrumbContext();
  const { removeConnection } = useIntegrationContext();
  const directoryId = !selectedFolder || selectedFolder.name === '_root' ? null : selectedFolder.id;

  async function handleReconnectGoogleDrive() {
    dismissNotification('googledrive_auth_error_alert');
    await removeConnection(SERVICE_PROVIDER.GOOGLE_DRIVE);
    if (handleReconnect) {
      handleReconnect();
    }
  }

  const fetchData = async ({ pageParam }: { pageParam?: number | null | undefined }) => {
    try {
      const response = await getGoogleDriveResources(
        directoryId as string,
        driveId,
        pageSize,
        pageParam,
        order,
        orderBy,
        includeChildrenItemsCount
      );
      console.log('response', response);
      if (response) {
        queryCache.setQueryData(
          [CacheKeys.getGoogleDriveResources, 'directory-info', directoryId],
          {
            name: response.name,
            parentId: response.parentId,
            id: response.id,
            driveId: response.driveId,
          }
        );
      }
      if (
        response?.children?.items?.length > 0 &&
        response?.id &&
        !response.children.items[0].parentId
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        response.children.items.forEach((item: any) => {
          Object.assign(item, { parentId: response.id });
        });
      }
      return response;
    } catch (error) {
      console.log(error);
      // sessionStorage.removeItem(getStorageCacheKey(GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE));
      clearBreadcrumbsData();
      if (error instanceof ApiError) {
        const innerError = error?.error;
        const retryErrorCode = ['10401', '10404'];

        if (retryErrorCode.includes(innerError?.code)) {
          sendCustomNotification(
            <WarningMessage
              title="Whoops! GoogleDrive has lost connection with SP_CE"
              description="Please reconnect your GoogleDrive"
              // actionButton={renderReconnectButton()}
              buttonLabel="Reconnect"
              onClickButton={handleReconnectGoogleDrive}
            />,
            { type: 'error', duration: 20000 },
            'googledrive_auth_error_alert'
          );
        } else {
          sendNotification(
            i18n.t('Please refresh your browser and try again or contact us at support@spce.com'),
            { type: 'error' },
            i18n.t('Uh-oh! Something went wrong.')
          );
        }
      }
    }

    return { children: { items: [] } };
  };

  const googleDriveInfiniteQuery = useInfiniteQuery({
    queryKey: [
      CacheKeys.getGoogleDriveResources,
      directoryId,
      includeChildrenItemsCount,
      pageSize,
      order,
      orderBy,
    ],
    queryFn: ({ pageParam }) => fetchData({ pageParam }),
    initialPageParam: null,
    enabled: enabled,
    getNextPageParam: (lastPage) => lastPage?.children?.pageToken || null,
  });

  const directoryInfoQuery = useQuery({
    queryKey: [CacheKeys.getGoogleDriveResources, 'directory-info', directoryId],
    enabled: false,
  });

  const directoryInfoQueryData = directoryInfoQuery.data as SelectedFolderProps;

  useEffect(() => {
    if (!directoryInfoQueryData?.id) {
      return;
    }
    updateBreadcrumbItem(directoryInfoQueryData);
  }, [directoryInfoQueryData, updateBreadcrumbItem]);

  return { googleDriveInfiniteQuery };
}

import { useQuery } from '@tanstack/react-query';
import memoizeOne from 'memoize-one';
import { fetchSpaceCourseBlocks } from '../services';
import { CacheKeys } from '../../app/queryCache';

// sort ids function
const sortIds = memoizeOne((ids) => {
  return ids?.sort();
});

function useSpaceCourseBlocksQuery(ids, isPortal, enabled = true) {
  const sortedIds = sortIds(ids);
  const userSpaceCourseBlocks = useQuery({
    queryKey: [CacheKeys.courseBlocks_fetchSpaceCourseBlocks, sortedIds, isPortal],
    queryFn: async () => {
      const resp = await fetchSpaceCourseBlocks(sortedIds, isPortal);
      return resp;
    },
    enabled: enabled,
  });

  return {
    userSpaceCourseBlocks,
    isLoading: userSpaceCourseBlocks.isLoading,
    spaceBlocks: userSpaceCourseBlocks.data,
    refetch: userSpaceCourseBlocks.refetch,
  };
}

export default useSpaceCourseBlocksQuery;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export interface IIntegrationContext {
  addConnection: (data: any) => void;
  removeConnection: (provider: string) => void;
  updateLocalStorageConnections: () => void;
  hasConnection: (provider: string) => boolean;
  getProviderData: (provider: string) => any;
  providers: Array<any>;
  isLoading: boolean;
  showBlocker: () => void;
  hideBlocker: () => void;
}

export const IntegrationContext = React.createContext<IIntegrationContext>({
  addConnection: () => {},
  removeConnection: () => {},
  updateLocalStorageConnections: () => {},
  hasConnection: () => false,
  getProviderData: () => {},
  providers: [],
  isLoading: false,
  showBlocker: () => {},
  hideBlocker: () => {},
});
IntegrationContext.displayName = 'IntegrationContext';

export const useIntegrationContext = () => React.useContext(IntegrationContext);

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Popover,
  Card,
  CardActionArea,
  CardContent,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import Loading from '../../../components/Loading';
import './ItemSidePanel.scss';

export default function ItemSidePanel(props) {
  const { icon, name, popupContent, type, onClick, minHeight, subMenu } = props;
  // const classes = useStyles(styleProps);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);

  const handlePopoverOpen = (event) => {
    console.log('### handlePopoverOpen', event.target);
    setAnchorEl(event.target);
  };

  const handlePopoverClose = () => {
    console.log('### handlePopoverClose');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleOnClickOpenSubMenu = (event) => {
    setAnchorMenu(event.target);
  };

  const handleOnClick = async (componentType) => {
    if (processing) return;
    setProcessing(true);
    try {
      await onClick(componentType);
    } catch (error) {
      console.error(error);
    }
    setProcessing(false);
  };

  const handleCloseSubMenu = () => {
    setAnchorMenu(null);
  };

  const handleOnClickSubMenu = (componentType) => {
    handleOnClick(componentType);
  };

  const renderCard = () => {
    if (popupContent) {
      return (
        <Card className="designer-side-panel designer-side-panel-preview">
          <CardActionArea>
            <img src={popupContent.image} alt={popupContent.name} />
            <CardContent className="designer-side-panel-content">
              <Typography className="designer-side-panel-title" gutterBottom>
                {popupContent.name}
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                {popupContent.description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }
    return null;
  };

  // console.log('### ItemSidePanel', name, props.disabled);
  if (props.disabled) {
    return null;
  }

  return (
    <>
      <Box
        className="designer-side-panel-item"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
        <div
          className="designer-side-panel-item-content"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onClick={subMenu ? handleOnClickOpenSubMenu : () => handleOnClick(type)}
        >
          <span className={`icon ${icon}`}>
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </span>
          <Typography>{name}</Typography>
        </div>
        {processing && (
          <>
            <div className="blockui" />
            <div className="processing">
              <Loading />
            </div>
          </>
        )}
      </Box>
      <Popover
        id="mouse-over-popover"
        classes={{
          paper: 'designer-side-panel-paper',
          root: 'designer-side-panel-popover',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        sx={{
          [`& .MuiPopover-paper`]: { minHeight: minHeight ? `${minHeight}px` : 'auto' },
        }}
      >
        {renderCard()}
      </Popover>
      <Menu
        open={!!anchorMenu}
        anchorEl={anchorMenu}
        onClose={handleCloseSubMenu}
        className="designer-side-submenu"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {subMenu?.map((item) => (
          <MenuItem
            key={item.id}
            onClick={() => handleOnClickSubMenu(item.type)}
            className="designer-side-submenu-item"
          >
            <ListItemIcon>
              <i className={`icon ${item.icon}`} />
            </ListItemIcon>
            <ListItemText>
              <Typography>{item.name}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ItemSidePanel.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  popupContent: PropTypes.instanceOf(Object),
  minHeight: PropTypes.number,
  disabled: PropTypes.bool,
  subMenu: PropTypes.instanceOf(Array),
};

ItemSidePanel.defaultProps = {
  disabled: false,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeContext, { getTheme, getThemeContext } from '../theme';
import ErrorBoundary from './ErrorBoundary';
import AppFooter from '../app/AppFooter';
import AppHeader from '../app/AppHeader';
import LoadFontsOrganization from '../components/LoadFontsOrganization';
import UserDashboardProvider from '../users/UserDashboard/UserDashboardProvider';
import { AppContext } from './AppProvider';
import FeatureFlagsContextProvider from '../commons/FeatureFlags/FeatureFlagsContextProvider';
import NotificationMessages from './NotificationMessages';
import BlockScreenHandler from './BlockScreenHandler';
import currentOrganization from '../commons/CurrentOrganization';
import IntegrationConnectionHandler from '../integrations/IntegrationConnectionHandler';
import SuspendedScreen from './SuspendedScreen';

class AppContainer extends PureComponent {
  renderChildren() {
    if (this.props.isSuspended) {
      return <SuspendedScreen />;
    }
    return this.props.children;
  }
  render() {
    const { userInfo } = this.context;
    const { theme, includeDeletedFonts, isSpace } = this.props;
    // console.log('### includeDeletedFonts', includeDeletedFonts);
    // console.log('### userInfo', userInfo, portalHubConnection);

    return (
      <ThemeProvider theme={getTheme(theme)}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <LoadFontsOrganization
          isReady={!isSpace && !!userInfo?.id}
          includeDeletedFonts={includeDeletedFonts}
        />

        <ThemeContext.Provider value={getThemeContext(theme)}>
          <UserDashboardProvider>
            <FeatureFlagsContextProvider organizationId={this.props.organizationId}>
              <div className="app spp-app">
                <ErrorBoundary ctx="AppContainer">
                  <Container
                    className={`app-container ${this.props.fullScreen ? 'fullscreen' : ''}`}
                  >
                    <>
                      {(!this.props.hideHeader || this.props.isSuspended) && (
                        <AppHeader
                          logo={theme?.logoImageSrc}
                          hideNotification={this.props.isSuspended}
                        />
                      )}
                      {this.renderChildren()}
                      {!this.props.hideFooter && <AppFooter />}
                      <BlockScreenHandler />
                      {currentOrganization.isAdmin() && <IntegrationConnectionHandler />}
                    </>
                  </Container>
                </ErrorBoundary>
                <NotificationMessages />
              </div>
            </FeatureFlagsContextProvider>
          </UserDashboardProvider>
        </ThemeContext.Provider>
      </ThemeProvider>
    );
  }
}

AppContainer.propTypes = {
  children: PropTypes.element,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
  fullScreen: PropTypes.bool,
  // isMobile: PropTypes.bool,
  theme: PropTypes.shape({
    logoImageSrc: PropTypes.string,
  }),
  includeDeletedFonts: PropTypes.bool,
  organizationId: PropTypes.string,
  isSpace: PropTypes.bool,
  isSuspended: PropTypes.bool,
};

AppContainer.contextType = AppContext;

export default AppContainer;

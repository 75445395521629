import React from 'react';
import PropTypes from 'prop-types';
import PdfViewer from '../../components/PdfViewer';
import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';
import i18n from '../../i18n';
import useGetCertificateTemplateQuery from '../queries/useGetCertificateTemplateQuery';

const CertificateTemplateViewer = (props) => {
  const { id } = props;

  const getResourceForViewerResp = useGetCertificateTemplateQuery(id);

  if (getResourceForViewerResp.isFetching) {
    return <Loading className="loading" />;
  }

  const resource = getResourceForViewerResp.data;

  if (!resource?.url) {
    return (
      <ErrorMessage message={i18n.t('Sorry, this resource is not available at the moment!')} />
    );
  }

  return <PdfViewer src={resource.url} downloadable />;
};

CertificateTemplateViewer.propTypes = {
  id: PropTypes.string.isRequired,
};

CertificateTemplateViewer.defaultProps = {};

export default CertificateTemplateViewer;

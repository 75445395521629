import React, { Suspense, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from '../i18n';
import HeaderUser from './components/HeaderUser';
import queryCache from '../app/queryCache';
import eventBus from '../commons/EventBus';
import currentOrganization from '../commons/CurrentOrganization';
import { useAppContext } from '../commons/AppContext';
import { IntegrationContext } from '../commons/IntegrationContext';
import IntegrationContextProvider from '../integrations/IntegrationContextProvider';
import { SERVICE_PROVIDER } from '../integrations/integrationConstants';
import Api from '../commons/api';
import { getTheme } from '../theme';
import Loading from '../components/Loading';
import useMountEffect from '../commons/useMountEffect';
import {
  addIntegrationConnection,
  clearConnectionsCache,
} from '../integrations/integrationService';
import Widget from '../components/Widget';
import { getPipeDriveClientId } from '../deals/getClientId';

function getTitle(type) {
  switch (type) {
    case 'deals':
      return i18n.t('Deal Management');
    default:
      return '';
  }
}

function getSubTitle(type, headerSubTitleRef) {
  switch (type) {
    case 'deals':
      return <div ref={headerSubTitleRef}></div>;
    default:
      return '';
  }
}

const SettingsPage = (props) => {
  const { handleDrawerToggle, isOpen, type } = props;
  const appContext = useAppContext();
  const headerSubTitleRef = useRef(null);
  const [initialState, setInitialState] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useMountEffect(() => {
    setInitialState(location.state || {});
    if (location.state) {
      navigate(`${location.pathname}`, { replace: true });
    }
  });

  console.log('###initialState', initialState);

  function renderContent() {
    if (!initialState) {
      return <Loading />;
    }
    switch (type) {
      case 'deals':
        return (
          <IntegrationContextProvider showLoading>
            <IntegrationContext.Consumer>
              {(ctx) => (
                <Widget
                  id="DmsSettings"
                  packageId="@sp-ce/spp-widget-dms/settings"
                  clientId={getPipeDriveClientId()}
                  theme={getTheme()}
                  queryCache={queryCache}
                  eventBus={eventBus}
                  openReconnectDialog={initialState.reconnect}
                  subDomain={currentOrganization.getSubDomain()}
                  appContext={appContext}
                  integrationContext={ctx}
                  provider={SERVICE_PROVIDER.PIPE_DRIVE}
                  api={Api}
                  headerSubTitleRef={headerSubTitleRef}
                  addIntegrationConnection={addIntegrationConnection}
                  clearConnectionsCache={clearConnectionsCache}
                />
              )}
            </IntegrationContext.Consumer>
          </IntegrationContextProvider>
        );
      default:
        return null;
    }
  }

  return (
    <div className={`settings-page ${type}`}>
      <HeaderUser
        onClick={handleDrawerToggle}
        isOpen={isOpen}
        title={getTitle(type)}
        subTitle={getSubTitle(type, headerSubTitleRef)}
      />
      <Suspense fallback={<Loading />}>{renderContent()}</Suspense>
    </div>
  );
};

SettingsPage.propTypes = {
  handleDrawerToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  type: PropTypes.string,
};

export default SettingsPage;

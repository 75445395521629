import React, { useEffect } from 'react';

const TestScreen = () => {
  useEffect(() => {
    try {
      throw new Error('Test');
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <div>
      <h1>Test Screen</h1>
      <div id="test-widget" />
    </div>
  );
};

export default TestScreen;

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import './ChannelList.scss';
import ChannelItem from './ChannelItem';

function ChannelList({ channels, onSelectChannel, currentUserInfoId, updateChannel }) {
  function renderChannelItem(channel) {
    return (
      <ChannelItem
        key={channel.id}
        channel={channel}
        currentUserInfoId={currentUserInfoId}
        onSelectChannel={onSelectChannel}
        updateChannel={updateChannel}
      />
    );
  }

  return (
    <div className="channel-list-container styled-scrollbar">
      {channels.length > 0 && channels.map((channel) => renderChannelItem(channel))}
      {channels.length === 0 && (
        <div className="loading-container">
          <p>{i18n.t('No private chat')}</p>
        </div>
      )}
    </div>
  );
}

ChannelList.propTypes = {
  currentUserInfoId: PropTypes.number,
  onSelectChannel: PropTypes.func,
  channels: PropTypes.instanceOf(Array),
  updateChannel: PropTypes.func,
};

export default ChannelList;

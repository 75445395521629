import React, { useState, useEffect, useCallback, Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isBoolean } from 'lodash';
import CmsCheckbox from '../../commons/CmsCheckbox';
import i18n from '../../../i18n';
import {
  LAYOUT_COMPONENT,
  LAYOUT_FORM_COMPONENT,
  CMS_COMPONENT_TYPE,
  CMS_COMPONENT_STYLE,
} from '../../cmsConstants';
import { useThemeSettingsContext } from '../context/ThemeSettingsContextProvider';
import WidgetColumnPicker from '../WidgetColumnPicker';
import CmsButtonGroup from '../../commons/CmsButtonGroup';
import TextAlignment from '../../commons/TextAlignment';
import CmsSelect from '../../commons/CmsSelect';
import ColumnList from '../../commons/CmsColumnList';
import ColumnsTab from '../../commons/ColumnsTab';
import { getVideoLayoutFormByTextPosition } from '../../cmsUtils';
import PagesList from '../../commons/CmsPagesList';
import { handleAddPage, handleDeletePage } from '../../cmsServices';
import CourseBlocks from '../../../course/cms/CourseBlocks';
import CourseBlock from '../../../course/cms/CourseBlock';
import CmsHideBlock from '../../commons/CmsHideBlock';

const DealWidgetFieldsSetting = React.lazy(() => import('../../../deals/DealWidgetFieldsSetting'));

function LayoutForm(props) {
  const { layout, onChange, defaultTab, isMobileMode } = props;
  const { selectedBlock, setWorkingData } = useThemeSettingsContext();

  const [selectedColumnIndex, setSelectedColumnIndex] = useState(0);
  useEffect(() => {
    setSelectedColumnIndex(defaultTab);
  }, [defaultTab]);
  const type = selectedBlock?.items?.[0]?.type || selectedBlock?.type;
  const isVideoComponent = type === CMS_COMPONENT_TYPE.VIDEO;
  let items = selectedBlock?.items ?? [];
  if (type === CMS_COMPONENT_TYPE.CAROUSEL) {
    items = selectedBlock?.items[0]?.items ?? [];
  }
  console.log('formComponents', items);

  const {
    showHeading,
    showDescription,
    showImage,
    showButton,
    showSecondaryButton,
    columns,
    opacity,
    size,
    textAlign,
    imageRatio,
    imageAlign,
    numberOfWidgets,
    numberOfSlides,
    textPosition,
    autoplay,
  } = layout;

  function handleOnChangeShowHeading(event) {
    onChange('showHeading', event.target.checked, selectedColumnIndex);
  }

  function handleOnChangeShowDescription(event) {
    onChange('showDescription', event.target.checked, selectedColumnIndex);
  }

  function handleOnChangeShowImage(event) {
    onChange('showImage', event.target.checked, selectedColumnIndex);
  }

  function handleOnChangeAutoplay(event) {
    onChange('autoplay', event.target.checked, selectedColumnIndex);
  }

  function handleOnChangeShowButton(event) {
    onChange('showButton', event.target.checked, selectedColumnIndex);
  }

  function handleOnChangeShowSecondaryButton(event) {
    onChange('showSecondaryButton', event.target.checked, selectedColumnIndex);
  }

  const handleOnChangeColumn = useCallback(
    (action, value) => {
      if (action === 'remove') {
        if (value === selectedColumnIndex || value === items.length - 1) {
          setSelectedColumnIndex(0);
          setWorkingData({ contentType: type, currentTab: 0 });
        }
      }
      onChange('columns', { action, value });
    },
    [onChange, selectedColumnIndex, items?.length, type, setWorkingData]
  );

  const handleOnChangePage = useCallback(
    async (action, value) => {
      const pageBlock = cloneDeep(selectedBlock?.items[0]);
      let newPage;
      if (action === 'remove') {
        const foundPage = pageBlock?.pages?.find((page) => page.id === value.id);
        if (foundPage) {
          handleDeletePage(props.spaceId, pageBlock.id, value.id);
          onChange('pages', { action, value });
        }
      } else if (action === 'add') {
        try {
          newPage = await handleAddPage(props.spaceId, pageBlock.id, value);
          onChange('pages', { action, value: newPage });
        } catch (error) {
          console.log('error', error);
        }
      }
      return true;
    },
    [onChange, props.spaceId, selectedBlock?.items]
  );

  function handleOnChangeSize(value) {
    onChange('size', value);
  }

  function handleOnChangeTextAlignment(value) {
    onChange('textAlign', value, selectedColumnIndex);
  }

  function handleOnChangeNumberOfWidgets(value) {
    onChange('numberOfWidgets', value);
  }

  function handleOnChangeOverlayOpacity(value) {
    onChange('opacity', value, selectedColumnIndex);
  }

  function handleOnChangeImageRatio(value) {
    onChange('imageRatio', value);
  }

  function handleOnChangeImageAlignment(value) {
    onChange('imageAlign', value);
  }

  function handleOnChangeVideoLayout(value) {
    onChange('layout', value);
  }

  function handleOnChangeVideoAlignment(value) {
    onChange('videoAlign', value);
  }

  function handleOnChangeTextPosition(value) {
    onChange('textPosition', value, selectedColumnIndex);
  }

  function handleOnChangePositionColumn(newItems) {
    onChange('columnPosition', newItems);
  }

  function handleOnChangePositionPages(pages) {
    onChange('pagePosition', pages);
  }

  function handleOnChangeBlocks(blocks) {
    onChange('courseBlocks', blocks);
  }
  function handleOnChangeCourses(courses) {
    onChange('courses', courses);
  }
  function handleOnChangeDealFields(list) {
    onChange('fields', list);
  }

  function handleOnChangeSliders(action, value) {
    if (action === 'remove') {
      if (value === selectedColumnIndex || value === items.length - 1) {
        setSelectedColumnIndex(0);
        setWorkingData({ contentType: type, currentTab: 0 });
      }
    }
    onChange('sliders', { action, value });
  }

  function handleSelectColumnTab(value) {
    setSelectedColumnIndex(value);
    setWorkingData({ contentType: type, currentTab: value });
  }

  function handleOnChangeHidden(value) {
    onChange('hidden', value);
  }

  function renderComponentItem(component) {
    const isOverlay =
      !selectedBlock?.layout || selectedBlock?.layout === CMS_COMPONENT_STYLE.VIDEO_LAYOUT.OVERLAY;
    const canShowDescription = !(isOverlay && isVideoComponent && columns >= 3);
    const canShowSize = columns === 1; // || type === CMS_COMPONENT_TYPE.WIDGET;
    const canShowAutoplay = isVideoComponent;
    const hideDivider = type === CMS_COMPONENT_TYPE.WEB_CONTENT || isVideoComponent;
    const pages = selectedBlock?.items[0].pages;
    const convertHiddenOldData = {
      [CMS_COMPONENT_STYLE.HIDDEN.DESKTOP]: selectedBlock?.hidden,
      [CMS_COMPONENT_STYLE.HIDDEN.MOBILE]: selectedBlock?.hidden,
    };
    const defaultHidden = !isBoolean(selectedBlock?.hidden)
      ? selectedBlock?.hidden
      : convertHiddenOldData;

    let element = null;
    switch (component.component) {
      // case LAYOUT_COMPONENT.POSITION:
      //   element = (
      //     <ComponentPosition
      //       key={component.id}
      //       onClickArrowUp={moveUp}
      //       onClickArrowDown={moveDown}
      //       disableArrowDown={!canMoveDown()}
      //       disableArrowUp={!canMoveUp()}
      //     />
      //   );
      //   break;
      case LAYOUT_COMPONENT.COLUMN:
        element = (
          <ColumnList
            name={`ColumnList ${component.id} ${type}`}
            key={`ColumnList ${component.id}`}
            hideDivider={hideDivider}
            label={i18n.t('Column')}
            labelName={i18n.t('Number of columns')}
            columns={columns}
            onChangePositionColumn={handleOnChangePositionColumn}
            onChangeColumn={handleOnChangeColumn}
            max={component.settings.max}
            min={component.settings.min}
          />
        );
        break;
      case LAYOUT_COMPONENT.SELECT_COLUMN:
        element = (
          <>
            {columns > 1 && !component?.settings?.hide && (
              <ColumnsTab
                key={`ColumnsTab ${component.id}`}
                columns={columns}
                selectedColumn={selectedColumnIndex}
                setSelectedColumn={handleSelectColumnTab}
              />
            )}
          </>
        );
        break;
      case LAYOUT_COMPONENT.SLIDE:
        element = (
          <ColumnList
            name={`ColumnList ${component.id} ${type}`}
            key={`ColumnList ${component.id}`}
            label={i18n.t('Slider')}
            labelName={i18n.t('Number of slides')}
            columns={numberOfSlides}
            onChangePositionColumn={handleOnChangePositionColumn}
            onChangeColumn={handleOnChangeSliders}
            max={component.settings.max}
            min={component.settings.min}
            hideDeleteButton={numberOfSlides === component.settings.min}
          />
        );
        break;
      case LAYOUT_COMPONENT.SHOW_HEADING:
        element = (
          <CmsCheckbox
            key={component.id}
            checked={showHeading}
            onChange={handleOnChangeShowHeading}
            label={i18n.t('Show heading')}
            name="ShowHeading"
          />
        );
        break;

      case LAYOUT_COMPONENT.SHOW_DESCRIPTION:
        element = canShowDescription && (
          <CmsCheckbox
            key={component.id}
            checked={showDescription}
            onChange={handleOnChangeShowDescription}
            label={i18n.t('Show description')}
            name="ShowDescription"
          />
        );
        break;
      case LAYOUT_COMPONENT.AUTOPLAY:
        element = canShowAutoplay && (
          <CmsCheckbox
            key={component.id}
            checked={autoplay}
            onChange={handleOnChangeAutoplay}
            label={i18n.t('Autoplay video')}
            name="autoplay"
          />
        );
        break;
      case LAYOUT_COMPONENT.SHOW_BUTTON:
        element = (
          <CmsCheckbox
            key={component.id}
            checked={showButton}
            onChange={handleOnChangeShowButton}
            label={i18n.t('Show primary button')}
            name="ShowButton"
          />
        );
        break;
      case LAYOUT_COMPONENT.SHOW_SECONDARY_BUTTON:
        element = (
          <CmsCheckbox
            key={component.id}
            checked={showSecondaryButton}
            onChange={handleOnChangeShowSecondaryButton}
            label={i18n.t('Show secondary button')}
            name="ShowSecondaryButton"
          />
        );
        break;
      case LAYOUT_COMPONENT.SHOW_IMAGE:
        element = (
          <CmsCheckbox
            key={component.id}
            checked={showImage}
            onChange={handleOnChangeShowImage}
            label={i18n.t('Show image')}
            name="ShowImage"
          />
        );
        break;
      case LAYOUT_COMPONENT.SIZE: {
        element = canShowSize && (
          <CmsSelect
            key={component.id}
            label={component.settings.label}
            options={component.settings.options}
            value={size}
            onChange={handleOnChangeSize}
          />
        );
        break;
      }
      case LAYOUT_COMPONENT.ALIGNMENT: {
        element = (
          <TextAlignment
            key={component.id}
            alignmentList={component.settings.options}
            value={textAlign}
            onChange={handleOnChangeTextAlignment}
          />
        );
        break;
      }
      case LAYOUT_COMPONENT.WIDGET:
        element = (
          <WidgetColumnPicker
            key={component.id}
            size={size}
            value={numberOfWidgets}
            onChange={handleOnChangeNumberOfWidgets}
          />
        );
        break;
      case LAYOUT_COMPONENT.OVERLAY_OPACITY:
        element = (
          <CmsButtonGroup
            key={component.id}
            options={component.settings.options}
            label={i18n.t('Overlay opacity')}
            onChange={handleOnChangeOverlayOpacity}
            value={opacity}
          />
        );
        break;
      case LAYOUT_COMPONENT.IMAGE_RATIO:
        element = (
          <CmsButtonGroup
            key={component.id}
            options={component.settings.options}
            label={i18n.t('Image ratio')}
            onChange={handleOnChangeImageRatio}
            value={imageRatio}
          />
        );
        break;
      case LAYOUT_COMPONENT.IMAGE_ALIGMENT:
        element = (
          <CmsButtonGroup
            key={component.id}
            options={component.settings.options}
            label={component.settings.label}
            onChange={handleOnChangeImageAlignment}
            value={imageAlign}
          />
        );
        break;
      case LAYOUT_COMPONENT.TEXT_POSITION: {
        element = (
          <TextAlignment
            key={component.id}
            isCarousel
            alignmentList={component.settings.options}
            value={textPosition}
            onChange={handleOnChangeTextPosition}
          />
        );
        break;
      }
      case LAYOUT_COMPONENT.VIDEO_LAYOUT: {
        element = (
          <>
            <CmsSelect
              key={component.id}
              label={component.settings.label}
              options={component.settings.options}
              value={layout.layout}
              onChange={handleOnChangeVideoLayout}
            />
            <div className="divider" />
          </>
        );
        break;
      }
      case LAYOUT_COMPONENT.VIDEO_ALIGMENT:
        element = (
          <CmsButtonGroup
            key={component.id}
            options={component.settings.options}
            label={component.settings.label}
            onChange={handleOnChangeVideoAlignment}
            value={imageAlign}
          />
        );
        break;
      case LAYOUT_COMPONENT.PAGES:
        element = (
          <PagesList
            name={`PagesList ${component.id} ${type}`}
            key={`PagesList ${component.id}`}
            hideDivider={hideDivider}
            labelName={i18n.t('Number of pages')}
            pages={pages}
            onChangePages={handleOnChangePage}
            onChangePositionPages={handleOnChangePositionPages}
            max={component.settings.max}
            min={component.settings.min}
            hidden={selectedBlock.hidden}
          />
        );
        break;

      case LAYOUT_COMPONENT.COURSE_BLOCK:
        element = (
          <CourseBlock
            key={`Block ${component.id}`}
            data={selectedBlock?.items[0]?.courseBlocks}
            onChange={handleOnChangeBlocks}
          />
        );
        break;
      case LAYOUT_COMPONENT.COURSE:
        element = (
          <CourseBlocks
            key={`Course ${component.id}`}
            data={selectedBlock?.items[0]?.courses}
            onChange={handleOnChangeCourses}
          />
        );
        break;
      case LAYOUT_COMPONENT.FIELDS:
        element = (
          <Suspense fallback={<div>Loading...</div>}>
            <DealWidgetFieldsSetting
              key={`Deals ${component.id}`}
              fields={selectedBlock?.items[0]?.fields}
              onChange={handleOnChangeDealFields}
              disabled={isMobileMode}
            />
          </Suspense>
        );
        break;
      case LAYOUT_COMPONENT.HIDDEN:
        element = (
          <>
            <div className="divider" />
            <CmsHideBlock
              key={component.id}
              options={component.settings.options}
              label={component.settings.label}
              onChange={handleOnChangeHidden}
              value={defaultHidden}
            />
          </>
        );
        break;
      default:
        break;
    }
    return <Fragment key={component.id}>{element}</Fragment>;
  }

  let formComponents = LAYOUT_FORM_COMPONENT[type];
  if (isVideoComponent) {
    formComponents = getVideoLayoutFormByTextPosition(textPosition);
  }
  return (
    <div className="cms-editing-component-panel-tabpanel-content">
      {formComponents?.map((component) => renderComponentItem(component))}
    </div>
  );
}

LayoutForm.propTypes = {
  onChange: PropTypes.func,
  defaultTab: PropTypes.number,
  layout: PropTypes.shape({
    showHeading: PropTypes.bool,
    showDescription: PropTypes.bool,
    showButton: PropTypes.bool,
    showSecondaryButton: PropTypes.bool,
    columns: PropTypes.number,
    opacity: PropTypes.number,
    size: PropTypes.string,
    textAlign: PropTypes.string,
    imageRatio: PropTypes.string,
    imageAlign: PropTypes.string,
    numberOfWidgets: PropTypes.number,
    numberOfSlides: PropTypes.number,
    textPosition: PropTypes.string,
    autoplay: PropTypes.bool,
    layout: PropTypes.string,
    showImage: PropTypes.bool,
  }),
  spaceId: PropTypes.string,
  isMobileMode: PropTypes.bool,
};

export default LayoutForm;

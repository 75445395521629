import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DeviceUUID } from 'device-uuid';
import { PrivacySpace } from '../app/appConstants';
import { CacheKeys } from '../app/queryCache';
import { fetchSpaceAcessInfo } from './spaceAccessServices';
import { mappingMLSDataBlocks } from '../cms/cmsUtils';

function getVisitorId(ipAdrress) {
  const du = new DeviceUUID().parse();
  const dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen,
    ipAdrress,
  ];
  return du.hashMD5(dua.join(':'));
}

export function getDeviceId() {
  const du = new DeviceUUID().parse();
  const dua = [
    du.language,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen,
  ];
  return du.hashMD5(dua.join(':'));
}

const useSpaceAcessInfoQuery = (spaceUrlSlug) => {
  const getSpaceAcessInfoQuery = useQuery({
    queryKey: [CacheKeys.fetchSpaceAcessInfo, spaceUrlSlug],
    queryFn: async () => {
      const resp = await fetchSpaceAcessInfo(spaceUrlSlug);
      if (resp?.space) {
        resp.space = mappingMLSDataBlocks(resp.space);
      }
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: true,
    refetchOnMount: 'always',
  });

  const visitorId = useMemo(() => {
    if (getSpaceAcessInfoQuery.data) {
      const { space, ipAdrress } = getSpaceAcessInfoQuery.data;
      const isPublicSpace = space?.spacePrivacy === PrivacySpace.PUBLIC;
      if (isPublicSpace) {
        return getVisitorId(ipAdrress);
      }
    }
    return null;
  }, [getSpaceAcessInfoQuery.data]);

  return { getSpaceAcessInfoQuery, visitorId };
};

export default useSpaceAcessInfoQuery;

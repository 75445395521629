import i18n, { init } from 'i18next';
import en from './i18n/en';
import sv from './i18n/sv';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
};

init({
  resources,
  lng: navigator.language || navigator.userLanguage,
  fallbackLng: 'en-US',

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export const Trans = (props) => {
  return props.children;
};

export default i18n;

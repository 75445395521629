import React, { useEffect, useMemo, useRef } from 'react';

import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import spaceUser from '../spaces/spaceUser';
import { useSpaceContext } from '../spaces/SpaceContext';
import { useAppContext } from '../components/AppProvider';
import queryCache from '../app/queryCache';
import useSpaceUsersQuery from '../spaces/SpaceUsers/useSpaceUsersQuery';
import eventBus from '../commons/EventBus';
import ErrorBoundary from '../components/ErrorBoundary';
import OfficialButton from '../components/OfficialButtons';
import i18n from '../i18n';
import { findBlockByType } from '../cms/cmsUtils';
import Api from '../commons/api';
import RuntimeApi from '../commons/RuntimeApi';
import Loading from '../components/Loading';
import Widget from '../components/Widget';

const DealsManagementContainer = (props) => {
  const {
    fields,
    widgetName,
    defaultCurrency,
    defaultPipelineId,
    defaultCompanyId,
    isEdit,
    isMobile,
    pipelineName,
    companyName,
  } = props;
  const spaceContext = useSpaceContext();
  const { scrollToBlockId, space } = spaceContext;
  const hasLoggedIn = spaceUser.hasLoggedIn();
  const spaceAccessToken = spaceUser.getAccessToken();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const deletedDealsDialogRef = useRef(null);
  const dealDetailDialogRef = useRef(null);
  const deletedDealIds = location.state?.deletedDealIds;
  const locationDealId = location.state?.dealId;
  const blockType = location?.state?.blockType;

  const selectedFieldIds = useMemo(() => {
    if (!fields) {
      return [];
    }
    // check if fields is array of string
    if (fields.every((field) => typeof field === 'string')) {
      return fields;
    }
    return fields.map((field) => field.name);
  }, [fields]);

  // Scroll To block type when click on notification message button
  useEffect(() => {
    // delete location state
    if (blockType && space) {
      const block = findBlockByType(space, blockType);
      if (block) {
        scrollToBlockId(
          {
            cmsBlockId: block.id,
            cmsPagesId: block.pageId,
          },
          !!space.pagesBlock
        );
        console.log('location.state deletedDealIds 2', deletedDealIds);
        if (deletedDealIds) {
          deletedDealsDialogRef.current?.show(deletedDealIds);
        }
        if (locationDealId) {
          dealDetailDialogRef.current?.show(locationDealId);
        }
        navigate(`${location.pathname}`, {
          replace: true,
        });
      }
    }
  }, [
    blockType,
    space,
    locationDealId,
    scrollToBlockId,
    navigate,
    location.pathname,
    deletedDealIds,
  ]);

  const appContext = useAppContext();
  const getSpaceUsersQuery = useSpaceUsersQuery(spaceContext.space.id, false);
  const { data: spaceUsers } = getSpaceUsersQuery;

  if (!spaceUsers) {
    return (
      <div style={{ display: 'flex', minHeight: '100px' }}>
        <Loading />
      </div>
    );
  }

  const actions = (
    <OfficialButton
      variant="regular-primary"
      label={i18n.t('Reload')}
      onClick={() => location.reload()}
      fullWidth
    />
  );
  const isPortal = appContext.isPortal;
  const currency = spaceContext.space?.dealSettings?.currency?.currencyCode || defaultCurrency;
  const companyId = spaceContext.space?.dealSettings?.companyId || defaultCompanyId;
  const pipelineId = spaceContext.space?.dealSettings?.pipelineId || defaultPipelineId;
  return (
    <ErrorBoundary ctx="DMS" actions={actions}>
      <Widget
        id="DmsWidget"
        packageId="@sp-ce/spp-widget-dms/widget"
        theme={theme}
        selectedFieldIds={selectedFieldIds}
        spaceUsers={spaceUsers}
        queryCache={queryCache}
        spaceContext={spaceContext}
        appContext={appContext}
        currency={currency}
        eventBus={eventBus}
        widgetName={widgetName}
        isSelectable={hasLoggedIn}
        isEditMode={isEdit}
        spaceAccessToken={spaceAccessToken}
        isMobile={isMobile}
        deletedDealsDialogRef={deletedDealsDialogRef}
        dealDetailDialogRef={dealDetailDialogRef}
        isPortal={isPortal}
        pipelineId={pipelineId}
        companyId={companyId}
        pipelineName={pipelineName}
        companyName={companyName}
        api={isPortal ? Api : RuntimeApi}
      />
    </ErrorBoundary>
  );
};

DealsManagementContainer.propTypes = {
  fields: PropTypes.instanceOf(Array),
  widgetName: PropTypes.string,
  defaultCurrency: PropTypes.string,
  defaultPipelineId: PropTypes.string,
  defaultCompanyId: PropTypes.string,
  pipelineName: PropTypes.string,
  companyName: PropTypes.string,
  isEdit: PropTypes.bool,
  isMobile: PropTypes.bool,
};
export default DealsManagementContainer;

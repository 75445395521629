export class LocalUserInfo {
  constructor() {
    this.data = null;
    // console.log('Reading data from local storage...');
    const data = localStorage.getItem('localUserInfo');
    console.log('localUserInfo data:', data);
    if (data) {
      const jsonData = JSON.parse(data);
      this.data = jsonData;
    } else {
      this.data = {};
    }
  }

  getUserInfo() {
    return this.data;
  }

  setUserInfo(data) {
    this.data = data;
    localStorage.setItem('localUserInfo', JSON.stringify(this.data));
  }

  reset() {
    this.data = null;
    localStorage.removeItem('localUserInfo');
  }
}

const localUserInfo = new LocalUserInfo();

export default localUserInfo;

import React from 'react';
import i18n from '../../i18n';
import { getAppUrl } from '../../commons/utils';

function isSameOrigin() {
  try {
    console.log('window.top.location.href', window.top.location.href);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
}

function SilentLoginCallback() {
  React.useEffect(() => {
    const url = window.location.href;
    if (window.self !== window.top && isSameOrigin()) {
      console.log('window.parent', window.parent);
      window.parent.postMessage({ source: 'silent-login-callback', payload: { url } }, getAppUrl());
    }
  }, []);

  return <div className="authorization-container">{i18n.t('Processing login')}</div>;
}

export default SilentLoginCallback;

import Api from '../commons/api';
import { getFeatureFlagApi } from '../commons/utils';
import { FeaturePromo, PromoPopupPayload, PromoRequestResponse } from './types';

export async function getPromoList(): Promise<Array<FeaturePromo>> {
  return Api.get(`${getFeatureFlagApi()}/api/feature-promos/requests`).then((res) => res?.items);
}

export async function savePromoRequest(
  featureRequestId: string,
  data: PromoPopupPayload
): Promise<PromoRequestResponse> {
  return Api.put(
    `${getFeatureFlagApi()}/api/feature-promos/requests/${featureRequestId}/status`,
    data
  );
}

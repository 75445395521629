import React from 'react';
import { Grid2 } from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../i18n';
import ResponsiveDialog from './ResponsiveDialog';
import OfficialButton from './OfficialButtons';
import './ConfirmationDialog.scss';

const ConfirmationDialog = ({
  className = '',
  isProcessing,
  isDisabled = false,
  title,
  message,
  children,
  onClickCancel,
  onClickOK,
  okLabel,
  cancelLabel,
  icon = null,
  showCloseIcon = true,
  onClickCloseButton,
  dataIds,
}) => {
  const renderFooter = () => {
    if (!okLabel && !cancelLabel) return null;

    const buttons = [];

    if (cancelLabel) {
      buttons.push(
        <OfficialButton
          dataId={dataIds?.cancel}
          key="cancelButton"
          onClick={onClickCancel}
          variant="large-secondary"
          label={cancelLabel}
        />
      );
    }

    if (okLabel) {
      buttons.push(
        <OfficialButton
          key="okButton"
          dataId={dataIds?.ok}
          disabled={isProcessing || isDisabled}
          onClick={onClickOK}
          variant="large-primary"
          label={isProcessing ? i18n.t('Processing...') : okLabel}
        />
      );
    }

    return <div className="buttons">{buttons}</div>;
  };

  const handleOnclickCloseButton = () => {
    if (onClickCloseButton) {
      onClickCloseButton();
      return;
    }
    onClickCancel();
  };

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className={`${className} white confirm-dialog`}
      onClose={handleOnclickCloseButton}
      showCloseIcon={showCloseIcon}
      dataIds={dataIds}
    >
      <>
        <Grid2 className="content" container direction="column">
          {icon && <span className="integration-icon">{icon}</span>}
          {title && <h1 className="title">{title}</h1>}
          {message && <p>{message}</p>}
          {children}
        </Grid2>
        {renderFooter()}
      </>
    </ResponsiveDialog>
  );
};

ConfirmationDialog.propTypes = {
  className: PropTypes.string,
  isProcessing: PropTypes.bool,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.instanceOf(Object),
  onClickCancel: PropTypes.func,
  onClickOK: PropTypes.func,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  showCloseIcon: PropTypes.bool,
  onClickCloseButton: PropTypes.func,
  dataIds: PropTypes.instanceOf(Object),
};

export default ConfirmationDialog;

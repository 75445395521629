/* eslint prefer-object-spread: 0 */
import ApiError from '../commons/ApiError';
import currentOrganization from '../commons/CurrentOrganization';
import { sendNotification } from './utils';
import i18n from '../i18n';
import { ExceptionTypes } from '../app/appConstants';

export function errorHandling(error) {
  console.log('ERROR errorHandling', error);
  if (error instanceof Error) {
    return error;
  }
  try {
    if (error.errors && Array.isArray(error.errors)) {
      return error.errors.map((e) => new Error(e.message));
    }
    if (error.message) {
      return new Error(error.message);
    }
    return error;
  } catch (e) {
    console.error(e);
    return error;
  }
}

class Api {
  static async headers(initHeaders = {}) {
    const date = new Date();
    const headers = Object.assign(initHeaders || {}, {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF': '1',
      'x-spce-timezone-offset': date.getTimezoneOffset(),
    });

    const organization = currentOrganization.getData();
    if (organization) {
      Object.assign(headers, {
        organizationId: organization?.id,
      });
    }

    return headers;
  }

  static get(route, params, headers) {
    let url = route;
    if (params) {
      url = `${url}?${this.encodeData(JSON.parse(JSON.stringify(params)))}`;
    }
    return this.xhr(url, null, headers, 'GET');
  }

  static put(route, params, headers) {
    return this.xhr(route, params, headers, 'PUT');
  }

  static patch(route, params, headers) {
    return this.xhr(route, params, headers, 'PATCH');
  }

  static post(route, params, headers, isEncodeData, isFormData, withCredentials = false) {
    return this.xhr(route, params, headers, 'POST', isEncodeData, isFormData, withCredentials);
  }

  static delete(route, params, headers) {
    return this.xhr(route, params, headers, 'DELETE');
  }

  static encodeData(data) {
    return Object.keys(data)
      .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
      .join('&');
  }

  static async xhr(
    url,
    params,
    headers,
    verb,
    isEncodeData = false,
    isFormData = false,
    withCredentials = false,
    signal = null // to abort the request if needed
  ) {
    const formData = isFormData ? params : JSON.stringify(params);
    const options = Object.assign(
      { method: verb, signal },
      params ? { body: isEncodeData ? this.encodeData(params) : formData } : null
    );
    options.headers = await Api.headers(headers);
    if (withCredentials === true) {
      options.credentials = 'include';
    }
    const resp = await fetch(url, options);
    if (!resp.ok) {
      if (resp.status === 500) {
        sendNotification(
          i18n.t('Please refresh your browser and give it another try.'),
          { type: 'error' },
          i18n.t('Uh-oh! Something went wrong.')
        );
        throw new Error(ExceptionTypes.SubscriptionQuotaReached);
      }
      if (resp.status === 403) {
        const error = await resp.json();
        if (error.type === 'UserNotFoundException') {
          throw new Error(ExceptionTypes.UserNotFoundException);
        }
      }
      if (resp.status === 401) {
        throw new ApiError(
          { message: 'Unauthorized', type: 'Unauthorized', error: 'Unauthorized' },
          401
        );
      }
      const error = await resp.json();
      if (error.type === ExceptionTypes.FeatureNotEnabledException) {
        sessionStorage.removeItem('FeatureFlagsOrg');
        // showNotifyNotEnabledFeature.show(error);
      } else if (error.type === ExceptionTypes.SubscriptionQuotaReached) {
        // handleShowSubscriptionPopup(error);
        throw new Error(ExceptionTypes.SubscriptionQuotaReached);
      } else {
        throw new ApiError(error, resp.status);
      }
      // if (resp.status === 401) {
      //   if (process.env.REACT_APP_ENV !== 'local') {
      //     if (url.indexOf('/connect/userinfo') >= 0) {
      //       await authService.signOut();
      //     } else if (url.indexOf('/bff/user') === -1) {
      //       await authService.signIn(location.pathname);
      //     }
      //   }
      // } else {
      // }
    }
    const contentType = resp.headers.get('content-type');
    // console.log('contentType', contentType);
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return resp.json();
    }

    if (
      contentType &&
      (contentType.indexOf('application/pdf') !== -1 ||
        contentType.indexOf('text/calendar') !== -1 ||
        contentType.indexOf('application/zip') !== -1 ||
        contentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !==
          -1)
    ) {
      return resp.blob();
    }
    return resp.text();
  }

  static upload(url, file, onProgressCallback, signal = null) {
    if (signal && signal.aborted) {
      return Promise.reject(new DOMException('Aborted', 'AbortError'));
    }
    return new Promise((resolve, reject) => {
      const body = new FormData();
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      const headers = {
        'X-CSRF': '1',
      };

      const organization = currentOrganization.getData();
      if (organization) {
        Object.assign(headers, {
          organizationId: organization?.id,
        });
      }

      Object.keys(headers).forEach((key) => {
        xhr.setRequestHeader(key, headers[key]);
      });
      body.append('file', file);
      if (file.sendProgresReport !== undefined) {
        body.append('sendProgressReport', file.sendProgresReport);
      }
      if (file.useFileName !== undefined) {
        body.append('useFileName', file.useFileName);
      }

      if (file.correlatedObjectId !== undefined) {
        body.append('correlatedObjectId', file.correlatedObjectId);
      }

      if (file.sendInvitation !== undefined) {
        body.append('sendInvitation', file.sendInvitation);
      }

      xhr.onload = () => {
        if (xhr.status === 200 || xhr.statusText.toLowerCase() === 'ok') {
          resolve(JSON.parse(xhr.response));
        } else {
          reject(xhr.statusText);
        }
      };
      // xhr.onerror = reject;
      xhr.onerror = (err) => {
        console.log('onerror', err);
        reject(err);
      };
      xhr.onabort = (err) => {
        console.log('onabort', err);
        reject('abort');
      };
      if (onProgressCallback) {
        xhr.upload.onprogress = onProgressCallback;
      }
      if (signal) {
        signal.addEventListener('abort', () => xhr.abort());
      }
      xhr.send(body);
    });
  }

  static async fetch(url, headers = null) {
    const options = Object.assign({ method: 'GET' });
    options.headers = {};
    options.headers = await Api.headers(headers);
    if (headers) {
      options.headers = Object.assign({}, options.headers, headers);
    }
    return fetch(url, options).then((resp) => {
      if (!resp.ok) {
        throw new Error(resp);
      }
      console.log(resp.ok);
      return resp;
    });
  }
}
export default Api;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';
import './CmsButtonGroup.scss';

const CmsButtonGroup = ({ className, options, onChange, value, label }) => {
  const handleClick = (item) => {
    if (!onChange) return;
    onChange(item);
  };

  return (
    <div className="cms-buttons-group">
      <div className="cms-buttons-group-title">{label}</div>
      <ButtonGroup
        className={`cms-buttons-group-list ${className}`}
        color="primary"
        fullWidth
        aria-label="outlined primary fullwidth button group"
      >
        {options.map((item, index) => (
          <Button
            key={index}
            onClick={() => handleClick(item)}
            classes={{
              label: `${item !== value ? 'cms-buttons-group-label' : ''}`,
              outlinedPrimary: 'cms-buttons-group-outlined-primary',
              root: `cms-buttons-group-item ${item === value ? 'active' : ''}`,
            }}
          >
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

CmsButtonGroup.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
};

export default CmsButtonGroup;

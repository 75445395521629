import React from 'react';
import PropTypes from 'prop-types';
import { fontsWithVariants, loadGoogleFonts } from './LoadFonts';
import useGoogleFontsQuery from './GoogleFontsQuery';

export const LoadGoogleFonts = React.memo(({ fonts }) => {
  const { fonts: googleFonts } = useGoogleFontsQuery();
  React.useEffect(() => {
    if (googleFonts?.length > 0) {
      let allFonts = googleFonts;
      if (fonts) {
        console.log('### 113 fonts:', fonts);
        // filter out fonts that are not in the google fonts list
        allFonts = googleFonts.filter((font) => fonts.has(font.name) || fonts.has(font.family));
      }
      const items = fontsWithVariants(allFonts);
      const validFonts = items.map((item) => item.variant);
      console.log('### 113 validFonts:', validFonts);
      // console.log('### 113 fontsWithVariants:', fonts);
      if (validFonts.length > 0) {
        loadGoogleFonts(validFonts);
      }
    }
  }, [fonts, googleFonts]);
  return null;
});

LoadGoogleFonts.propTypes = {
  fonts: PropTypes.instanceOf(Set),
};

export default LoadGoogleFonts;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Link, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Icons from '../../components/Icons';
import ResponsiveDialog from '../../components/ResponsiveDialog';
import './GoogleDriveConnectionDialog.scss';
import i18n from '../../i18n';
import {
  dismissNotification,
  getDomain,
  getGoogleDriveClientId,
  popupWindow,
  sendCustomNotification,
  setCookie,
} from '../../commons/utils';
import GoogleLogin from '../components/GoogleLogin';
import WarningMessage from '../../components/WarningMessage';
import currentOrganization from '../../commons/CurrentOrganization';
import { getGoogleDriveAuthUrl } from '../integrationUtils';

const GoogleDriveConnectionDialog = (props) => {
  const [isHidden, setIsHidden] = useState(false);

  function retryGoogleSignIn() {
    dismissNotification('googledrive_scope_error_alert');
    setIsHidden(false);
  }

  async function handleGoogleSuccess(response) {
    console.log('###response', response);
    if (response?.scope?.includes('https://www.googleapis.com/auth/drive.readonly')) {
      props.onClose(response.code);
    } else {
      setIsHidden(true);
      sendCustomNotification(
        <WarningMessage
          title="There was a problem  connecting Google Drive"
          description="Don't forget to give SP_CE consent to connect Google Drive"
          buttonLabel="Try again"
          onClickButton={retryGoogleSignIn}
        />,
        { type: 'error', duration: 20000 },
        'googledrive_scope_error_alert'
      );
    }
  }

  function handleGoogleError(error) {
    console.log(error);
  }

  function handleOnClickLogin() {
    setCookie(
      'tmpSubDomain',
      currentOrganization.getSubDomain(),
      0.5,
      getDomain(window.location.origin) ?? ''
    );

    popupWindow(getGoogleDriveAuthUrl(), i18n.t('GoogleDrive'), 700, 700);
    window.CallParentfunc = async function (isAuthenticated, accessCode, scope) {
      if (isAuthenticated) {
        handleGoogleSuccess({ code: accessCode, scope: scope });
      }
    };
  }

  const renderMessage = () => {
    return (
      <>
        <p>{i18n.t('Connect Google Drive to your SP_CE account in order to')}:</p>
        <List>
          <ListItem>
            <ListItemIcon>
              <Icons name="icon-check-circle" />
            </ListItemIcon>
            <ListItemText primary="Easily access Google Drive files directly from within a space" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Icons name="icon-check-circle" />
            </ListItemIcon>
            <ListItemText primary="Have changes made in Google Drive will be updated in the space automatically" />
          </ListItem>
        </List>
        <p>
          <Link
            href="https://www.spce.com/productutorials/connect-google-drive-to-global-resources/"
            target="_blank"
            underline="always"
            className="spce-link"
          >
            {i18n.t('Learn more about the Google Drive Integration')}
          </Link>
        </p>
        <Alert icon={<InfoIcon />} severity="warning" className="information-alert">
          Give SP_CE consent to connect Google Drive by checking the checkbox in the next step.
        </Alert>
      </>
    );
  };

  return (
    <ResponsiveDialog
      modal
      maxWidth="lg"
      className={`white confirm-dialog google-drive-connection-dialog ${isHidden ? 'hidden' : ''}`}
      onClose={props.onClose}
    >
      <>
        <Box className="content" display="flex" flexDirection="column">
          <Icons name="icon-googledrive" />
          <h1 className="title">{i18n.t('Connect Google Drive')}</h1>
          {renderMessage()}
        </Box>
        <div className="buttons">
          <GoogleLogin
            clientId={getGoogleDriveClientId()}
            scope={process.env.REACT_APP_GOOGLE_DRIVE_SCOPE}
            onSuccess={handleGoogleSuccess}
            onFailure={handleGoogleError}
            onClick={handleOnClickLogin}
            skipNativeSignIn
            onCancelled={props.onClose}
          >
            <Icons name="icon-googledrive" className="google-icon" />
            <span className="label">{i18n.t('Login to Google Drive')}</span>
          </GoogleLogin>
        </div>
      </>
    </ResponsiveDialog>
  );
};

GoogleDriveConnectionDialog.propTypes = {
  onClose: PropTypes.func,
};

export default GoogleDriveConnectionDialog;

import React from 'react';
import PropTypes from 'prop-types';
import { Add as AddIcon } from '@mui/icons-material';
import OfficialButton from './OfficialButtons';

function AddButton(props) {
  const [processing, setProcessing] = React.useState(false);

  async function onClick(e) {
    setProcessing(true);
    try {
      await props.onClick(e);
      setProcessing(false);
    } catch (error) {
      console.log('error', error);
      setProcessing(false);
    }
  }

  return (
    <OfficialButton
      toolTipMessage={props.tooltip}
      dataId={props.dataId}
      icon={<AddIcon />}
      label={props.label}
      disabled={props.disabled}
      className={props.className ?? ''}
      onClick={onClick}
      variant={props.variant || 'rectangle-green'}
      isProcessing={processing}
    />
  );
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  dataId: PropTypes.string,
  tooltip: PropTypes.string,
};

export default AddButton;

import React, { useCallback, useState, useMemo } from 'react';
import { IconButton, Divider } from '@mui/material';
import { isChrome, isEdge } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import './SpaceHeader.scss';
import LoginMenu from '../../../components/LoginMenu';
import ShareLinkButton from '../../../components/ShareLinkButton';
import { useSpaceContext } from '../../../spaces/SpaceContext';
import i18n from '../../../i18n';
import SpaceInfo from '../../../homepage/SpaceInfo';
import NotificationButton from '../../../components/Notification/NotificationButton';
import VaamButton from '../../../components/Vaam/VaamButton';
import spaceUser from '../../../spaces/spaceUser';
import { Tag } from '../../../components/Tags';
import currentOrganization from '../../../commons/CurrentOrganization';
import HomeButton from '../../../components/HomeButton';
import LightTooltip from '../../../components/LightTooltip';
import { useAppContext } from '../../../components/AppProvider';
import { getSignInUrl } from '../../../commons/utils';
import { Ids } from '../../../commons/pendoTaggings';
import { useFeatureFlagsContext } from '../../../commons/FeatureFlags/FeatureFlagsContextProvider';
import { FeatureFlagsType } from '../../../app/appConstants';
import SpaceRuntimeActionMenu from '../../../homepage/SpaceRuntimeActionMenu';
import PublishChangesButton from './PublishChangesButton';

function SpaceHeaderPreview(props) {
  const { space } = useSpaceContext();

  const { isMobile, isPortal } = props;

  const { isAuthenticated, isTablet } = useAppContext();

  const navigate = useNavigate();
  const isAdmin = currentOrganization.isAdmin();
  const isHost = spaceUser.isHost();
  const isOwner = spaceUser.getUserInfoId() === space?.createdByUserInfoId;

  const currentUserSpaceRole = spaceUser?.getRole();

  const { checkEnabledFeatures } = useFeatureFlagsContext();
  const [isEnabledSpaceAnalytics, isEnabledVaam] = checkEnabledFeatures([
    FeatureFlagsType.SPACE_ANALYTICS,
    FeatureFlagsType.VAAM,
  ]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [enabledCMSGlobalTemplate] = checkEnabledFeatures([
    FeatureFlagsType.CMS_GLOBAL_TEMPLATE_UPDATE,
  ]);
  const appliedGlobalTemplate =
    enabledCMSGlobalTemplate && space?.isTemplate && space?.templateRefId;

  const currentSpace = useMemo(() => {
    return {
      ...space,
      currentUserSpaceRole,
    };
  }, [space, currentUserSpaceRole]);

  function handleOnClickedVaamButton() {
    setAnchorEl(false);
  }

  function handleClickInsightAnalyticButton() {
    navigate(`/space-analytics/${space.id}`, { name: space.name });
  }

  function handleClickDesignSpaceButton() {
    if (isAuthenticated) {
      navigate(`/space/${space.id}/design`);
    } else {
      const url = encodeURI(`/space/${space.id}/design?orgid=${space.organizationId}`);
      window.location = getSignInUrl(url);
    }
  }

  function handleOnClickEditInfoSpace() {
    if (isAuthenticated) {
      props.handleClickEditButton();
      setAnchorEl(null);
    } else {
      const url = encodeURI(
        `/space/${space.id}/redirect?orgid=${space.organizationId}&isEdit=true`
      );
      window.location = getSignInUrl(url);
    }
  }

  const handleOnClickMore = useCallback(async (event) => {
    setAnchorEl(event.target);
  }, []);

  function renderSpaceAnalyticsButton() {
    // console.log('isShowroom', isShowroom);
    // if (isShowroom && isPublicSpace) {
    //   return (
    //     <LightTooltip title={i18n.t('Space analytics is not available in public showrooms.')}>
    //       <div>
    //         <IconButton className="button-icon" data-id={Ids.InsightSpaceIconButton} disabled>
    //           <EqualizerIcon fontSize="small" />
    //         </IconButton>
    //       </div>
    //     </LightTooltip>
    //   );
    // }

    return (
      <LightTooltip title={i18n.t('View analytics')}>
        <IconButton
          className="button-icon"
          data-id={Ids.InsightSpaceIconButton}
          onClick={handleClickInsightAnalyticButton}
        >
          <EqualizerIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    );
  }

  function renderSpaceActions() {
    const canDesign = (isAdmin || isHost) && isAuthenticated;
    const canEdit = (isAdmin || isHost) && isAuthenticated;
    const canOpenVaam = !space.isTemplate && isEnabledVaam && isAuthenticated;
    const shouldVaamButtonDisabled = !isChrome && !isEdge;
    const isShowTabletMenu = isHost || isAdmin;
    const canOpenIASpace =
      (isAdmin || isHost || isOwner) &&
      isAuthenticated &&
      !space.isTemplate &&
      isEnabledSpaceAnalytics;

    if (!isMobile && !isTablet) {
      return (
        <>
          {appliedGlobalTemplate && (
            <>
              <PublishChangesButton spaceId={space?.id} templateRefId={space?.templateRefId} />
              <div className="vertical-divider">
                <Divider className="divider" />
              </div>
            </>
          )}
          {canEdit && (
            <LightTooltip title={i18n.t('Edit information')} placement="bottom">
              <IconButton
                onClick={handleOnClickEditInfoSpace}
                className="button-icon edit-button"
                data-id={Ids.EditSpaceButtonIcon}
              >
                <EditIcon className="editicon" />
              </IconButton>
            </LightTooltip>
          )}
          {canDesign && (
            <LightTooltip title={i18n.t('Edit design')} placement="bottom">
              <IconButton
                className="button-icon"
                onClick={handleClickDesignSpaceButton}
                data-id={Ids.EditDesignSpaceButtonIcon}
              >
                <i className="icon icon-design-1 " />
              </IconButton>
            </LightTooltip>
          )}
          {canOpenVaam && (
            <VaamButton
              onClicked={handleOnClickedVaamButton}
              shouldVaamButtonDisabled={shouldVaamButtonDisabled}
            />
          )}
          {canOpenIASpace && renderSpaceAnalyticsButton()}
        </>
      );
    }

    if (isTablet && !isMobile) {
      return (
        <>
          {appliedGlobalTemplate && (
            <PublishChangesButton spaceId={space?.id} templateRefId={space?.templateRefId} />
          )}
          {isShowTabletMenu && (
            <IconButton onClick={handleOnClickMore} size="small" className="iconbox">
              <MoreHorizIcon />
            </IconButton>
          )}
          <SpaceRuntimeActionMenu
            className="space-action-menu-runtime"
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            space={currentSpace}
            onClickEdit={canDesign ? handleOnClickEditInfoSpace : null}
            onClickGoToDesign={canEdit ? handleClickDesignSpaceButton : null}
            onClickVaam={canOpenVaam ? handleOnClickedVaamButton : null}
            shouldVaamButtonDisabled={shouldVaamButtonDisabled}
            onClickSpaceAnalytics={canOpenIASpace ? handleClickInsightAnalyticButton : null}
          />
        </>
      );
    }

    return null;
  }

  return (
    <div className="cms-space-header">
      <div className="left-content">
        {!props.hideSpaceName && (
          <div className="space-info">
            <SpaceInfo
              spaceName={space?.name}
              spaceId={space?.id}
              spaceLogo={space?.theme?.logoImageSrc}
              className="space-name"
              showInfoSpaceIcon={isAdmin || isHost}
              hideLogo={isMobile}
              isPortal={isPortal}
            />
          </div>
        )}

        {!props.hideDivider && !props.hideShareLink && !space.isTemplate && (
          <div className="vertical-divider">
            <Divider className="divider" />
          </div>
        )}

        {space.isTemplate && !props.hideTemplateTag && (
          <div className="tag-template-container">
            <Tag className="template-tag">{i18n.t('Template')}</Tag>
          </div>
        )}

        {!props.hideShareLink && !space.isTemplate && !isMobile && (
          <ShareLinkButton space={currentSpace} />
        )}
      </div>

      {!props.hideRightContent && (
        <div className="right-content">
          {!props.hideShareLink && !space.isTemplate && isMobile && (
            <ShareLinkButton space={currentSpace} />
          )}
          {renderSpaceActions()}

          {(isAdmin || isHost || isOwner) && !isMobile && isAuthenticated && (
            <div className="vertical-divider">
              <Divider className="divider" />
            </div>
          )}

          {isAuthenticated && (
            <>
              <HomeButton />
              <NotificationButton />
              <LoginMenu />
            </>
          )}
        </div>
      )}
    </div>
  );
}

SpaceHeaderPreview.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    backgroundImageSrc: PropTypes.string,
    createdByUserProfileImageSrc: PropTypes.string,
    isActive: PropTypes.bool,
    isGlobal: PropTypes.bool,
  }),
  // spaceId: PropTypes.string,
  hideRightContent: PropTypes.bool,
  hideSpaceName: PropTypes.bool,
  hideShareLink: PropTypes.bool,
  hideDivider: PropTypes.bool,
  hideTemplateTag: PropTypes.bool,
  isMobile: PropTypes.bool,
  isPortal: PropTypes.bool,
  handleClickEditButton: PropTypes.func,
};

export default SpaceHeaderPreview;

import React, { useState, useRef, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import LoadingMessage from '../../components/LoadingMessage';
import spaceUser from '../spaceUser';
import { useSpaceContext } from '../SpaceContext';
import i18n from '../../i18n';
import ParticipantItem from '../SpaceUsers/ParticipantItem';
import FilterBox from '../../components/FilterBox';
import ChannelList from './ChannelList';
import './PrivateChat.scss';
import usePrivateChatQuery from './usePrivateChatQuery';
import NoDaTaBox from '../../components/NoDataBox';

const PrivateChat = React.memo(
  forwardRef((props, ref) => {
    const { onSelectChannel, spaceId } = props;
    const currentUser = spaceUser.getUserInfo();
    const chatFormRef = useRef();

    const { isMobile, isPublicSpace } = useSpaceContext();

    const [filterText, setFilterText] = useState('');
    const { isLoading, suggestedUsers, channels, updateChannel } = usePrivateChatQuery(
      currentUser.userInfoId,
      spaceId,
      filterText,
      props.newEventMessage?.newPrivateMessages,
      isPublicSpace
    );

    function handleOnChangeText(value) {
      setFilterText(value);
    }
    function handleOnClearText() {
      setFilterText('');
    }

    const startChatWith = useCallback(
      (participant) => {
        const isViewOnlyMode = spaceUser.isViewOnly();
        if (isViewOnlyMode || !participant?.canChat) {
          return;
        }
        if (onSelectChannel) {
          onSelectChannel({
            participants: [currentUser, participant],
          });
        }
      },
      [currentUser, onSelectChannel]
    );

    async function handleOnLeaveChat() {
      const result = await chatFormRef.current?.handleCloseForm();
      return result;
    }

    React.useImperativeHandle(ref, () => ({
      handleCloseForm: handleOnLeaveChat,
    }));

    function renderPrivateChannelItems() {
      return (
        <div className="participant-channel-items">
          <ChannelList
            currentUserInfoId={currentUser.userInfoId}
            channels={channels}
            onSelectChannel={onSelectChannel}
            updateChannel={updateChannel}
          />
        </div>
      );
    }

    console.log('channels', channels);

    function renderParticipantMessage() {
      if (suggestedUsers.length === 0) {
        if (filterText.length > 0 && channels.length === 0) {
          return (
            <NoDaTaBox
              icon={<span className="icon-person" />}
              title={i18n.t('No users found')}
              // description={i18n.t('Choose another keyword and try again')}
            />
          );
        }
        if (channels.length === 0) {
          return (
            <div className="no-data">
              <div>{i18n.t('No private chat')}</div>
            </div>
          );
        }
        return null;
      } else {
        return <div className="suggestion">{i18n.t('Suggested for you')}</div>;
      }
    }

    function renderSearchInput() {
      if (suggestedUsers.length === 0 && channels.length === 0 && filterText.length === 0) {
        return null;
      }
      return (
        <div className="participant-list-filter">
          <FilterBox
            autoFocus={!isMobile}
            variant="light"
            placeholder={i18n.t('Search for users or email')}
            value={filterText}
            onChange={handleOnChangeText}
            onCancel={handleOnClearText}
          />
        </div>
      );
    }

    function renderBody() {
      if (isLoading) {
        return (
          <div className="loading-container">
            <LoadingMessage color="#fff">{i18n.t('Loading...')}</LoadingMessage>
          </div>
        );
      }
      return (
        <div className="participant-list-content">
          {renderSearchInput()}
          <div className="participant-list-body styled-scrollbar">
            {renderPrivateChannelItems()}
            {renderParticipantMessage()}
            <div className="list">
              {suggestedUsers.length > 0 &&
                suggestedUsers.map((participant) => (
                  <React.Fragment key={participant.userInfoId}>
                    <ParticipantItem
                      participant={participant}
                      onClickChat={() => startChatWith(participant)}
                      isCurrentParticipant={participant.isCurrentParticipant}
                      isSpace
                      chatPanel
                      canChat={participant.canChat}
                      isSelectMode={false}
                      isNewParticipant={participant.isNewParticipant}
                    />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      );
    }

    return <div className="private-chat-container">{renderBody()}</div>;
  })
);

PrivateChat.propTypes = {
  spaceId: PropTypes.string,
  newEventMessage: PropTypes.instanceOf(Object),
  onSelectChannel: PropTypes.func,
};

PrivateChat.defaultProps = {};

PrivateChat.displayName = 'PrivateChat';

export default PrivateChat;

import _findIndex from 'lodash/findIndex';
import { newGuid, trimString } from '../../commons/utils';
import { CONTENT_COMPONENT } from '../cmsConstants';

function hasImageExtension(fileName) {
  const extension = fileName.split('.').pop();
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'gif' || extension === 'png') {
    return true;
  }
  return false;
}

function getResourceFieldName(contentType) {
  switch (contentType) {
    case CONTENT_COMPONENT.VIDEO_UPLOAD:
      return 'videoUrl';
    case CONTENT_COMPONENT.FILE_UPLOAD:
    case CONTENT_COMPONENT.IMAGE_UPLOAD:
    case CONTENT_COMPONENT.BACKGROUND_IMAGE:
      return 'backgroundImageUrl';
    default:
      return '';
  }
}

export const onResourceChange = (content, resource, contentType) => {
  console.log('content, resource, contentType: ', content, resource, contentType);
  let resources = content.resources;
  let fieldsUpdate = [];
  let valuesUpdate = [];
  if (!resources) {
    resources = [];
  }

  let fieldName = getResourceFieldName(contentType);
  const croppedFiledName = `${fieldName}Cropped`;

  if (resource) {
    const { fileName, fileDescription, resourceType, src, isExternal, settings, orgSrc } = resource;
    let tempFileName = fileName;
    if (!fileName || fileName === 'Untitled' || !hasImageExtension(fileName)) {
      tempFileName = 'Untitled';
      if (fieldName === 'videoUrl') {
        tempFileName = `${trimString(src, 23)} (Video url)`;
      } else if (fieldName === 'backgroundImageUrl') {
        tempFileName = `${trimString(src, 23)} (Background image url)`;
      }
    }
    // console.log('heading', heading);
    console.log('resource fileName', fileName);
    console.log('resource tempFileName', tempFileName);

    let action = null;
    if (!resource.resourceId) {
      const currentResourceId = content[`${fieldName}ResourceId`];
      action = currentResourceId ? 'update' : 'add';
      const newResource = {
        uniqueId: newGuid(),
        fieldName: fieldName,
        action: action,
        data: {
          fileName: tempFileName,
          fileDescription: fileDescription || '',
          src,
          type: resourceType,
          order: 0,
          isExternal,
          settings,
          orgSrc,
        },
        isFileUploader: true,
      };
      if (action === 'add') {
        resources.push(newResource);
      } else if (action === 'update') {
        const foundResourceIndex = _findIndex(
          resources,
          (item) => item.data?.resourceId === currentResourceId && item.action === action
        );
        if (foundResourceIndex !== -1) {
          resources[foundResourceIndex].data.fileName = tempFileName;
          resources[foundResourceIndex].data.fileDescription = fileDescription || '';
        } else {
          // If url link is deleting and continuously add new link
          const isDeletingItem = _findIndex(
            resources,
            (item) => item.fieldName === fieldName && item.action === 'delete'
          );
          // For case update url by paste new link to replace exit link
          const isUpdatingUrl = resource.src !== resource.fileName || resource.updateNewUrl;

          // Add new resource when replace or delete and add new
          if (isDeletingItem !== -1 || isUpdatingUrl) {
            newResource.action = 'add';
          }

          if (isUpdatingUrl) {
            resources.push({
              uniqueId: newGuid(),
              fieldName: fieldName,
              action: 'delete',
              isFileUploader: true,
            });
          }
          resources.push(newResource);
        }
      }
    } else {
      // backgroundImageUrlCropped
      if (resource.isCropped) {
        fieldName = `${fieldName}Cropped`;
        fieldsUpdate.push('cropperData');
        fieldsUpdate.push('backgroundImageCropped');
        valuesUpdate.push(resource.cropperData);
        valuesUpdate.push(resource.backgroundImageCropped);
        // Remove old cropped image
        resources.push({
          uniqueId: newGuid(),
          action: 'delete',
          isFileUploader: true,
          fieldName: fieldName,
        });
      }

      resources.push({
        uniqueId: newGuid(),
        fieldName: fieldName,
        action: 'add',
        data: {
          resourceId: resource.resourceId,
          fileName: tempFileName,
          fileDescription: fileDescription || '',
          type: resourceType,
          canvasBackgroundColor: resource?.backgroundImageCropped,
          isCropped: resource.isCropped || false,
          materialId: resource.materialId,
        },
        isFileUploader: true,
      });
    }
  } else {
    resources.push({
      uniqueId: newGuid(),
      fieldName: fieldName,
      action: 'delete',
      isFileUploader: true,
    });
    // For case remove cropped images also;
    if (content[`${fieldName}Cropped`]) {
      resources.push({
        uniqueId: newGuid(),
        fieldName: `${fieldName}Cropped`,
        action: 'delete',
        isFileUploader: true,
      });
      fieldsUpdate = [croppedFiledName, 'cropperData'];
      valuesUpdate = [undefined, undefined, undefined];
    }
  }
  fieldsUpdate = [...fieldsUpdate, fieldName, 'resources'];
  valuesUpdate = [...valuesUpdate, resource?.resourceId, resources];
  return {
    fieldsUpdate,
    valuesUpdate,
  };
};

export const getWidthAndHeightImage = (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve, reject) => {
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
};

import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchAllChannels } from './CommentService';
import queryCache, { CacheKeys } from '../../app/queryCache';
import { ChannelType } from '../../app/appConstants';
import { getFilteredItems } from '../SpaceUsers/functions';
import { useSpaceContext } from '../SpaceContext';
import useSpaceUsersQuery from '../SpaceUsers/useSpaceUsersQuery';

const usePrivateChatQuery = (
  currentUserInfoId,
  spaceId,
  filterText,
  newEventMessage,
  isPublicSpace
) => {
  const { signalRConnection } = useSpaceContext();
  const getSpaceUsersQuery = useSpaceUsersQuery(spaceId, false, true, false);

  const getChannels = useQuery({
    queryKey: [CacheKeys.fetchAllChannels, spaceId, ChannelType.P2P],
    queryFn: async () => {
      if (!spaceId) {
        return [];
      }
      const channels = await fetchAllChannels(spaceId, ChannelType.P2P);
      return channels;
    },

    retry: 3,
    retryDelay: () => 5000,
    refetchOnMount: 'always',
  });

  const updateChannel = useCallback(
    (channelId, data) => {
      queryCache.setQueryData([CacheKeys.fetchAllChannels, spaceId, ChannelType.P2P], (oldData) => {
        if (!oldData) {
          return oldData;
        }
        const updatedData = oldData.map((channel) => {
          if (channel.id === channelId) {
            return {
              ...channel,
              ...data,
            };
          }
          return channel;
        });
        return updatedData;
      });
    },
    [spaceId]
  );

  const refetchChannels = getChannels.refetch;

  useEffect(() => {
    function handleOnChannelAdded() {
      refetchChannels();
    }

    if (signalRConnection) {
      signalRConnection.on('ChannelAddedEvent', handleOnChannelAdded);
    }

    return () => {
      if (signalRConnection) {
        signalRConnection.off('ChannelAddedEvent', handleOnChannelAdded);
      }
    };
  }, [signalRConnection, refetchChannels]);

  const isFetching = getChannels.isFetching || getSpaceUsersQuery.isFetching;
  const isLoading = getChannels.isLoading || getSpaceUsersQuery.isLoading;

  const data = useMemo(() => {
    if (!getSpaceUsersQuery.data || !getChannels.data) {
      return { suggestedUsers: [], channels: [] };
    }
    const users = getSpaceUsersQuery.data;
    const filteredUsers = getFilteredItems(users, filterText);
    console.log('filteredUsers', filteredUsers);
    let channels = [];
    let suggestedUsers = [];
    filteredUsers.forEach((user) => {
      if (user.userInfoId === currentUserInfoId || (user.isExternal && isPublicSpace)) {
        return;
      }
      // find channel
      const channel = getChannels.data.find((c) => {
        const participantIds = c.participants.map((participant) => participant.id);
        return participantIds.includes(user.userInfoId);
      });
      if (channel) {
        let numberOfNewComments = 0;
        if (newEventMessage && newEventMessage.length) {
          const foundChanelId = newEventMessage.find((item) => item.channelId === channel.id);
          console.log('foundChanelId', foundChanelId);
          numberOfNewComments = foundChanelId?.totalBadgeCount;
        }
        channels.push({ ...channel, numberOfNewComments });
      } else if (user.userInfoId > 0) {
        suggestedUsers.push({
          ...user,
          canChat: true,
        });
      }
    });
    channels = channels.sort((a, b) => {
      // sort by last message time
      if (a.lastComment && b.lastComment) {
        return a.lastComment.created < b.lastComment.created ? 1 : -1;
      } else if (a.lastComment) {
        return -1;
      } else {
        return 1;
      }
    });

    suggestedUsers = suggestedUsers.sort((a, b) => {
      // Sorting logic remains the same
      if (a.role === 'Host' && b.role !== 'Host') return -1;
      if (a.role !== 'Host' && b.role === 'Host') return 1;
      // if (a.isOnline && !b.isOnline) return -1;
      // if (!a.isOnline && b.isOnline) return 1;
      return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
    });

    console.log('channels', channels);

    return { suggestedUsers, channels };
  }, [
    getSpaceUsersQuery.data,
    getChannels.data,
    filterText,
    currentUserInfoId,
    newEventMessage,
    isPublicSpace,
  ]);

  return {
    isFetching,
    isLoading,
    suggestedUsers: data.suggestedUsers,
    channels: data.channels,
    updateChannel,
  };
};

export default usePrivateChatQuery;

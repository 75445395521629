import memoizeOne from 'memoize-one';
import { formatDateTime } from '../commons/DateTimeUtils';
import { getBffClientUrl, getUrlParameter } from '../commons/utils';
import { ResourceSources } from '../resources/types';

export const GLOBAL_RESOURCE_TYPE = {
  FOLDER: 0,
  RESOURCE: 1,
};

export const GLOBAL_PROVIDER_TYPE = {
  SPCE: 'SPCE',
  ONE_DRIVE: 'OneDrive',
  GOOGLE_DRIVE: 'GoogleDrive',
};

export const formatResources = memoizeOne((data, acceptResourceTypes) => {
  return data.map((item, index) => {
    const displayDateTime = formatDateTime(item.lastModified, 'MMM DD, YYYY');
    const isNotAFolder = item.resourceType !== null && item.resourceType !== undefined;
    let allowSelectedType = true;
    if (isNotAFolder) {
      allowSelectedType =
        acceptResourceTypes === undefined ? true : acceptResourceTypes.includes(item.resourceType);
    }
    return {
      ...item,
      index,
      displayDateTime: displayDateTime,
      name: item.name || item.resourceDisplayName || item.resourceName || 'Untitled',
      src: item.orgSrc || item.url,
      type: isNotAFolder ? GLOBAL_RESOURCE_TYPE.RESOURCE : GLOBAL_RESOURCE_TYPE.FOLDER,
      description: item.description || item.resourceDescription,
      allowSelectedType: allowSelectedType,
      isFolder: !isNotAFolder,
    };
  });
});

export function getMolnlyckeBffApi() {
  return `${getBffClientUrl()}/integrations/mlke`;
}

export function getResourceUrlParameters() {
  try {
    const stringSrId = getUrlParameter('srid', '');
    if (stringSrId) {
      const splittedIds = stringSrId.split('-');
      return {
        folderId: splittedIds[0] ? Number(splittedIds[0]) : 0,
        resourceId: splittedIds[1] ? Number(splittedIds[1]) : 0,
      };
    }
  } catch (error) {
    console.error(error);
    return {
      folderId: null,
      resourceId: null,
    };
  }
  return {
    folderId: null,
    resourceId: null,
  };
}

export const GLOBAL_RESOURCES_INFO = {
  OneDrive: {
    readonly: 'This view is read-only mode, head over to OneDrive to make changes',
  },
  GoogleDrive: {
    readonly: 'This view is read-only mode, head over to GoogleDrive to make changes',
  },
};

export const getSource = memoizeOne((selectedTab) => {
  switch (selectedTab) {
    case GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE:
      return ResourceSources.GoogleDrive;
    case GLOBAL_PROVIDER_TYPE.ONE_DRIVE:
      return ResourceSources.OneDrive;
    default:
      return ResourceSources.Global;
  }
});

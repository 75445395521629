import useWidgetImport from '../commons/useWidgetImport';
import React, { forwardRef, useRef } from 'react';
import Loading from './Loading';

type WidgetProps = {
  id: string | number;
  packageId: string;
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Widget = forwardRef((props: WidgetProps, ref: any) => {
  const elementRef = useRef(null);
  useWidgetImport(props.packageId, elementRef, props, ref);
  return (
    <div className={`spp-widget ${props.className || ''}`} id={`${props.id}`} ref={elementRef}>
      <div className="loading">
        <Loading />
      </div>
    </div>
  );
});

export default Widget;

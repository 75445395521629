import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import I18n from '../../i18n';
import { SpaceUserRole } from '../../app/appConstants';
import Tags from '../../components/Tags';
import LightTooltip from '../../components/LightTooltip';
import spaceUser from '../spaceUser';
import { getTimeActive } from './functions';
import UserAvatar from '../../components/UserAvatar';

function ParticipantItem(props) {
  const {
    participant,
    isModerator,
    isCurrentParticipant,
    isSpace,
    canChat,
    // canInviteOrRemovePariticipant,
    isSelected,
    isSelectMode,
    onClickUnSelect,
    onClickSelect,
    isNewParticipant,
  } = props;

  function getRoleName(data) {
    if (isSpace) {
      if (data.isAdmin) {
        return I18n.t('Host');
      }
      return '';
    } else {
      const role = data?.lastRole || data?.role;
      switch (role) {
        case SpaceUserRole.Host:
          return I18n.t('Host');
        default:
          return '';
      }
    }
  }

  const handleOnClickChat = () => {
    if (!canChat) {
      return;
    }
    props.onClickChat(participant);
  };

  function getTags() {
    const tags = [];
    const roleName = getRoleName(participant);
    if (roleName) {
      tags.push({
        backgroundColor: '#DBE1E3',
        color: '#000',
        text: roleName,
      });
    }
    // if (!participant.isVerified) {
    //   tags.push({
    //     backgroundColor: '#FF7F7F',
    //     color: '#000',
    //     text: I18n.t('Not verified'),
    //     tooltip: I18n.t(
    //       'An email has been sent to the users inbox for confirmation - this has not yet been verified.'
    //     ),
    //   });
    // }
    return tags;
  }

  function handleMoreButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
    props.onMoreButtonClick(event.currentTarget, participant);
  }

  function handleOnClickUnCheck(event) {
    event.preventDefault();
    event.stopPropagation();
    onClickUnSelect(participant);
  }

  function handleOnClickSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    onClickSelect(participant);
  }

  function renderRightButton() {
    const isViewOnlyMode = spaceUser.isViewOnly();
    if (!isCurrentParticipant && canChat && !props.isModerator && !isViewOnlyMode) {
      return (
        <LightTooltip
          title={I18n.t('Start a private chat with selected user in the Space')}
          placement="bottom-start"
        >
          <div className="buttonOpacity">
            <IconButton aria-label="chat" onClick={handleOnClickChat}>
              <ChatIcon color="secondary" />
            </IconButton>
          </div>
        </LightTooltip>
      );
    }
    if (!isCurrentParticipant && isModerator) {
      if (isSelected) {
        return (
          <IconButton className="whiteButton" onClick={handleOnClickUnCheck}>
            <span className="icon-checked">
              <span className="path1"></span>
              <span className="path2"></span>
            </span>
          </IconButton>
        );
      }
      if (isSelectMode) {
        return (
          <IconButton className="whiteButton" onClick={handleOnClickSelect}>
            <CheckBoxOutlineBlankOutlinedIcon className="checkbox" />
          </IconButton>
        );
      }
      return (
        <div className="buttonOpacity">
          <IconButton className="whiteButton" onClick={handleMoreButtonClick}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      );
    }
    return null;
  }

  function renderParticipantItem() {
    const tags = getTags();
    const onlineParticipant = participant.isOnline;
    return (
      <>
        <div className="avatar-block">
          <div className="avatar">
            <UserAvatar fullName={participant?.fullName} email={participant.email} />
            <div className="online-background">
              <div className={`circle ${onlineParticipant ? 'online' : ''}`}></div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="name-role-block">
            <div className="name">
              {participant.fullName}
              {isCurrentParticipant && <span>{`(${I18n.t('me')})`}</span>}
            </div>

            {tags.length > 0 && <Tags items={tags} />}
          </div>
          <div className="email">
            <span>{getTimeActive(participant?.lastActive, onlineParticipant)}</span>
          </div>
        </div>
        <div className="icon-right">{renderRightButton()}</div>
        {/* <span className={`blue-dot ${isNewParticipant ? 'active' : ''}`} /> */}
      </>
    );
  }

  function handleOnClickItem() {
    if (!canChat) {
      return;
    }
    props.onClickChat(participant);
    if (props.onClick && isNewParticipant) {
      props.onClick(participant, isNewParticipant);
    }
  }

  if (props.isCurrentParticipant) {
    return <div className="participant-item">{renderParticipantItem()}</div>;
  }

  return (
    <div
      onClick={handleOnClickItem}
      style={{ cursor: !canChat ? 'inherit' : 'pointer' }}
      className={`participant-item ${props.isActionMenuOpen ? 'hover' : ''}`}
    >
      {renderParticipantItem()}
    </div>
  );
}

ParticipantItem.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isOnline: PropTypes.bool,
    isVerified: PropTypes.bool,
    role: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    userInfoId: PropTypes.number,
    email: PropTypes.string,
    invited: PropTypes.bool,
    lastActive: PropTypes.string,
  }),
  onClickChat: PropTypes.func,
  // onClickInviteToStage: PropTypes.func,
  isCurrentParticipant: PropTypes.bool,
  isModerator: PropTypes.bool,
  isSpace: PropTypes.bool,
  canChat: PropTypes.bool,
  // canInviteOrRemovePariticipant: PropTypes.bool,
  onMoreButtonClick: PropTypes.func,
  isActionMenuOpen: PropTypes.bool,
  onClickUnSelect: PropTypes.func,
  onClickSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  isSelectMode: PropTypes.bool,
  isNewParticipant: PropTypes.bool,
  onClick: PropTypes.func,
};

ParticipantItem.defaultProps = {
  chatPanel: false,
};

export default React.memo(ParticipantItem);

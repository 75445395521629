import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableContainer } from '@mui/material';

import EnhancedTableHead from './EnhancedTableHeader';

import './EnhancedTable.scss';

const EnhancedTable = (props) => {
  const { children, headCells, onChangeSort, defaultSort, className } = props;
  const [order, setOrder] = useState(defaultSort?.order || 'asc');
  const [orderBy, setOrderBy] = useState(defaultSort?.orderBy || '');

  const handleRequestSort = (event, property) => {
    console.log('### handleRequestSort', order, orderBy, property);
    const sort = orderBy === property && order === 'asc' ? 'desc' : 'asc';
    setOrder(sort);
    setOrderBy(property);
    onChangeSort(sort, property);
  };

  return (
    <TableContainer className={`table-container styled-scrollbar ${className}`}>
      <Table className="table" size="medium" aria-label="sticky table" stickyHeader>
        {headCells && (
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
        )}

        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

EnhancedTable.propTypes = {
  defaultSort: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      disablePadding: PropTypes.bool,
      label: PropTypes.string,
      width: PropTypes.string,
      disableSorting: PropTypes.bool,
    })
  ).isRequired,
  onChangeSort: PropTypes.func,
  className: PropTypes.string,
};

export default EnhancedTable;

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
// import { Tab, Tabs } from '@mui/material';
import './GlobalResourcesTabs.scss';
import Tabs from '../components/Tabs';
import { GLOBAL_PROVIDER_TYPE } from './utils';
import { useBreadcrumbContext } from './context/BreadcrumbsContextProvider';

const getTabs = memoizeOne((showOneDrive, showGoogleDrive, selectedTab) => {
  const initialTabs = ['SPCE'];
  if (showOneDrive) {
    initialTabs.push(GLOBAL_PROVIDER_TYPE.ONE_DRIVE);
  }
  if (showGoogleDrive) {
    initialTabs.push(GLOBAL_PROVIDER_TYPE.GOOGLE_DRIVE);
  }
  const initialSelectedTab = initialTabs.findIndex((item) => item === selectedTab) || 0;

  return { initialTabs, initialSelectedTab };
});

const GlobalResourcesTabs = (props) => {
  const { initialTabs, initialSelectedTab } = getTabs(
    props.showOneDrive,
    props.showGoogleDrive,
    props.selectedTab
  );
  // const { tabs, changeTab } = useTabs(initialTabs, initialSelectedTab, disable, tooltipMessage);
  const { goToRootItem } = useBreadcrumbContext();
  const [activeTab, setActiveTab] = useState(initialSelectedTab);

  const tabs = useMemo(() => {
    const disableTabs = props.disabledTabs ? ['OneDrive', 'GoogleDrive'] : [];
    return initialTabs.map((tab, index) => {
      const disabled = disableTabs.includes(tab);
      return {
        label: tab,
        active: index === activeTab,
        disabled,
        disabledMessage: disabled
          ? 'The integration currently supports adding folders, but not individual files.'
          : '',
      };
    });
  }, [activeTab, initialTabs, props.disabledTabs]);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    const newValue = initialTabs[tab];
    if (props.selectedTab === newValue) {
      goToRootItem();
    }
    props.onChangeTab(newValue);
  };

  return (
    <div className={`global-resources-tabs ${props.hideIntegration && 'hidden'}`}>
      {!props.hideIntegration && (
        <Tabs tabs={tabs} onChange={handleChangeTab} variant="tabs-level-2" />
      )}
    </div>
  );
};

GlobalResourcesTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  showOneDrive: PropTypes.bool,
  showGoogleDrive: PropTypes.bool,
  onChangeTab: PropTypes.func,
  hideIntegration: PropTypes.bool,
  disabledTabs: PropTypes.bool,
};

GlobalResourcesTabs.defaultProps = {
  showOneDrive: true,
  showGoogleDrive: true,
  onChangeTab: () => {},
  hideIntegration: false,
  disabledTabs: false,
};

export default React.memo(GlobalResourcesTabs);

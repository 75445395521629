import { useRef } from 'react';

const useArray = <T extends string | number>(array: T[]) => {
  const arrayRef = useRef(array);
  const arrayHasChanged =
    arrayRef.current.length !== array.length ||
    arrayRef.current.some((item, index) => item !== array[index]);
  if (arrayHasChanged) {
    arrayRef.current = array;
  }

  return arrayRef.current;
};

export default useArray;

export const CMS_WIDGET_SUBTYPE = {
  PARTICIPANTS: 'participants',
  CHAT: 'chat',
  RESOURCES: 'resources',
};

export const WIDGET_OPTION = [
  { value: CMS_WIDGET_SUBTYPE.CHAT, name: 'Chat', title: 'Chat' },
  { value: CMS_WIDGET_SUBTYPE.RESOURCES, name: 'Resources', title: 'Resources' },
];

export const WIDGET_OPTION_PUBLIC = [
  { value: CMS_WIDGET_SUBTYPE.RESOURCES, name: 'Resources', title: 'Resources' },
];

export const WIDGET_OPTION_SHOWROOM = [
  { value: CMS_WIDGET_SUBTYPE.CHAT, name: 'Chat', title: 'Chat' },
  { value: CMS_WIDGET_SUBTYPE.RESOURCES, name: 'Resources', title: 'Resources' },
];

export const WIDGET_FEATURES = {
  Participants: CMS_WIDGET_SUBTYPE.PARTICIPANTS,
  Chat: CMS_WIDGET_SUBTYPE.CHAT,
  Resources: CMS_WIDGET_SUBTYPE.RESOURCES,
};

export const WIDGET_NAME = {
  [CMS_WIDGET_SUBTYPE.RESOURCES]: 'Resources',
  [CMS_WIDGET_SUBTYPE.CHAT]: 'Chat',
  // [CMS_WIDGET_SUBTYPE.PARTICIPANTS]: 'Participants',
};

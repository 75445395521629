import React from 'react';
import _find from 'lodash/find';
import { useQuery } from '@tanstack/react-query';
import { CacheKeys } from '../../app/queryCache';
import { useSpaceContext } from '../SpaceContext';
import { ChannelType } from '../../app/appConstants';
import { fetchChannel, joinChannel, leaveChannel } from './CommentService';
import { useIntegrationRuntimeContext } from '../integrations/IntegrationRuntimeContextProvider';

const isChannelExisted = (externalChannels, newChannel) => {
  const foundExternalChannel = _find(externalChannels, (item) => item.id === newChannel.id);
  return !!foundExternalChannel;
};

function useChatPublicChannel(props) {
  const { canRunChat } = props;
  const { space, signalRConnection, hasSpaceJustCreated } = useSpaceContext();
  const { integrationConnections, setIntegrationConnections } = useIntegrationRuntimeContext();

  const spaceId = space?.id;
  const signalRConnectionId = signalRConnection?.connectionId;
  const [chatPublicChannel, setChatPublicChannel] = React.useState(null);
  const refChatPublicChannel = React.useRef(null); // a trick to avoid component re-render
  const externalChannelsCheckerTimeout = React.useRef(null);
  // console.log('chatPublicChannel canRunChat', canRunChat);
  const fetchChatPublicChannelQuery = useQuery({
    queryKey: [CacheKeys.fetchChannelRequest, spaceId],
    queryFn: async () => {
      const resp = await fetchChannel(spaceId, ChannelType.RUNTIME);
      return resp;
    },
    retry: 3,
    retryDelay: () => 5000,
    enabled: canRunChat,
  });

  React.useEffect(() => {
    const mainChannel = fetchChatPublicChannelQuery.data;
    // console.log('chatPublicChannel mainChannel', mainChannel, spaceId, signalRConnectionId);
    async function joinPublicChannel() {
      // console.log('chatPublicChannel join public channel');
      await joinChannel(spaceId, signalRConnectionId, mainChannel.id, ChannelType.RUNTIME);
    }

    if (mainChannel && spaceId && signalRConnectionId) {
      joinPublicChannel();
      setChatPublicChannel(mainChannel);
      refChatPublicChannel.current = mainChannel;
    }

    return () => {
      if (mainChannel && spaceId && signalRConnectionId) {
        leaveChannel(spaceId, signalRConnectionId, mainChannel.id, ChannelType.RUNTIME);
      }
    };
  }, [fetchChatPublicChannelQuery.data, spaceId, signalRConnectionId]);

  const updateIntegrationConnections = React.useCallback(() => {
    const newIntegrationConnections = [...integrationConnections];
    setIntegrationConnections(newIntegrationConnections);
  }, [integrationConnections, setIntegrationConnections]);

  React.useEffect(() => {
    function handleExternalChannelAdded(newChannel) {
      if (refChatPublicChannel.current) {
        const newChatPublicChannel = { ...refChatPublicChannel.current };
        const externalChannels = newChatPublicChannel.externalChannels || [];
        if (!isChannelExisted(externalChannels, newChannel)) {
          externalChannels.push(newChannel);
          setChatPublicChannel(newChatPublicChannel);
          refChatPublicChannel.current = newChatPublicChannel;
          updateIntegrationConnections(externalChannels);
        }
      }
    }
    if (signalRConnection?.connectionId) {
      signalRConnection.on('ExternalChannelAdded', handleExternalChannelAdded);
    }

    return () => {
      if (signalRConnection?.connectionId) {
        // console.log('chatPublicChannel remove events');
        signalRConnection.off('ExternalChannelAdded', handleExternalChannelAdded);
      }
    };
  }, [signalRConnection, updateIntegrationConnections]);

  React.useEffect(() => {
    const externalChannels = chatPublicChannel?.externalChannels;

    async function checkAndFetchExternalChannels() {
      if (externalChannelsCheckerTimeout.current) {
        return;
      }

      externalChannelsCheckerTimeout.current = setTimeout(async () => {
        if (!externalChannels || externalChannels.length === 0) {
          const mainChannel = await fetchChannel(spaceId, ChannelType.RUNTIME);
          if (mainChannel) {
            setChatPublicChannel(mainChannel);
            refChatPublicChannel.current = mainChannel;
            updateIntegrationConnections(mainChannel.externalChannels || []);
            clearTimeout(externalChannelsCheckerTimeout.current);
          }
        }
      }, 30000);
    }

    if (integrationConnections?.length > 0) {
      if (externalChannels?.length === 0 && hasSpaceJustCreated()) {
        checkAndFetchExternalChannels();
      }
    }
  }, [
    chatPublicChannel?.externalChannels,
    hasSpaceJustCreated,
    integrationConnections,
    spaceId,
    updateIntegrationConnections,
  ]);

  return [chatPublicChannel, setChatPublicChannel];
}

export default useChatPublicChannel;

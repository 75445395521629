import React, { useState } from 'react';
import { AlertTitle } from '@mui/material';
import './style.scss';
import OfficialButton from '../OfficialButtons';

type WarningMessageProps = {
  title: string;
  description: string;
  actionButton?: JSX.Element;
  buttonLabel?: string;
  onClickButton?: () => void;
};

function WarningMessage(props: WarningMessageProps) {
  const { title, description, buttonLabel, onClickButton, actionButton } = props;
  const [isProcessing, setIsProcessing] = useState(false);

  const handleOnClickButton = async () => {
    if (!onClickButton) return;
    setIsProcessing(true);
    try {
      await onClickButton();
    } catch (error) {
      console.error(error);
    }
    setIsProcessing(false);
  };

  return (
    <div className="warning-custom-message-popup">
      <div className="warning-custom-message-popup-content">
        {title && <AlertTitle>{title}</AlertTitle>}

        {description && <span>{description}</span>}
      </div>
      {actionButton}
      {!actionButton && buttonLabel && (
        <div className="warning-custom-message-popup-actions">
          <OfficialButton
            label={buttonLabel}
            isProcessing={isProcessing}
            onClick={handleOnClickButton}
            variant="regular-primary"
          />
        </div>
      )}
    </div>
  );
}

export default WarningMessage;

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './SpaceInfo.scss';
import { getDisplayText } from '../commons/utils';
import LogoSpace from '../components/LogoSpace';

function SpaceInfo({ spaceName, spaceLogo, variant, hideLogo }) {
  const className = `space-name ${variant}`;

  const { htmlAttributes } = getDisplayText(spaceName);

  return (
    <div className="space-info">
      {!hideLogo && <LogoSpace src={spaceLogo} className="space-name-logo" />}
      <div className={className}>
        <h1 className="text-truncate" title={spaceName} {...htmlAttributes} />
      </div>
    </div>
  );
}

SpaceInfo.propTypes = {
  spaceName: PropTypes.string,
  spaceId: PropTypes.string,
  spaceLogo: PropTypes.string,
  variant: PropTypes.string,
  hideLogo: PropTypes.bool,
  isPortal: PropTypes.bool,
};

export default SpaceInfo;

import React from 'react';
import { Popover } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import i18n from '../../i18n';
import { ResourceReferenceList } from '../../globalResources/context/Interfaces';
import './GlobalResourceUsageButton.scss';
import { getResourceUsageData } from '../../resources/ResourceServices';
import OfficialButton from '../OfficialButtons';
import Loading from '../Loading';

type GlobalResourceUsageButtonProps = {
  resourceId?: string;
};

export default function GlobalResourceUsageButton(props: GlobalResourceUsageButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [displayData, setDisplayData] = React.useState<Array<ResourceReferenceList> | null>(null);
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      setAnchorEl(event.currentTarget);
      setIsProcessing(true);
      const data = await getResourceUsageData(props.resourceId);
      setDisplayData(data);
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
    setIsProcessing(false);
  };
  const handleClose = () => {
    setIsProcessing(false);
    setDisplayData(null);
    setAnchorEl(null);
  };

  function renderPopover() {
    const open = Boolean(anchorEl);
    const popupId = open ? 'simple-popover' : undefined;
    return (
      <Popover
        id={popupId}
        open={open}
        className="global-resource-usage-popover"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {isProcessing
          ? null
          : displayData?.map(({ categoryName, children }, key) => (
              <div className="customized-dropdown-item-wrapper" key={key}>
                <div className="item-header">
                  <div className="item-name">
                    {categoryName} ({children.length})
                  </div>
                </div>
                {children.map(({ name, id }) => (
                  <a
                    className="item-body"
                    key={id}
                    href={`${location.origin}/s/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="item-body-content">{name}</div>
                    <ArrowForwardIosIcon color="inherit" style={{ fontSize: 'inherit' }} />
                  </a>
                ))}
              </div>
            ))}
      </Popover>
    );
  }

  return (
    <div>
      <OfficialButton
        isProcessing={isProcessing}
        processingText={<Loading fontSize={8} />}
        variant="small-secondary"
        onClick={handleClick}
        label={i18n.t('View')}
      />
      {isProcessing ? null : renderPopover()}
    </div>
  );
}

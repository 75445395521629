import I18n from '../i18n';

export const Provider = {
  Vaam: 3,
};

export const ResourceTypeNames = {
  video: 'video',
  audio: 'audio',
  image: 'image',
  pdf: 'pdf',
  podcast: 'podcast',
  powerpoint: 'powerpoint',
  word: 'word',
  excel: 'excel',
  keynote: 'keynote',
  webContent: 'link',
  externalLink: 'externallink',
  other: 'other',
  font: 'font',
  zip: 'zip',
};

export const ResourceTypeTitle = {
  video: 'Video',
  image: 'Images',
  pdf: 'PDF',
  podcast: 'Web Content',
  powerpoint: 'PowerPoint',
  word: 'Word',
  excel: 'Excel',
  keynote: 'Keynote',
  webContent: 'Web Content',
  externalLink: 'External Link',
  audio: 'Audio',
  cad: 'CAD',
  zip: 'Zip',
  other: 'Other',
};

export const ResourceType = {
  unknown: -1,
  video: 0,
  image: 1,
  pdf: 2,
  podcast: 3,
  powerpoint: 4,
  word: 5,
  excel: 6,
  keynote: 7,
  signingDocument: 8,
  vaam: 9,
  font: 10,
  externalLink: 11,
  webSharing: 12,
  audio: 14,
  cad: 15,
  zip: 16,
  eps: 17,
  ai: 18,
  indd: 19,
  psd: 20,
  dcm: 21,
};

export const UploadContextTypes = {
  Space: 0,
  OrgBranding: 3,
  GlobalResources: 4,
  TermsAndConditions: 5,
  CourseBlocks: 6,
};
export const ResourceTypeDisplayNames = {
  ...ResourceTypeTitle,
  webSharing: 'Web Sharing',
};

export const MessageType = {
  Email: 'Email',
  SMS: 'SMS',
};

export const SpaceUserRole = {
  Host: 'Host',
  Participant: 'Participant',
  Supervisor: 'Supervisor',
};

export const DefaultTimeZone = 'Europe/London';

export const PrivacyLevel = {
  Open: 1,
  Closed: 2,
};

export const ChannelType = {
  PORTAL: 0,
  RUNTIME: 1,
  BREAKOUT: 2,
  P2P: 3,
};

export const InteractionType = {
  Like: 'Like',
};

export const SpaceTemplateScopeNames = {
  1: 'Global Template',
  2: 'Private',
};

export const OrganizationUserRole = {
  Admin: 'Admin',
  User: 'User',
  Partner: 'Partner',
};

export const OrganizationUserState = {
  Created: 'Created',
  Invited: 'Invited',
  Active: 'Active',
  Rejected: 'Rejected',
  InActive: 'InActive',
};

export const OrganizationUserStateName = {
  0: 'Created',
  1: 'User is Invited',
  2: 'Activated',
  3: 'Rejected',
  4: 'Deactivated',
};

export const SpaceState = {
  Open: 'Open',
  Closed: 'Closed',
  InActive: 'InActive',
};

export const ResourceInteractionType = {
  Open: 1,
  Close: 2,
  Click: 3,
  Uploaded: 4,
  Shared: 5,
  Rated: 6,
  Downloaded: 7,
  Viewed: 9,
};

export const SpaceType = {
  PUBLIC: 2,
  RESTRICTED: 0,
  PRIVATE: 1,
};

export const PrivacySpace = {
  PUBLIC: 'Public',
  RESTRICTED: 'Restricted',
  PRIVATE: 'Private',
};

export const UploadingFileState = {
  NotStarted: 0, // file was picked but not started to upload.
  Started: 1,
  Completed: 2,
  Failed: 3,
  Canceled: 4,
};

export const ResourceState = {
  Created: 0,
  Transcoding: 1,
  Transcoded: 2,
  Converting: 3,
  Converted: 4,
  Deleted: 5,
  PendingExternalyCreated: 6,
  AwaitingTranscoding: 7,
  AwaitingConversion: 8,
  ConversionFailed: 9,
  TranscodingFailed: 10,
};

export const FolderTypes = {
  GlobalResources: 0,
  OneDrive: 1,
  GoogleDrive: 2,
};

export const ConvertToPDFResourceTypes = [
  ResourceType.powerpoint,
  ResourceType.keynote,
  ResourceType.word,
  ResourceType.excel,
];

export const DefaultParticipantRoles = [
  {
    value: SpaceUserRole.Participant,
    name: I18n.t('User'),
  },
  {
    value: SpaceUserRole.Host,
    name: I18n.t('Host'),
  },
];

export const systemFonts = [
  {
    id: 'MediumLLWeb-Regular',
    name: 'MediumLLWeb-Regular',
    fontFamily: 'MediumLLWeb-Regular',
    variants: [],
  },
  {
    id: 'MediumLLWeb-Bold',
    name: 'MediumLLWeb-Bold',
    fontFamily: 'MediumLLWeb-Bold',
    variants: [],
  },
  {
    id: 'F37Judge-MediumCondensed',
    name: 'F37Judge-MediumCondensed',
    fontFamily: 'F37Judge-MediumCondensed',
    variants: [],
  },
  {
    id: 'Times New Roman',
    name: 'Times New Roman',
    fontFamily: 'Times New Roman',
    variants: ['regular', '700', 'italic'],
  },
  {
    id: 'Helvetica',
    name: 'Helvetica',
    fontFamily: 'Helvetica',
    variants: ['regular', '700', 'italic'],
  },
  {
    id: 'Arial',
    name: 'Arial',
    fontFamily: 'Arial',
    variants: ['regular', '700', 'italic'],
  },
];

export const DirectoryType = {
  Default: 'Default',
  CMS: 'CMS',
  Vaam: 'Vaam',
  Misc: 'Misc',
  Extra: 'Extra',
};

export const VideoPlayerState = {
  Playing: 0,
  Paused: 1,
  Ended: 2,
};

export const FontVariantsDictionary = {
  regular: ':regular',
  700: 'Bold:700',
  italic: 'Italic:italic',
  300: 'Light:300',
  500: 'Medium:500',
  600: 'SemiBold:600',
};

export const FeatureFlagsType = {
  // Intergrations
  INTEGRATIONS_ONEDRIVE: 'integrations-onedrive',
  INTEGRATIONS_GOOGLEDRIVE: 'integrations-googledrive',
  MAGIC_LINK: 'magic-link',
  SPACE_ANALYTICS: 'space-space-analytics',
  DATE_PICKER: 'analytics-date-picker',
  VAAM: 'space-vaam',
  TC: 'settings-tc',
  TAGS: 'settings-tags',
  SETTINGS_COMPANY_ANALYTICS: 'settings-company-analytics',
  HOME_SPACE_ENGAGEMENT: 'home-spaces-engagement',
  CMS_PAGES: 'cms-pages',
  HTML_CSS: 'cms-css-html',
  CMS_GLOBAL_TEMPLATE_UPDATE: 'cms-global-template-update',
  SPACE_ADD_HOST: 'space-add-host',
  MATERIAL_SLIDER: 'material-slider',

  SPACE_UPLOAD_FOLDER_ONEDRIVE: 'space-upload-folder-onedrive',
  SPACE_UPLOAD_FOLDER_GOOGLEDRIVE: 'space-upload-folder-googledrive',
  SCRIPT: 'cms-script',
  DEAL_MANAGEMENT: 'deal-management',
  DEAL_MANAGEMENT_SETTINGS: 'deal-management-settings',
  COLLECTION_ASSESSMENTS: 'collection-assessments',
  DASHBOARD: 'dashboard',
  COURSES: 'courses',
};

export const TryForFreeOrganizationIdsProd = [
  '22755b47-3e24-4065-bcf3-87ba8aa7adfe', // Prod
  '5bdccc48-e41b-4b1b-9d8f-b16abd4011d7',
];
export const TryForFreeOrganizationIdsDev = [
  '028b670a-7ed0-4097-a4b2-6ac06b8cad69', // Dev
];

export const OnBoardSpaces = [
  'fda32068-76c0-45d1-9d39-5af28a36923e', // Dev
];

export const DefaultLogoImage = '/static/images/spce-logo-black.png';
export const DefaultBackgroundImage = '/static/images/default-bg.jpg';

export const SupportedExtensions = [
  '.jpg',
  '.jpeg',
  '.png',
  '.apng',
  '.tif',
  '.tiff',
  '.gif',
  '.svg',
  '.webp',
  '.mp4',
  '.avi',
  '.mov',
  '.mpg',
  '.mpeg',
  '.webm',
  '.mp3',
  '.wav',
  '.doc',
  '.docx',
  '.pdf',
  '.ppt',
  '.pptx',
  '.key',
  '.xlsx',
  '.xlsm',
  '.xlsb',
  '.xls',
  '.dwg',
  '.stp',
  '.igs',
  '.jt',
  '.step',
  '.sat',
  '.stl',
  '.ttf',
  '.otf',
  '.woff',
  '.woff2',
  '.zip',
  '.eps',
  '.ai',
  '.indd',
  '.psd',
  '.dcm',
];

export const DefaultResourceAccept = SupportedExtensions.join(',');

export const SupportedExtensionsByCategory = {
  image: ['.jpg', '.jpeg', '.png', '.apng', '.gif', '.svg', '.webp', '.tiff', '.tif'],
  video: ['.mp4', '.avi', '.mov', '.mpg', '.mpeg', '.webm'],
  audio: ['.mp3', '.wav'],
  pdf: ['.pdf'],
  powerpoint: ['.ppt', '.pptx'],
  word: ['.doc', '.docx'],
  excel: ['.xls', '.xlsx', '.xlsm', '.xlsb'],
  keynote: ['.key'],
  font: ['.ttf', '.otf', '.woff', '.woff2'],
  cad: ['.dwg', '.stp', '.igs', '.jt', '.step', '.sat', '.stl'],
  other: ['.zip', '.eps', '.ai', '.indd', '.psd', '.dcm'],
};

export const ExceptionTypes = {
  SubscriptionQuotaReached: 'SubscriptionQuotaReached',
  BusinessViolationException: 'BusinessViolationException',
  ConferenceViolationException: 'ConferenceViolationException',
  FeatureNotEnabledException: 'FeatureNotEnabledException',
  InvalidRequestException: 'InvalidRequestException',
  NotFoundException: 'NotFoundException',
  PermissionDeniedException: 'PermissionDeniedException',
  SpaceAccessException: 'SpaceAccessException',
  SubscriptionViolationException: 'SubscriptionViolationException',
  TranslatableException: 'TranslatableException',
  UnauthorizedException: 'UnauthorizedException',
  UserNotFoundException: 'UserNotFoundException',
};

export const SpaceInteractionType = {
  PAGE_LOAD: 'PAGE_LOAD',
  CLICK: 'CLICK',
  FOCUS: 'FOCUS',
  VIEW_RESOURCE: 'VIEW_RESOURCE',
  SUBMIT: 'SUBMIT',
  SCROLL: 'SCROLL',
};

export const CommentStatus = {
  SENDING: 'SENDING',
  SENT: 'SENT',
  FAILED: 'FAILED',
  NEW: 'NEW',
};

export const SidebarType = {
  Material: 'Marterial',
  Chat: 'Chat',
  Participants: 'Participants',
};

export const CommentState = {
  Active: 'Active',
  Deleted: 'Deleted',
};

export const VideoResourceViewedBatchTime = 5;
export const ViewedTimeConsiderAsViewed = 0;

/* global System */
import React from 'react';
import client from 'react-dom/client';
import ReactDom from 'react-dom';
import jsxRuntime from 'react/jsx-runtime';
import momentTz from 'moment-timezone';
import * as reactDnd from 'react-dnd';
import * as reactDndHtml5Backend from 'react-dnd-html5-backend';
import * as lodash from 'lodash';
import i18next from 'i18next';

System.set('app:react', { default: React, ...React });
System.set('app:react-dom', { default: ReactDom, ...ReactDom });
System.set('app:react-dom/client', { default: client, ...client });
System.set('app:react/jsx-runtime', { default: jsxRuntime, ...jsxRuntime });
System.set('app:moment-timezone', { default: momentTz, ...momentTz });
System.set('app:react-dnd', { default: reactDnd, ...reactDnd });
System.set('app:react-dnd-html5-backend', {
  default: reactDndHtml5Backend,
  ...reactDndHtml5Backend,
});
System.set('app:lodash', { default: lodash, ...lodash });
System.set('app:i18next', { default: i18next, ...i18next });

if (process.env.REACT_APP_ENV === 'local') {
  require('./system.local');
}

import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { IBlock } from '../types';
import ConfirmRemoveBlockDialog from '../../cms/components/ConfirmRemoveBlockDialog';
import { uuidv4 } from '../../commons/utils';
import { useMountEffect } from '../../commons/CustomHooks';
import useSpaceCourseBlocksQuery from '../queries/useSpaceCourseBlocksQuery';

const CmsCourseBlocks = React.lazy(() => import('./CmsCourseBlocks'));

type ICmsBlock = IBlock & {
  itemId: string;
  blockId?: string;
  courseId?: string;
};

type IProps = {
  data: Array<ICmsBlock>;
  onChange: (newList: Array<ICmsBlock>) => void;
};

const CourseBlock = (props: IProps) => {
  const { data, onChange } = props;
  const [list, setList] = useState<ICmsBlock[]>([]);

  const ids = data?.map((item) => item.blockId);
  const { spaceBlocks, refetch } = useSpaceCourseBlocksQuery(ids, true, false);

  useMountEffect(() => {
    if (ids?.length > 0) {
      refetch();
    }
  });

  useEffect(() => {
    if (spaceBlocks) {
      const blocks = spaceBlocks.map((item: IBlock) => {
        return {
          id: uuidv4(),
          name: item.blockName,
          blockId: item.blockId,
        };
      });
      setList(blocks);
    }
  }, [spaceBlocks]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (newList: any[]) => {
    console.log('newList: ', newList);
    setList(newList);
    onChange(newList);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnSelectItems = (selectedBlocks: any[]) => {
    setList((prev) => {
      let newItems = prev || [];
      selectedBlocks.forEach((selectedBlock) => {
        newItems = update(newItems, {
          $push: [
            {
              id: uuidv4(),
              name: selectedBlock.name,
              blockId: selectedBlock.id,
            } as ICmsBlock,
          ],
        });
      });
      onChange(newItems);
      return newItems;
    });
  };

  const handleOnRemove = async () => {
    const { hasConfirmed } = await ConfirmRemoveBlockDialog.show(
      'Remove Blocks',
      `Are you sure you want to remove this block in the widget? All historical data of this
        block in this space will be kept in analytics.`,
      false,
      null,
      'Yes',
      'Cancel'
    );
    return hasConfirmed;
  };

  return (
    <CmsCourseBlocks
      label="Number of block"
      name="block"
      list={list}
      onRemove={handleOnRemove}
      handleOnChange={handleOnChange}
      handleOnSelectItems={handleOnSelectItems}
    />
  );
};

export default CourseBlock;

import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { formatDateTime } from '../../commons/DateTimeUtils';
import LightTooltip from '../LightTooltip';
// import { SubscriptionLockIcon } from '../../commons/Subscription';
import ScrollIntoView from '../ScrollIntoView';
import Icons from '../Icons';
import ResourceNameCell from './ResourceNameCell';
import ResourceSelection from '../Material/ResourceSelection/ResourceSelection';

const ResourceListItem = React.forwardRef((props, ref) => {
  const {
    labelId,
    resourceId,
    resourceType,
    hasChanges,
    lastModified,
    created,
    state,
    isExternal,
    resourceName,
    src,
    extension,
    // resource,
    selected,
    isMobile,
    subscriptionLock,
    isFolder,
    isNewFile,
    isDisabled,
    isProcessing,
    dimmed,
    indicator,
    onClick,
    onDoubleClick,
    onClickCheckbox,
    onClickMoreButton,
    scrollIntoView,
    numberFileOfFolder,
    isConvertingFile,
    showCheckbox,
    multiple,
    columns,
    location,
    disabledRowMessage,
  } = props;

  const renderSelection = () => {
    return (
      <TableCell padding="checkbox">
        <ResourceSelection
          isShow={showCheckbox}
          labelId={labelId}
          selectBoxType={multiple ? 'checkbox' : 'radio'}
          disabled={isProcessing}
          checked={selected}
          onChange={onClickCheckbox}
        />
      </TableCell>
    );
  };

  const renderName = () => {
    const numberValue = columns.includes('itemsCount') ? null : numberFileOfFolder;
    return (
      <TableCell onClick={onClick} onDoubleClick={onDoubleClick} id={labelId}>
        {/* {subscriptionLock && <SubscriptionLockIcon />} */}
        {scrollIntoView && <ScrollIntoView />}
        <ResourceNameCell
          id={resourceId}
          isMobile={isMobile}
          state={state}
          type={resourceType}
          extension={extension}
          isConvertingFile={isConvertingFile}
          isFolder={isFolder}
          resourceName={resourceName}
          isExternal={isExternal}
          src={src}
          lastModified={lastModified}
          created={created}
          numberFileOfFolder={numberValue}
          location={location}
        />
      </TableCell>
    );
  };

  const renderType = () => {
    return (
      <TableCell>
        {indicator && (
          <Icons
            name={indicator.iconName}
            className={`resource-indicator ${indicator.indicatorCss}`}
          />
        )}
      </TableCell>
    );
  };

  const renderLastModified = () => {
    return <TableCell>{formatDateTime(lastModified || created, 'MMM DD, YYYY')}</TableCell>;
  };

  const renderItems = () => {
    return <TableCell>{numberFileOfFolder()}</TableCell>;
  };

  const renderActions = () => {
    return (
      <TableCell padding="none" align="center" className="resource-item-actions">
        {props.showActionMenu && (
          <LightTooltip
            title="Go back to the space to edit files."
            disableHoverListener={!props.disabledMoreButton}
            placement="bottom-start"
          >
            <div className="action-menu-buttons">
              <IconButton
                disabled={props.disabledMoreButton || isProcessing}
                className="action-menu-button"
                size="small"
                onClick={onClickMoreButton}
              >
                <MoreHorizIcon className="icon-more" />
              </IconButton>
            </div>
          </LightTooltip>
        )}
        {(isNewFile || hasChanges) && (
          <div className="blue-dot-wrapper">
            <span className="blue-dot" />
          </div>
        )}
      </TableCell>
    );
  };

  const renderCells = () => {
    let cells = [];
    if (isMobile) {
      cells = [
        { key: 'name', render: renderName },
        { key: 'actions', render: renderActions },
      ];
    } else {
      cells = [
        { key: 'select', render: renderSelection },
        { key: 'name', render: renderName },
        { key: 'indicator', render: renderType },
        { key: 'itemsCount', render: renderItems },
        { key: 'lastModified', render: renderLastModified },
        { key: 'actions', render: renderActions },
      ];
    }
    cells = cells.filter((cell) => columns.includes(cell.key));
    return cells.map((cell) => <Fragment key={cell.key}>{cell.render()}</Fragment>);
  };

  function renderRow() {
    return (
      <TableRow
        key={resourceId}
        className={clsx('resource-item', {
          draggable: props.draggable && !isFolder,
          isDragging: props.isDragging,
          'disabled-row': isDisabled || isProcessing || dimmed || subscriptionLock,
          'new-file': isNewFile || hasChanges,
          'mobile-mode': isMobile,
          'folder-row': isFolder,
        })}
        hover
        ref={ref}
      >
        {renderCells()}
      </TableRow>
    );
  }

  if (disabledRowMessage) {
    return (
      <LightTooltip title={disabledRowMessage} placement="bottom">
        {renderRow()}
      </LightTooltip>
    );
  }
  return renderRow();
});

ResourceListItem.propTypes = {
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resourceType: PropTypes.number,
  state: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isExternal: PropTypes.bool,
  resourceName: PropTypes.string,
  src: PropTypes.string,
  extension: PropTypes.string,
  hasChanges: PropTypes.bool,
  lastModified: PropTypes.string,
  created: PropTypes.string,
  selected: PropTypes.bool,
  isMobile: PropTypes.bool,
  subscriptionLock: PropTypes.bool,
  isFolder: PropTypes.bool,
  isNewFile: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isProcessing: PropTypes.bool,
  dimmed: PropTypes.bool,
  indicator: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onClickCheckbox: PropTypes.func,
  onClickMoreButton: PropTypes.func,
  showActionMenu: PropTypes.bool,
  disabledMoreButton: PropTypes.bool,
  draggable: PropTypes.bool,
  isDragging: PropTypes.bool,
  isConvertingFile: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
  numberFileOfFolder: PropTypes.func,
  labelId: PropTypes.string,
  showCheckbox: PropTypes.bool,
  multiple: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.string,
  disabledRowMessage: PropTypes.string,
};

ResourceListItem.defaultProps = {
  showCheckbox: true,
  multiple: true,
};

ResourceListItem.displayName = 'ResourceListItem';

export default ResourceListItem;

import React from 'react';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
import { useDebouncedCallback } from 'use-debounce';
import { TextField } from '@mui/material';
import memoizeOne from 'memoize-one';
import { WIDGET_NAME, WIDGET_OPTION } from '../cmsConstantsForWidget';
import i18n from '../../i18n';
import CmsSelect from '../commons/CmsSelect';

const getWidgetRowTitle = memoizeOne((index) => {
  let title = '';
  switch (index) {
    case 0:
      title = 'First widget';
      break;
    case 1:
      title = 'Second widget';
      break;
    case 2:
      title = 'Third widget';
      break;
    case 3:
      title = 'Fourth widget';
      break;
    default:
      break;
  }

  return title;
});

const getOptions = memoizeOne((spaceFeatures) => {
  let enabledFeatures = [...WIDGET_OPTION];

  if (Object.keys(spaceFeatures)?.length) {
    enabledFeatures = WIDGET_OPTION.filter((option) => {
      return spaceFeatures[option.title];
    });
  }
  return enabledFeatures;
});

function WidgetSelect(props) {
  const { onChange } = props;
  const selectedBlock = props.defaultValue;
  const widgetItems = selectedBlock?.items;

  const options = getOptions(props.spaceFeatures);

  function handleOnChangeWidget(value, id) {
    const newItems = _cloneDeep(widgetItems);
    const index = newItems.findIndex((item) => item.id === id);
    if (index !== -1) {
      newItems[index].settings.subType = value;
      newItems[index].widgetName = WIDGET_NAME[value];
      if (onChange) onChange(newItems);
    }
  }

  function handleOnChangeWidgetName(event, id) {
    const newItems = _cloneDeep(widgetItems);
    const index = newItems.findIndex((item) => item.id === id);
    if (index !== -1) {
      const newName = event.target.value.trim();
      if (newItems[index].widgetName !== newName) {
        newItems[index].widgetName = newName;
        if (onChange) onChange(newItems);
      }
    }
  }

  const onDebouncedNameChange = useDebouncedCallback(
    (event, id) => handleOnChangeWidgetName(event, id),
    1500
  );

  function renderRow() {
    const lastIndex = widgetItems.length - 1;
    const keyRender = Date.now() + Math.random();
    return (
      <div key={keyRender} className="displayFlex column-direction gap24">
        {widgetItems.map((item, index) => (
          <div key={item.id} className="displayFlex column-direction gap8">
            <div>{getWidgetRowTitle(index)}</div>
            <div className="cms-component-panel-control">
              <label className="cms-component-panel-control-label">{i18n.t('Select Widget')}</label>
              <CmsSelect
                options={options}
                value={item.settings.subType}
                onChange={(value) => handleOnChangeWidget(value, item.id)}
              />
            </div>
            <div className="cms-component-panel-control">
              <label className="cms-component-panel-control-label">{i18n.t('Widget title')}</label>
              <TextField
                autoComplete="off"
                fullWidth
                className="cms-component-panel-control-input"
                variant="outlined"
                placeholder={WIDGET_NAME[item.settings.subType]}
                defaultValue={item.widgetName}
                name="heading"
                onChange={(e) => onDebouncedNameChange(e, item.id)}
                onBlur={(e) => {
                  onDebouncedNameChange.cancel();
                  handleOnChangeWidgetName(e, item.id);
                }}
              />
            </div>
            {index !== lastIndex && <div className="divider mt-24"></div>}
          </div>
        ))}
      </div>
    );
  }

  return renderRow();
}

WidgetSelect.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.instanceOf(Object), // the selected block/row
  contentType: PropTypes.string,
};

export default WidgetSelect;

import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetchPromoQuery from './useFetchPromoQuery';
import currentOrganization from '../commons/CurrentOrganization';
import { savePromoRequest } from './promoService';
import { PromoRequestStatus } from './types';
import useSessionStorage from '../commons/useSessionStorage';

const PromoPopup = React.lazy(() => import('./promoPopup'));
const SuccessPromoPopup = React.lazy(() => import('./successPromoPopup'));

function PromoFeatureHandler() {
  const [isPromoPopupOpen, setIsPromoPopupOpen] = React.useState<boolean>(false);
  const [isActivated, setIsActivated] = useSessionStorage('isPromoActivated', undefined);
  const [isSavingData, setIsSavingData] = React.useState<boolean>(false);
  const organizationId = currentOrganization.getOrganizationId();
  const { data, isFetching } = useFetchPromoQuery(organizationId, isActivated === undefined);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (data && !isFetching && isActivated === undefined) {
      setIsPromoPopupOpen(true);
    }
  }, [data, isFetching, isActivated]);

  if (!data || isFetching) {
    return null;
  }

  const {
    title,
    description,
    imageSrc,
    requestId,
    successText,
    successButtonLabel,
    successButtonUrl,
  } = data;

  async function answerPromoRequest(isAccepted: boolean | null) {
    setIsSavingData(true);
    let status;
    if (isAccepted === true) {
      status = PromoRequestStatus.Accepted;
    } else if (isAccepted === false) {
      status = PromoRequestStatus.Rejected;
    } else {
      status = PromoRequestStatus.Dismissed;
    }
    const result = await savePromoRequest(requestId, { status });
    if (result.success && status === PromoRequestStatus.Accepted) {
      setIsActivated(true);
    }
    setIsSavingData(false);
  }

  function handleOnClose() {
    setIsPromoPopupOpen(false);
    setIsActivated(false);
  }

  async function handleOnClickActivateTrial() {
    await answerPromoRequest(true);
    setIsPromoPopupOpen(false);
  }

  function onCloseSuccessPopup() {
    setIsActivated(false);
  }

  function onClickGoToFeature() {
    navigate(successButtonUrl);
  }

  if (isActivated) {
    return (
      <Suspense fallback={null}>
        <SuccessPromoPopup
          successText={successText}
          successButtonLabel={successButtonLabel}
          onClose={onCloseSuccessPopup}
          onClickGoToFeature={onClickGoToFeature}
        />
      </Suspense>
    );
  }

  console.log('isPromoPopupOpen', isPromoPopupOpen);

  if (!isPromoPopupOpen) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <PromoPopup
        title={title}
        description={description}
        imageSrc={imageSrc}
        isSavingData={isSavingData}
        onClose={handleOnClose}
        onClickActivateTrial={handleOnClickActivateTrial}
      />
    </Suspense>
  );
}

export default PromoFeatureHandler;

import { endsWith } from 'lodash';
import memoizeOne from 'memoize-one';
import moment from 'moment-timezone';
import { DefaultTimeZone } from '../app/appConstants';

export const getUserTimeZone = memoizeOne(() => {
  try {
    return moment.tz.guess();
    // Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    console.log('ERROR', e);
  }
  return DefaultTimeZone;
});

export function formatTime(val, format = 'YYYY-MM-DD HH:mm') {
  if (!val) {
    return '';
  }
  return val.format(format);
}

export function parseDateTimeStringFromServer(val, baseUtcOffset = 0) {
  if (!val) {
    return null;
  }
  if (typeof val === 'object') {
    return val;
  }
  let str = val.toUpperCase();
  if (!endsWith(str, 'Z')) {
    str = `${str}Z`;
  }
  const m = moment(str);
  if (baseUtcOffset) {
    m.utcOffset(baseUtcOffset);
  }
  return m;
}

export function formatDateTime(val, format = 'YYYY-MM-DD HH:mm', offset = null) {
  if (!val) {
    return '';
  }
  if (offset !== null) {
    return moment(val).utcOffset(offset).format(format);
  }
  return moment(val).local().format(format);
}

export function parseAndFormatDateString(val, format = 'MMMM DD, YYYY', baseUtcOffset = 0) {
  return formatDateTime(parseDateTimeStringFromServer(val, baseUtcOffset), format);
}

export function getDay(val) {
  if (!val) {
    return '';
  }

  let day = formatDateTime(new Date(val), 'dddd');
  if (formatDateTime(new Date(), 'MM-DD-YYYY') === val) {
    day = 'Today';
  } else if (val === formatDateTime(new Date(moment().add(-1, 'days')), 'MM-DD-YYYY')) {
    day = 'Yesterday';
  }

  return day;
}

export function formatTodoDueDate(value) {
  if (!value) {
    return '';
  }

  const inputDate = moment(value, 'LL').startOf('day');
  const today = moment().startOf('day');
  const tomorrow = today.clone().add(1, 'day');
  const daysDiff = inputDate.diff(today, 'days');

  if (inputDate.isBefore(today)) {
    return 'Overdue';
  }

  if (inputDate.isSame(today)) {
    return 'Due today';
  }

  if (inputDate.isSame(tomorrow)) {
    return 'Due in 1 day';
  }

  if (daysDiff > 0 && daysDiff <= 3) {
    return `Due in ${daysDiff} days`;
  }

  if (inputDate.isSame(today, 'year')) {
    return `Due on ${inputDate.format('MMM D')}`;
  } else {
    return `Due on ${inputDate.format('MMM D, YYYY')}`;
  }
}

export function newDate(timezone) {
  const date = moment();
  if (timezone) date.tz(timezone);
  return date;
}

export function getTimeToNow(date) {
  if (date.isBefore()) {
    return null;
  }
  const [num, text] = date.toNow(true).split(' ');
  if (num === 'an' || num === 'a') {
    return { num: 1, text };
  }
  return { num, text };
}

export function getDiff(startDate, endDate, type = 'days') {
  const diff = endDate.diff(startDate, type);
  if (diff > 0) {
    return diff + 1;
  }
  return 0;
}

export function secondsToTime(secs) {
  const hours = Math.floor(secs / (60 * 60));

  const divisorForMinutes = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  return {
    hours,
    minutes,
    seconds,
  };
}

// startDate, endDate must be moment objects, not strings
export function getDuration(startDate, endDate) {
  const diffSeconds = endDate.diff(startDate, 'seconds');
  const time = secondsToTime(diffSeconds);
  return `${time.hours}hr, ${time.minutes}mins`;
}

export function timeFromNow(date, custom = false) {
  if (custom) {
    date.locale('spce-en');
  } else {
    date.locale('en');
  }
  return date.fromNow();
}

export function timeFromNowByLocal(date, locale = 'en') {
  date.locale(locale);
  return date.fromNow();
}

export function timeUnitsFromNow(time) {
  const date = parseDateTimeStringFromServer(time);

  const now = moment();
  const exp = moment(date);
  const duration = moment.duration(now.diff(exp));
  const diffDays = now.startOf('day').diff(exp.startOf('day'));

  const asDays = moment.duration(diffDays).asDays();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  return {
    date,
    days,
    hours,
    minutes,
    asDays,
  };
}

export function addZAtEndOfDateTimeString(time) {
  if (!time) return time;
  let str = time.toUpperCase();
  if (!endsWith(str, 'Z')) {
    str = `${str}Z`;
  }
  return str;
}

export function getLastUpdatedTime(time, format, text = '') {
  if (!time) return time;
  const parsedDate = parseDateTimeStringFromServer(time);
  const now = moment();
  const duration = moment.duration(now.diff(parsedDate));
  const currentYear = now.year();
  const inDays = duration.asDays();
  if (inDays > 7) {
    if (format) {
      return parsedDate.format(format);
    }
    if (parsedDate.year() === currentYear) {
      return parsedDate.format('MMM DD');
    } else {
      return parsedDate.format('MMM DD, YYYY');
    }
  } else {
    const inMinutes = duration.asMinutes();
    if (inMinutes < 1) {
      return 'Just now';
    } else if (inMinutes < 60) {
      const minutes = Math.floor(inMinutes);
      return minutes === 1 ? `1 minute ${text}` : `${minutes} minutes ${text}`;
    } else {
      const inHours = duration.asHours();
      if (inHours < 24) {
        const hours = Math.floor(inHours);
        return hours === 1 ? `1 hour ${text}` : `${hours} hours ${text}`;
      } else {
        const days = Math.floor(inDays);
        return days === 1 ? `1 day ${text}` : `${days} days ${text}`;
      }
    }
  }
  // return parsedDate.format('MMM DD, YYYY');
}

export function formatDate(date) {
  const parsedDate = parseDateTimeStringFromServer(date);
  if (moment().isSame(parsedDate, 'day')) {
    return 'Today';
  } else if (moment().subtract(1, 'days').isSame(parsedDate, 'day')) {
    return 'Yesterday';
  } else if (moment().startOf('week').isBefore(parsedDate)) {
    return moment(parsedDate).format('dddd'); // Outputs the weekday, e.g., Monday, Tuesday, etc.
  } else {
    return moment(parsedDate).format('MMM DD, YYYY'); // Outputs the date in the format MMM, DD YYYY, e.g., Dec 31, 2022.
  }
}

export function formatTimestamp(value) {
  const parseDate = parseDateTimeStringFromServer(value)?.local();
  const time = parseDate.format('hh:mm A');
  return time;
}

/* eslint import/prefer-default-export: 0 */

import { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import { remove } from 'lodash';

export const useMountEffect = (fun) => {
  useEffect(
    () => {
      // console.log('useMountEffect');
      fun();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export function useUnmountEffect(callback) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useLayoutEffect(() => {
    return () => {
      const callbackFunction = callbackRef.current;

      if (!callbackFunction) {
        return;
      }

      callbackFunction();
    };
  }, []);
}

export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIntersecting;
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useSelectedItems(defaultValue = []) {
  const [selectedItems, setSelectedItems] = useState(defaultValue);

  const onToggleItem = useCallback((item) => {
    return new Promise((resolve) => {
      setSelectedItems((prevState) => {
        const newState = prevState.slice();
        const rs = remove(newState, (e) => e === item);
        if (rs.length === 0) {
          // add new
          newState.push(item);
        }
        resolve(newState);
        return newState;
      });
    });
  }, []);

  const clearAll = useCallback(() => {
    setSelectedItems([]);
  }, []);
  return [selectedItems, onToggleItem, clearAll, setSelectedItems];
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    console.log('useForceUpdate');
    setTick((tick) => tick + 1);
  }, []);
  return update;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

import React from 'react';
import { ISpaceUser } from '../types/User';

export type Space = {
  id: string;
  name: string;
  isTemplate: boolean;
};

export interface ISpaceContext {
  space: Space | null;
  theme: { logoImageSrc: string | null };
  isLoading?: boolean;
  signalRConnection?: object | null;
  spaceUserSession?: ISpaceUser | null;
}

export const SpaceContext = React.createContext<ISpaceContext>({
  space: null,
  theme: { logoImageSrc: null },
});
SpaceContext.displayName = 'SpaceContext';

export const useSpaceContext = () => React.useContext(SpaceContext);

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CommentsContainer from './CommentsContainer';
import './ChatContainer.scss';
import Chat from './Chat';
import { useSpaceContext } from '../SpaceContext';
import useUnmountEffect from '../../commons/useUnmountEffect';
import Tabs, { useTabs } from '../../components/Tabs';
import i18n from '../../i18n';
import PrivateChat from './PrivateChat';
import ChatSidebarHandler from './ChatSidebarHandler';
import spaceUser from '../spaceUser';
import { ChannelType } from '../../app/appConstants';
import NoDaTaBox from '../../components/NoDataBox';

function ChatContainer(props) {
  const { spaceId, mainChannelId, mainChannel, onResourceView, onClose, widgetName } = props;
  const {
    setActiveChannel,
    vaamTargetChannel,
    setVaamTargetChannel,
    isSendingVaam,
    setIsSendingVaam,
    hideParticipants,
    newNotifications,
    isVisitor,
  } = useSpaceContext();
  const newEventMessage = newNotifications?.Chats?.details;
  const userInfoId = spaceUser.getUserInfoId();
  const hidePrivateChat = spaceUser.isHidden() || hideParticipants || isVisitor;
  const { tabs, changeTab } = useTabs(
    hidePrivateChat ? ['Public Chat'] : ['Public Chat', 'Private Chat']
  );
  const [chatTab, setChatTab] = useState(0);

  const commentsRef = useRef();
  const participantListRef = useRef();
  const [selectedChannel, setSelectedChannel] = useState(null);

  const tabsData = useMemo(() => {
    const privateMessagesCount =
      newEventMessage?.newPrivateMessages?.reduce(
        (acc, message) => acc + message.totalBadgeCount,
        0
      ) || 0;
    const publicMessagesCount = newEventMessage?.newPublicMessages?.totalBadgeCount || 0;
    return tabs.map((item) => ({
      ...item,
      badgeCount: item?.label === 'Private Chat' ? privateMessagesCount : publicMessagesCount,
    }));
  }, [
    newEventMessage?.newPrivateMessages,
    newEventMessage?.newPublicMessages?.totalBadgeCount,
    tabs,
  ]);

  useEffect(() => {
    if (chatTab === 0) {
      changeTab(0);
    } else {
      changeTab(1);
    }
  }, [changeTab, chatTab]);

  useEffect(() => {
    if (chatTab === 0) {
      setActiveChannel(mainChannel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSendingVaam && vaamTargetChannel) {
      if (vaamTargetChannel.channelType === ChannelType.P2P) {
        setChatTab(2);
        setSelectedChannel(vaamTargetChannel);
      } else {
        setChatTab(0);
        // setSelectedChannel(mainChannel);
      }
      setVaamTargetChannel(null);
      setIsSendingVaam(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendingVaam]);

  useUnmountEffect(() => {
    setActiveChannel(mainChannel);
  });

  const handleTabChange = (tabIndex) => {
    setChatTab(tabIndex);
  };

  function handleOnSelectChannel(channel) {
    setSelectedChannel(channel);
    setChatTab(2);
  }

  function handleOnFocusCommentBox(channelId) {
    console.log('channelId', channelId);
  }

  const handleOnChannelLoaded = (channel) => {
    setActiveChannel(channel);
  };

  function renderPublicChat() {
    return (
      <div className="event-chat">
        <CommentsContainer
          key="comments-container"
          ref={commentsRef}
          spaceId={spaceId}
          participantId={userInfoId}
          channelId={mainChannelId}
          channelType={ChannelType.RUNTIME}
          onResourceView={onResourceView}
          isChatWiget={props.isChatWiget}
        />
      </div>
    );
  }

  function renderChat() {
    if (!selectedChannel) {
      return null;
    }

    function handleOnCloseChat() {
      setActiveChannel(null);
      setSelectedChannel(null);
      setChatTab(1);
    }

    return (
      <Chat
        id={props.id}
        ref={commentsRef}
        spaceId={spaceId}
        participants={selectedChannel.participants}
        participantId={userInfoId}
        mainChannelId={mainChannelId}
        onClose={handleOnCloseChat}
        onFocus={handleOnFocusCommentBox}
        onLoaded={handleOnChannelLoaded}
        onResourceView={onResourceView}
        isChatWiget={props.isChatWiget}
      />
    );
  }

  function renderPrivateChat() {
    return (
      <PrivateChat
        ref={participantListRef}
        newEventMessage={newEventMessage}
        spaceId={spaceId}
        onSelectChannel={handleOnSelectChannel}
        mainChannelId={mainChannelId}
      />
    );
  }

  function renderTabContent() {
    switch (chatTab) {
      case 0:
        return renderPublicChat();
      case 1:
        return renderPrivateChat();
      case 2:
        return renderChat();
      default:
        return null;
    }
  }

  function renderChatContainer() {
    const showTabs = !hidePrivateChat && chatTab !== 2;
    if (props.isTemplate) {
      return (
        <>
          <div className="side-panel-title">
            <div className="side-panel-title-bold">Chat</div>
          </div>
          <NoDaTaBox
            icon={<i className="icon-chat" />}
            title={
              <>
                <span>
                  {i18n.t('In spaces created from this template')}
                  <br />
                  {i18n.t('there will be a chat in this view.')}
                </span>
              </>
            }
          />
        </>
      );
    }

    function renderTitle() {
      let displayText;

      if (widgetName) {
        displayText = widgetName;
      } else {
        displayText = hidePrivateChat ? 'Public Chat' : 'Chat';
      }
      return displayText;
    }

    async function handleOnClose() {
      if (chatTab === 1) {
        await participantListRef.current.handleCloseForm();
        onClose();
      } else {
        if (!commentsRef.current) {
          onClose();
          return;
        }
        const isCloseForm = await commentsRef.current.handleCloseForm();
        if (isCloseForm) {
          onClose();
        }
      }
    }

    return (
      <>
        {chatTab !== 2 && (
          <div className="side-panel-title">
            <div className="side-panel-title-bold">{renderTitle()}</div>
          </div>
        )}
        {!props.isChatWiget && (
          <div className="close-side-panel">
            <IconButton
              aria-label="close"
              size="small"
              color="secondary"
              className="close-icon"
              onClick={handleOnClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {showTabs && (
          <div className="comments-tabs">
            <Tabs tabs={tabsData} onChange={handleTabChange} variant="tabs-level-2" />
          </div>
        )}
        {renderTabContent()}
        {!props.isChatWiget && (
          <ChatSidebarHandler
            spaceId={spaceId}
            mainChannelId={mainChannelId}
            mainChannel={mainChannel}
            setChatTab={setChatTab}
            setSelectedChannel={setSelectedChannel}
            setActiveChannel={setActiveChannel}
          />
        )}
      </>
    );
  }

  return <div className="chat-main-container">{renderChatContainer()}</div>;
}

ChatContainer.propTypes = {
  id: PropTypes.string,
  spaceId: PropTypes.string,
  mainChannelId: PropTypes.number,
  mainChannel: PropTypes.shape({}),
  onResourceView: PropTypes.func,
  onClose: PropTypes.func,
  isChatWiget: PropTypes.bool,
  isTemplate: PropTypes.bool,
  widgetName: PropTypes.string,
};

export default ChatContainer;

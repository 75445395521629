import UTIF from 'utif';
import { getExtensionFromUrl } from './ResourceUtils';

// examples: https://github.com/johnthad/utif-demo
export function getTheFirstPageInTiffImage(arrayBuffer) {
  if (!arrayBuffer) return null;
  if (arrayBuffer instanceof ArrayBuffer) {
    if (!arrayBuffer.byteLength) return null;
    try {
      const pages = UTIF.decode(arrayBuffer);
      const page = pages[0]; // only take 1st page
      UTIF.decodeImage(arrayBuffer, page);
      const rgba = UTIF.toRGBA8(page); // Uint8Array with RGBA pixels
      const canvas = document.createElement('canvas');
      canvas.width = page.width;
      canvas.height = page.height;
      const ctx = canvas.getContext('2d');
      const imgData = ctx.createImageData(page.width, page.height);
      imgData.data.set(rgba);
      ctx.putImageData(imgData, 0, 0);
      return canvas;
    } catch (err) {
      console.log('getTheFirstPageInTiffImage ERROR:', err);
    }
  }
  return null;
}

export function getAllPagesInTiffImage(arrayBuffer) {
  if (!arrayBuffer) return null;
  if (arrayBuffer instanceof ArrayBuffer) {
    if (!arrayBuffer.byteLength) return null;
    try {
      const pages = UTIF.decode(arrayBuffer);
      const canvases = pages.map((page) => {
        UTIF.decodeImage(arrayBuffer, page);
        const rgba = UTIF.toRGBA8(page); // Uint8Array with RGBA pixels
        const canvas = document.createElement('canvas');
        canvas.width = page.width;
        canvas.height = page.height;
        const ctx = canvas.getContext('2d');
        const imgData = ctx.createImageData(page.width, page.height);
        imgData.data.set(rgba);
        ctx.putImageData(imgData, 0, 0);
        return canvas;
      });
      return canvases;
    } catch (err) {
      console.log('getAllPagesInTiffImage ERROR:', err);
    }
  }
  return null;
}

export function isTiffFile(src, extension) {
  const mExtension = getExtensionFromUrl(src, extension);
  return mExtension === 'tiff' || mExtension === 'tif';
}

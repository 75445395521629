import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton } from '@mui/material';
import { useThemeSettingsContext } from './context/ThemeSettingsContextProvider';
import './MenuActionsCms.scss';
import '../cmsStyle.scss';
import LightTooltip from '../../components/LightTooltip';
import ConfirmRemoveBlockDialog from './ConfirmRemoveBlockDialog';
import i18n from '../../i18n';
import { useLocalStorage } from '../../commons/CustomHooks';
import { Ids } from '../../commons/pendoTaggings';
import useInterval from '../../commons/useInterval';
import { CMS_COMPONENT_TYPE } from '../cmsConstants';

const configureCopyButton = memoizeOne((block) => {
  let isDisabled = false;
  let tooltip = '';
  if (!block?.id) return { isDisabled: true, tooltip };
  const type = block.items?.[0]?.type;
  if (type === CMS_COMPONENT_TYPE.DEAL_MANAGEMENT) {
    isDisabled = true;
    tooltip = i18n.t('It is not supported to copy-paste deal management blocks.');
  } else if (type === CMS_COMPONENT_TYPE.WIDGET) {
    isDisabled = true;
    tooltip = i18n.t('It is not supported to copy-paste space widget blocks.');
  } else if (type === CMS_COMPONENT_TYPE.SCRIPT) {
    isDisabled = true;
    tooltip = i18n.t('It is not supported to copy-paste script blocks.');
  }
  return { isDisabled, tooltip };
});

const configureDuplicateButton = memoizeOne((block) => {
  let isDisabled = false;
  let tooltip = '';
  if (!block?.id) return { isDisabled: true, tooltip };
  const type = block.items?.[0]?.type;
  if (type === CMS_COMPONENT_TYPE.DEAL_MANAGEMENT) {
    isDisabled = true;
    tooltip = i18n.t('It is not supported to duplicate deal management blocks.');
  }
  return { isDisabled, tooltip };
});

const MenuActionsCms = (props) => {
  const {
    moveUp,
    moveDown,
    canMoveUp,
    canMoveDown,
    removeBlock,
    handleDuplicateColumn,
    handleCopyBlock,
    handlePasteBlock,
    readCopiedCmsBlockData,
  } = useThemeSettingsContext();

  const [dismissConfirmRemoveBlock, setDismissConfirmRemoveBlock] = useLocalStorage(
    'dismissConfirmRemoveBlock',
    ''
  );

  const [enablePasteBtn, setEnablePasteBtn] = React.useState(false);

  const configCopyBtn = configureCopyButton(props.currentBlock);
  const configDuplicateBtn = configureDuplicateButton(props.currentBlock);

  const isPagesNavigation = props.isPagesNavigation;

  async function handleDeleteColumn(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!dismissConfirmRemoveBlock) {
      const { hasConfirmed, doNotShow } = await ConfirmRemoveBlockDialog.show(
        'Remove Block',
        i18n.t('Are you sure you want to remove this block?'),
        true,
        `Don't ask me again`
      );
      if (hasConfirmed) {
        setDismissConfirmRemoveBlock(doNotShow);
        await removeBlock(props.currentBlock);
      }
    } else {
      await removeBlock(props.currentBlock);
    }
  }

  function handleOnAddBlockClick(event) {
    event.stopPropagation();
    if (props.onAddButtonClick) {
      props.onAddButtonClick(props.currentBlock);
    }
  }

  function handleCopyBlockBtnClick(event) {
    event.stopPropagation();
    // console.log('### 123 copied from floating menu');
    handleCopyBlock(props.currentBlock);
  }

  function handlePasteBlockBtnClick(event) {
    event.stopPropagation();
    // console.log('### 123 pasted from floating menu');
    handlePasteBlock(props.currentBlock, true);
  }

  const syncCheckClipboard = React.useCallback(async () => {
    if (!props.canCopyPasteBlock) return;
    const data = await readCopiedCmsBlockData();
    const shouldEnablePasteBtn = !!data;
    setEnablePasteBtn(shouldEnablePasteBtn);
  }, [readCopiedCmsBlockData, props.canCopyPasteBlock]);

  const delayTime = props.canCopyPasteBlock ? 123 : 60 * 60 * 1000;
  useInterval(syncCheckClipboard, delayTime);

  function getMessageDeleteBlock() {
    if (isPagesNavigation)
      return 'Users will see the first page without the navigation bar when the Pages block is deleted.';
    return 'Delete block';
  }

  let buttons = [
    {
      icon: <AddIcon className="icon" />,
      disabled: false,
      onClick: handleOnAddBlockClick,
      title: 'ADD BLOCK',
      dataId: Ids.AddBlockFromMenuId,
      divider: true,
      isShow: true,
    },
    {
      icon: <ArrowUpwardIcon className="icon" />,
      disabled: !canMoveUp(props.currentBlock),
      onClick: () => moveUp(props.currentBlock),
      toolTipMessage: 'Move up',
      dataId: Ids.BlockMoveUp,
      divider: false,
      isShow: !isPagesNavigation,
    },
    {
      icon: <ArrowDownwardIcon className="icon" />,
      disabled: !canMoveDown(props.currentBlock),
      onClick: () => moveDown(props.currentBlock),
      toolTipMessage: 'Move down',
      dataId: Ids.BlockMoveDown,
      divider: false,
      isShow: !isPagesNavigation,
    },
    {
      icon: <span className="icon-duplicate duplicateIcon" />,
      disabled: configDuplicateBtn.isDisabled,
      onClick: () => handleDuplicateColumn(props.currentBlock),
      toolTipMessage: configDuplicateBtn.tooltip,
      dataId: Ids.BlockDuplicate,
      divider: false,
      isShow: !isPagesNavigation,
    },
    {
      icon: <DeleteIcon className={'icon'} onClick={handleDeleteColumn} />,
      disabled: false,
      onClick: handleDeleteColumn,
      toolTipMessage: getMessageDeleteBlock(),
      dataId: Ids.BlockDelete,
      divider: props.canCopyPasteBlock,
      isShow: true,
    },
    {
      disabled: configCopyBtn.isDisabled,
      toolTipMessage: configCopyBtn.tooltip,
      toolTipPlacement: 'bottom',
      onClick: handleCopyBlockBtnClick,
      title: 'COPY',
      dataId: Ids.BlockCopy,
      divider: false,
      isShow: props.canCopyPasteBlock && !isPagesNavigation,
    },
    {
      disabled: !props.currentBlock || !enablePasteBtn,
      onClick: handlePasteBlockBtnClick,
      title: 'PASTE',
      dataId: Ids.BlockPaste,
      divider: false,
      className: !isPagesNavigation && 'marginLeft16i',
      isShow: props.canCopyPasteBlock,
    },
  ];
  buttons = buttons.filter((button) => button.isShow);

  function handleOnClickOnDisabledButton(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function renderButton(button) {
    if (!button.toolTipMessage) {
      return (
        <Button
          className={`button ${button.className}`}
          data-id={button.dataId}
          disabled={button.disabled}
          onClick={button.onClick}
        >
          {button.icon}
          {button.title}
        </Button>
      );
    }
    return (
      <LightTooltip title={button.toolTipMessage} placement={button.toolTipPlacement || 'top'}>
        <Button
          className={`button ${button.className} disabled`}
          data-id={button.dataId}
          // disabled={button.disabled}
          onClick={handleOnClickOnDisabledButton}
        >
          {button.icon}
          {button.title}
        </Button>
      </LightTooltip>
    );
  }

  return (
    <div className="menu-action-cms-container">
      <div className="menu-action-cms">
        <div className="actions">
          {buttons.map((button, index) => (
            <React.Fragment key={index}>
              {!button.title ? (
                <LightTooltip title={button.toolTipMessage} placement="top">
                  <span>
                    <IconButton
                      className={`icon-buttons ${button.className}`}
                      data-id={button.dataId}
                      disabled={button.disabled}
                      onClick={button.onClick}
                    >
                      {button.icon}
                    </IconButton>
                  </span>
                </LightTooltip>
              ) : (
                renderButton(button)
              )}
              {button.divider && (
                <Box>
                  <div className="divider"></div>
                </Box>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
MenuActionsCms.propTypes = {
  onAddButtonClick: PropTypes.func,
  currentBlock: PropTypes.instanceOf(Object),
  canCopyPasteBlock: PropTypes.bool,
  isPagesNavigation: PropTypes.bool,
};

export default MenuActionsCms;

import React, { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import { dismissNotification, sendNotification } from '../../commons/utils';
import i18n from '../../i18n';
import ConnectButton from '../components/ConnectButton';
import DisconnectDialog from '../DisconnectDialog';
import { SERVICE_PROVIDER } from '../integrationConstants';
import { useIntegrationContext } from '../../commons/IntegrationContext';
import { GoogleDriveTreeItem } from './googleDrive.types';
import GoogleDriveConnectionDialog from './GoogleDriveConnectionDialog';
import GoogleDriveSelectRootFoldersDialog from './GoogleDriveSelectRootFoldersDialog';
import { saveGoogleDriveDefaultRootFolders } from './googleDriveService';
import { createGoogleDriveRootFoldersPayload } from './utils';
import { removeIntegrationConnection } from '../integrationService';
import { ConnectButtonBaseProps } from '../components/ConnectButton/types';
import ConnectFailedDialogService from '../ConnectFailedDialogService';

const GoogleDriveConnectButton = forwardRef((props: ConnectButtonBaseProps, ref: Ref<unknown>) => {
  const { isProcessing, serviceProvider, onConnectSuccess } = props;
  const { addConnection } = useIntegrationContext();
  const [showGoogleConnectDialog, setShowGoogleConnectDialog] = useState(false);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);
  const folderDialogResolver = useRef<(value: boolean) => void>();

  function handleOnClickConnect() {
    setShowGoogleConnectDialog(true);
  }

  function handleOnClickDisconnect() {
    setShowDisconnectDialog(true);
  }

  useImperativeHandle(ref, () => ({
    handleOnClickConnect,
  }));

  async function connectGoogleDrive(accessCode: string) {
    setShowGoogleConnectDialog(false);
    if (!accessCode || typeof accessCode !== 'string') {
      return;
    }
    await addConnection({
      accessCode: accessCode,
      serviceProvider: SERVICE_PROVIDER.GOOGLE_DRIVE,
      onBeforeSuccessDialog: async () => {
        setShowFolderDialog(true);
        return new Promise((res) => {
          folderDialogResolver.current = res;
        });
      },
      onFailure: (resp: { reason: string; data: { additionalMessage: string } }) => {
        if (resp.reason === 'DuplicatedEmail') {
          ConnectFailedDialogService.show(
            SERVICE_PROVIDER.GOOGLE_DRIVE,
            resp.data.additionalMessage
          );
        }
      },
    });
  }

  const handleOnFolderSubmit = async (selectedFolders: GoogleDriveTreeItem[]) => {
    const payload = createGoogleDriveRootFoldersPayload(selectedFolders);
    console.log(payload);
    await saveGoogleDriveDefaultRootFolders(payload);
    dismissNotification('googledrive_auth_error_alert');
    if (folderDialogResolver.current) {
      folderDialogResolver.current(true);
    }
    setShowFolderDialog(false);
    sendNotification(
      i18n.t('You can continue using SPCE in the meantime'),
      { duration: 5000, type: 'processing' },
      i18n.t('Connecting GoogleDrive folders might take a while')
    );
    if (onConnectSuccess) {
      await onConnectSuccess();
    }
  };

  async function handleOnFolderDialogClose() {
    await removeIntegrationConnection(SERVICE_PROVIDER.GOOGLE_DRIVE);
    if (folderDialogResolver.current) {
      folderDialogResolver.current(false);
    }
    setShowFolderDialog(false);
  }

  function contactUs() {
    const recipient = 'support@spce.com';
    const subject = 'Disconnect Google Drive Support';
    const message = `I would like support with disconnecting the Google Drive integration.`;
    window.open(`mailto:${recipient}?subject=${subject}&body=${message}`);
  }

  function supportChangeFolders() {
    const recipient = 'support@spce.com';
    const subject = 'Change default Google Drive folders Support';
    const message = `I would like support with chaging the Google Drive default shared folders.`;
    window.open(`mailto:${recipient}?subject=${subject}&body=${message}`);
  }

  return (
    <>
      <ConnectButton
        serviceProvider={serviceProvider}
        isProcessing={isProcessing}
        onClickConnect={handleOnClickConnect}
        onClickDisconnect={handleOnClickDisconnect}
      />
      {showFolderDialog && (
        <GoogleDriveSelectRootFoldersDialog
          onClose={handleOnFolderDialogClose}
          onSubmit={handleOnFolderSubmit}
          isOpen={showFolderDialog}
          onRequestSupport={supportChangeFolders}
        />
      )}
      {showGoogleConnectDialog && <GoogleDriveConnectionDialog onClose={connectGoogleDrive} />}
      <DisconnectDialog
        isOpen={showDisconnectDialog}
        serviceProvider={SERVICE_PROVIDER.GOOGLE_DRIVE}
        onSubmit={contactUs}
        onClose={() => setShowDisconnectDialog(false)}
      />
    </>
  );
});

export default GoogleDriveConnectButton;

const descendingComparator = (a, b, orderBy) => {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (valueB !== null && valueB !== undefined && (valueA === undefined || valueA === null))
    return -1;
  if ((valueB === undefined || valueB === null) && valueA !== null && valueA !== undefined)
    return 1;

  if (valueB < valueA) return -1;
  if (valueB > valueA) return 1;

  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export function dataTableSort(array, comparator, priority = null) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  if (priority) {
    for (let i = 0; i < stabilizedThis.length; i++) {
      if (stabilizedThis[i][0][priority]) {
        const [priorityItem] = stabilizedThis.splice(i, 1);
        stabilizedThis.unshift(priorityItem);
      }
    }
  }
  return stabilizedThis.map((el) => el[0]);
}
